import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loadOrganizationList,
  saveOrganization,
  deleteOrganization,
} from "../services";
import emptyOrganization from "../utils/constants/data/empty-organization";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

export const loadOrganizationListThunk = createAsyncThunk(
  "organization/loadOrganizationList",
  async (thunkAPI, { dispatch }) => {
    const response = await loadOrganizationList(thunkAPI);
    dispatch(setOrganizationList(response));
    return response;
  }
);

export const saveOrganizationThunk = createAsyncThunk(
  "organization/saveOrganization",
  async (thunkAPI, { dispatch }) => {
    const response = await saveOrganization(thunkAPI);
    dispatch(loadOrganizationListThunk(thunkAPI.scenario_id));
    return response;
  }
);

export const deleteOrganizationThunk = createAsyncThunk(
  "organization/deleteOrganization",
  async (thunkAPI, { dispatch }) => {
    await deleteOrganization(thunkAPI);
    dispatch(loadOrganizationListThunk(thunkAPI.scenario_id));
  }
);

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization: getFromLocalStorage("organization", "object")
      ? getFromLocalStorage("organization", "object")
      : emptyOrganization,

    organizationList: getFromLocalStorage("organizationList", "object")
      ? getFromLocalStorage("organizationList", "array")
      : [],
  },
  reducers: {
    setOrganizationList: (state, action) => {
      state.organizationList = action.payload;
      saveToLocalStorage("organizationList", state.organizationList);
    },
    getOrganizationList: (state) => {
      return state.organization.organizationList;
    },
    setOrganization: (state, action) => {
      state.organization = action.payload;
      saveToLocalStorage("organization", state);
    },

    clearOrganization: () => {
      saveToLocalStorage("organization", emptyOrganization);
      return { organization: { emptyOrganization } };
    },

    clearOrganizationList: () => {
      saveToLocalStorage("organizationList", []);
      return { organizationList: [] };
    },
  },
});

export const {
  setOrganizationList,
  getOrganizationList,
  setOrganization,
  clearOrganization,
  clearOrganizationList,
} = organizationSlice.actions;
export default organizationSlice.reducer;
