export const GR = {
  global: {
    scenarioLibraryLabel: "Βιβλιοθήκη σεναρίων",
    scenarioLibraryDescription:
      "Δυνατότητες δημιουργίας νέων σεναρίων και επεξεργασίας αποθηκευμένων",

    scenariosLabel: "Σενάρια",
    titleLabel: "Τίτλος",
    locationAddresslabel: "Τοποθεσία",
    authorLabel: "Συντάκτης",
    commentsLabel: "Σχόλια",
    openLabel: "Άνοιγμα",
    editLabel: "Επεξεργασία",
    saveLabel: "Αποθήκευση",
    updateLabel: "Ενημέρωση",
    deleteLabel: "Διαγραφή",
    cancelLabel: "Ακύρωση",
    scenarioTitleLabel: "Τίτλος σεναρίου",
    addElementLabel: "Προσθήκη",
    scenarioExecutionPanelLabel: "Πίνακας ελέγχου εκτέλεσης σεναρίου",
    textFundedByEU:
      "Το παρόν έργο έλαβε χρηματοδότηση από το Horizon 2020, το πρόγραμμα της Ευρωπαϊκής Ένωσης για Έρευνα και Καινοτομία κάτω από τη συμφωνία επιχορήγησης n° 883441",
    saveAndOpenScenario: "Αποθήκευση και άνοιγμα σεναρίου",
    openScenario: "Άνοιγμα σεναρίου",
    addScenario: "+ Προσθήκη Σεναρίου",
    addScenarioBasicData: "Προσθήκη Σεναρίου - Βασικά στοιχεία",
    languageLabel: "Γλώσσα",
  },
  sidebarLeft: {
    homeLabel: "Αρχική",
    projectDescriptionLabel: "Το έργο",
    toolDescriptionLabel: "PPT tool",
    historyLabel: "Ιστορικό",
    faqsLabel: "FAQs",
    settingsLabel: "Ρυθμίσεις",
    privacyPolicyLabel: "Πολιτική απορρήτου",
    contactUsLabel: "Επικοινωνία",
    searchLabel: "Αναζήτηση",
  },
  signIn: {
    signInLabel: "ΣΥΝΔΕΣΗ",
    usernamePlaceholder: "EMAIL",
    passwordPlaceholder: "ΚΩΔΙΚΟΣ",
    sendNewPassswordLabel: "Στείλε μου έναν νέο κωδικό",
    signInButtonLabel: "Σύνδεση",
    requiredField: "*Υποχρεωτικό πεδίο",
  },
  scenarioLibrary: {
    totalScenariosLabel: "Σύνολο σεναρίων",
    publicScenariosLabel: "Ανοιχτά σενάρια",
    userRegisteredLabel: "Εγγεγραμένοι χρήστες",
    loggedInUserScenarios: "Σενάρια χρήστη",
  },
  productLabels: {
    loadingMessageLoading: "Φόρτωση εφαρμογής...",
    loadingMessageComeLater: "Παρακαλώ δοκιμα΄στε αργότερα",
  },
  headerTitle: "PPT tool",
  breadcrumb: {
    homeScreen: "Αρχική οθόνη",
    scenarioLibrary: "Βιβλιοθήκη Σεναρίων",
    addScenarioBasicData: "Προσθήκη Σεναρίου - Βασικά στοιχεία",
  },
};
