import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loadEventCategoryList,
  saveEventCategory,
  deleteEventCategory,
} from "../services/";
import emptyEventCategory from "../utils/constants/data/empty-event-category";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

export const loadEventCategoryListThunk = createAsyncThunk(
  "eventCategory/loadEventCategoryList",
  async (thunkAPI, { dispatch }) => {
    const response = await loadEventCategoryList(thunkAPI);
    dispatch(setEventCategoryList(response));

    return response;
  }
);

export const saveEventCategoryThunk = createAsyncThunk(
  "eventCategory/saveEventCategory",
  async (thunkAPI, { dispatch }) => {
    const response = await saveEventCategory(thunkAPI);
    dispatch(loadEventCategoryListThunk(thunkAPI.scenarioId));
    dispatch(setEventCategory(response));
    return response;
  }
);

export const deleteEventCategoryThunk = createAsyncThunk(
  "eventCategory/deleteEventCategory",
  async (thunkAPI, { dispatch }) => {
    await deleteEventCategory(thunkAPI);
    dispatch(loadEventCategoryListThunk(thunkAPI.scenarioId));
    dispatch(getEventCategoryList());
    dispatch(clearEventCategory());
  }
);

export const eventCategorySlice = createSlice({
  name: "eventCategory",
  initialState: {
    eventCategory: getFromLocalStorage("eventCategory", "object")
      ? getFromLocalStorage("eventCategory", "object")
      : emptyEventCategory,
    eventCategoryList: getFromLocalStorage("eventCategoryList", "object")
      ? getFromLocalStorage("eventCategoryList", "array")
      : [],
  },
  reducers: {
    setEventCategoryList: (state, action) => {

      state.eventCategoryList = action.payload;
      saveToLocalStorage("eventCategoryList", state.eventCategoryList);
    },
    getEventCategoryList: (state) => {
      return state.eventCategory.eventCategoryList;
    },
    setEventCategory: (state, action) => {
      state.eventCategory = action.payload;
      saveToLocalStorage("eventCategory", state);
    },

    clearEventCategory: () => {
      saveToLocalStorage("eventCategory", emptyEventCategory);
      return { eventCategory: { emptyEventCategory } };
    },

    clearEventCategoryList: () => {
      saveToLocalStorage("eventCategoryList", []);
      return { eventCategoryList: [] };
    },
  },
});

export const {
  setEventCategoryList,
  getEventCategoryList,
  setEventCategory,
  clearEventCategory,
  clearEventCategoryList,
} = eventCategorySlice.actions;
export default eventCategorySlice.reducer;
