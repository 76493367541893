import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteLessonLearned, getLessonsLearned, saveLessonLearned } from "../services";

export const loadLessonsLearnedThunk = createAsyncThunk(
  "LessonsLearned/getLessonsLearned",
  async (thunkAPI, { dispatch }) => {
    const response = await getLessonsLearned(thunkAPI);
    dispatch(setLessonsLearned([...response]));
    return response;
  }
);

export const saveLessonLearnedThunk = createAsyncThunk(
  "LessonLearned/saveLessonLearned",
  async (thunkAPI, { dispatch }) => {
    const response = await saveLessonLearned(thunkAPI);
    dispatch(loadLessonsLearnedThunk(thunkAPI.scenarioId));
    return response;
  }
);

export const deleteLessonLearnedThunk = createAsyncThunk(
  "LessonLearned/deleteLessonLearned",
  async (thunkAPI, { dispatch }) => {
    await deleteLessonLearned(thunkAPI);
    dispatch(loadLessonsLearnedThunk(thunkAPI.scenarioId));
  }
);

const initialState = [];

export const lessonsLearnedSlice = createSlice({
  name: "LessonsLearned",
  initialState,
  reducers: {
    setLessonLearnedList: (state, action) => {
      state.LessonLearnedList = action.payload;
    },
    getLessonLearnedList: (state) => {
      return state.LessonLearnedList;
    },
    setLessonsLearned: (state, action) => {
      return action.payload;
    },
    clearLessonsLearned: () => {
      return [];
    },
  },
});

// each case under reducers becomes an action
export const { setLessonLearnedList, getLessonLearnedList, setLessonsLearned, clearLessonsLearned } = lessonsLearnedSlice.actions;
export default lessonsLearnedSlice.reducer;
