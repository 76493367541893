export const dataSourceFormFieldsInitial = {
  dataCount: 4,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "Resource Availability Model-1",
      description: "",
      name: "resourceAvailabilityModel1",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident",
            name: "eventCategory",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Start time",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
            isRange: false,
          },
        ],
      },
    },
    {
      dataId: "2",
      type: "DataRecord",
      label: "Resource Availability Model-2",
      description: "",
      name: "resourceAvailabilityModel2",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident",
            name: "eventCategory",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Start time",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
            isRange: false,
          },
        ],
      },
    },
    {
      dataId: "3",
      type: "DataRecord",
      label: "EWS - Reproduction Model Lithuania",
      description: "",
      name: "ewsReproductionModelLithuania",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident",
            name: "eventCategory",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location (Country)",
            name: "locationCountry",
            placeholder: "Insert the country",
            required: true,
          },
          {
            type: "Date",
            valueType: "string",
            description: "EPOCH",
            label: "Date",
            name: "date",
            constraint: {
              format: "EPOCH",
            },
            required: true,
            isRange: false,
          },
          {
            type: "Quantity",
            valueType: "integer",
            description: "",
            label: "New cases",
            name: "newCases",
            placeholder: "",
            constraint: {
              minValue: 0,
            },
            required: true,
          },
          {
            type: "Quantity",
            valueType: "integer",
            description: "",
            label: "New deaths",
            name: "newDeaths",
            placeholder: "",
            constraint: {
              minValue: 0,
            },
            required: true,
          },
          {
            type: "Numerical",
            valueType: "string",
            description: "",
            label: "Reproduction rate",
            name: "reproductionRrate",
            placeholder: "",
            required: true,
            decimals: 2,
            constraint: {
              minValue: 0,
            },
          },
          {
            type: "Quantity",
            valueType: "integer",
            description: "",
            label: "Hospital patients",
            name: "hospPatients",
            placeholder: "",
            constraint: {
              minValue: 0,
            },
            required: true,
          },
          // {
          //   type: "Time",
          //   valueType: "string",
          //   description: "e.g.: 2021-09-14T09:40:56.260Z",
          //   label: "Time Occurred",
          //   name: "timeOccurred",
          //   constraint: {
          //     format: "TimeISO8601",
          //   },
          //   required: true,
          //   isRange: false,
          // },
        ],
      },
    },
    {
      dataId: "4",
      type: "DataRecord",
      label: "ESBL_February_2021",
      description: "",
      name: "esblFebruary2021",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident",
            name: "eventCategory",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "File",
            valueType: "object",
            description: "",
            label: "ESBL data",
            name: "file",
            placeholder: "Select a file",
            required: true,
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Start time",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
            isRange: false,
          },
        ],
      },
    },
  ],
};

const dataSourceFormFields = (eventCategoryList) => {
  dataSourceFormFieldsInitial.value.forEach((e) => {
    e.data.field[0].constraint.value = eventCategoryList;
  });

  return dataSourceFormFieldsInitial;
};

export default dataSourceFormFields;
