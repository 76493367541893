import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import scenarioEmpty from "./../utils/constants/data/scenario-empty";
import { saveScenario } from "../services";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

export const saveScenarioThunk = createAsyncThunk(
  "scenario/saveScenario",
  async (thunkAPI, { dispatch }) => {
    const response = await saveScenario(thunkAPI);
    dispatch(setScenario({ ...response }));
    return response;
  }
);

const initialState = getFromLocalStorage("scenario", "object")
  ? getFromLocalStorage("scenario", "object")
  : scenarioEmpty;

export const scenarioSlice = createSlice({
  name: "scenario",
  initialState,
  reducers: {
    setScenario: (state, action) => {
      saveToLocalStorage("scenario", action.payload);
      return action.payload;
    },

    clearScenario: () => {
      saveToLocalStorage("scenario", scenarioEmpty);
      return scenarioEmpty;
    },
    addElementToScenarioReducer: (state, action) => {
      const scenarioClone = { ...action.payload };
      if (typeof action.payload.message === "object") {
        action.payload.message = JSON.stringify(action.payload.message);
      }
      scenarioClone.script.push([action.payload]);
      saveScenarioThunk();
      return scenarioClone;
    },
    setScenarioLocationReducer: (state, action) => {
      return { ...state.scenario, location: action.payload };
    },
    setScenarioLocationAddressReducer: (state, action) => {
      return { ...state.scenario, locationAddress: action.payload };
    },
    setOrUpdateScenarioFieldReducer: (state, action) => {
      return {
        ...state.scenario,
        [action.payload.fieldName]: action.payload.value,
      };
    },
    saveScenarioToDbReducer: (state, action) => {
      state.scenario[action.payload.fieldName] = action.payload.value;
    },
  },
});

// each case under reducers becomes an action
export const {
  setScenario,
  clearScenario,
  fillScenarioReducer,
  setScenariolocationReducer,
  setScenarioLocationAddressReducer,
  setOrUpdateScenarioFieldReducer,
  addElementToScenarioReducer,
  updateElementToScenario,
} = scenarioSlice.actions;
export default scenarioSlice.reducer;
