// eslint-disable-next-line no-undef
const REACT_APP_MODE = process.env.REACT_APP_MODE || "dev";
const proj_name = process.env.REACT_APP_PROJECT_NAME || "default";

const MAIN_URL_LOCAL = "http://localhost";

const MAIN_URL_SERVER = "https://stamina-ppt.exus.co.uk";
const MAIN_URL_SERVER_NIGHTINGALE = "https://nightingale.exus.co.uk";
const MAIN_URL_SERVER_SILVANUS = "https://silvanus.exus.co.uk";
const MAIN_URL_SERVER_COPROTECT = "https://co-protect.scenario-builder.eu";

export const SEND_TO_KAFKA = true;

const properties = {
  deploymentLevel: REACT_APP_MODE, // "dev" or "prod"
  url: {
    mainUrl: {
      local: `${MAIN_URL_LOCAL}:8080`,
      server:
        proj_name === "nightingale"
          ? MAIN_URL_SERVER_NIGHTINGALE
          : proj_name === "silvanus"
          ? MAIN_URL_SERVER_SILVANUS
          : proj_name === "co-protect"
          ? MAIN_URL_SERVER_COPROTECT
          : MAIN_URL_SERVER,
    },
    mainUrlAuth: {
      local: "http://localhost:8080",
      server:
        proj_name === "nightingale"
          ? MAIN_URL_SERVER_NIGHTINGALE
          : proj_name === "silvanus"
          ? MAIN_URL_SERVER_SILVANUS
          : proj_name === "co-protect"
          ? MAIN_URL_SERVER_COPROTECT
          : MAIN_URL_SERVER,
    },
    apiScenario: "/api/scenario/",
    apiScenarioDuplicate: "/api/scenario/duplicate",
    apiOrganization: "/api/organization/",
    apiParticipant: "/api/participant/",
    apiEventCategory: "/api/eventCategory/",
    apiKPI: "/api/scenarioKPI/",
    apiLessonLearned: "/api/lessonLearned/",
    apiComment: "/api/comment/",
    apiKafkaSendElement: {
      local: "/kafka/sent/element/",
      server: "/api/kafka/sent/element/",
    },
    apiUsersListAndOrganizations: "/api/user-organization-info/",
  },
  auth: {
    authServerSignIn: "/api/token",
    scope: "dashboard",
  },

  elementTypes: {
    EVENT: "Event",
    MESSAGE: "Message",
    SOCIAL_MEDIA: "Social Media",
    SOCIAL_MEDIA_BATCH: "Social Media Batch",
    TIME_BETWEEN: "Time Between Elements",
    DATA_SOURCE: "Data Source",
    MEDICAL_ASSET: "Medical Asset",
  },

  googleMapsApi: {
    url: {
      getAddressFromCoordinates:
        "https://maps.googleapis.com/maps/api/geocode/json",
    },
    key: "AIzaSyCh3mVkIOPRWnzFhzGm9uDF-80b3ZlGizo",
  },

  storageKeyNamePrefix: "sb_tool_",
};

export default properties;
