export const countriesEUwithAlpha2code = [
  { isoCode: "AT", name: "Austria" },
  { isoCode: "BE", name: "Belgium" },
  { isoCode: "BG", name: "Bulgaria" },
  { isoCode: "HR", name: "Croatia" },
  { isoCode: "CY", name: "Cyprus" },
  { isoCode: "CZ", name: "Czech Republic" },
  { isoCode: "DK", name: "Denmark" },
  { isoCode: "EE", name: "Estonia" },
  { isoCode: "FI", name: "Finland" },
  { isoCode: "FR", name: "France" },
  { isoCode: "DE", name: "Germany" },
  { isoCode: "GR", name: "Greece" },
  { isoCode: "HU", name: "Hungary" },
  { isoCode: "IE", name: "Ireland, Republic of (EIRE)" },
  { isoCode: "IT", name: "Italy" },
  { isoCode: "LV", name: "Latvia" },
  { isoCode: "LT", name: "Lithuania" },
  { isoCode: "LU", name: "Luxembourg" },
  { isoCode: "MT", name: "Malta" },
  { isoCode: "NL", name: "Netherlands" },
  { isoCode: "PL", name: "Poland" },
  { isoCode: "PT", name: "Portugal" },
  { isoCode: "RO", name: "Romania" },
  { isoCode: "SK", name: "Slovakia" },
  { isoCode: "SI", name: "Slovenia" },
  { isoCode: "ES", name: "Spain" },
  { isoCode: "SE", name: "Sweden" },
  { isoCode: "GB", name: "United Kingdom (Great Britain)" },

  { isoCode: "GF", name: "French Guiana" },
  { isoCode: "GP", name: "Guadeloupe" },
  { isoCode: "MQ", name: "Martinique" },
  { isoCode: "ME", name: "Montenegro" },
  { isoCode: "YT", name: "Mayotte" },
  { isoCode: "RE", name: "Réunion" },
  { isoCode: "MF", name: "Saint Martin" },

  { isoCode: "GI", name: "Gibraltar" },
  { isoCode: "AX", name: "Åland Islands" },

  { isoCode: "PM", name: "Saint Pierre and Miquelon" },
  { isoCode: "GL", name: "Greenland" },
  { isoCode: "BL", name: "Saint Bartelemey" },
  { isoCode: "SX", name: "Sint Maarten" },
  { isoCode: "AW", name: "Aruba" },
  { isoCode: "CW", name: "Curacao" },
  { isoCode: "WF", name: "Wallis and Futuna" },
  { isoCode: "PF", name: "French Polynesia" },
  { isoCode: "NC", name: "New Caledonia" },
  { isoCode: "TF", name: "French Southern Territories" },
  { isoCode: "AI", name: "Anguilla" },
  { isoCode: "BM", name: "Bermuda" },
  { isoCode: "IO", name: "British Indian Ocean Territory" },
  { isoCode: "VG", name: "Virgin Islands, British" },
  { isoCode: "KY", name: "Cayman Islands" },
  { isoCode: "FK", name: "Falkland Islands (Malvinas)" },
  { isoCode: "MS", name: "Montserrat" },
  { isoCode: "PN", name: "Pitcairn" },
  { isoCode: "SH", name: "Saint Helena" },
  { isoCode: "GS", name: "South Georgia and the South Sandwich Islands" },
  { isoCode: "TC", name: "Turks and Caicos Islands" },

  { isoCode: "AD", name: "Andorra" },
  { isoCode: "LI", name: "Liechtenstein" },
  { isoCode: "MC", name: "Monaco" },
  { isoCode: "SM", name: "San Marino" },
  { isoCode: "VA", name: "Vatican City" },

  { isoCode: "JE", name: "Jersey" },
  { isoCode: "GG", name: "Guernsey" },
  { isoCode: "GI", name: "Gibraltar" },
];

export function getCountryCode(country) {
  return countriesEUwithAlpha2code
    .filter((c) => c.name === country)
    .map((filteredObj) => filteredObj.isoCode);
}
