import involvedCountriesStringList from "./../data/involved-countries-string-list";

const scenarioBasicDataFormFields = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "Scenario",
      description: "",
      name: "scenario",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Scenario Title",
            name: "title",
            required: true,
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Scenario Objectives",
            name: "scenarioGoal",
            required: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Scenario location coordinates",
            name: "locationCoordinates",
            disabled: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location Address",
            name: "locationAddress",
            placeholder: "If exists will appear after point selection on map",
            required: false,
            disabled: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Involved countries",
            name: "involvedCountries",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: involvedCountriesStringList,
              minValue: 1,
            },
            multiSelect: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Trial name (only letters and dashes allowed)",
            placeholder: "Enter the name of the Trial",
            required: false,
            name: "trial",
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Scenario comments",
            name: "comments",
            required: true,
            rows: 2,
          },
        ],
      },
    },
  ],
};

export default scenarioBasicDataFormFields;
