import React from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";
import { useField } from "formik";
import { LabelRequiredAndErrorMessage } from "./helpers";

const ColorTypeFieldFormik = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { returnSelection, name, fieldLabel, required } = props;

  const { setValue } = helpers;

  const updateState = (input) => {
    setValue(input);
    returnSelection({ value: input, name });
  };

  return (
    <React.Fragment>
      <BootstrapForm.Group controlId={`formBasic${fieldLabel}`}>
        <BootstrapForm.Label>{fieldLabel}</BootstrapForm.Label>
        <BootstrapForm.Control
          type="color"
          //defaultValue="#563d7c"
          title="Choose Event Category color"
          value={field.value}
          onChange={(e) => updateState(e.target.value)}
        />

        <LabelRequiredAndErrorMessage meta={meta} required={required} />
      </BootstrapForm.Group>
    </React.Fragment>
  );
};

ColorTypeFieldFormik.propTypes = {
  returnSelection: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  hideRequiredText: PropTypes.bool.isRequired,
};

ColorTypeFieldFormik.defaultProps = {
  name: "objectName",
  fieldLabel: "",
  required: false,
  hideRequiredText: false,
};

export default ColorTypeFieldFormik;
