import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteComment, getComments, saveComment } from "../services";

export const loadCommentsThunk = createAsyncThunk(
  "Comments/getComments",
  async (thunkAPI, { dispatch }) => {
    const response = await getComments(thunkAPI);
    dispatch(setComments([...response]));
    return response;
  }
);

export const saveCommentThunk = createAsyncThunk(
  "Comment/saveComment",
  async (thunkAPI, { dispatch }) => {
    const response = await saveComment(thunkAPI);
    dispatch(loadCommentsThunk(thunkAPI.scenarioId));
    return response;
  }
);

export const deleteCommentThunk = createAsyncThunk(
  "Comment/deleteComment",
  async (thunkAPI, { dispatch }) => {
    await deleteComment(thunkAPI);
    dispatch(loadCommentsThunk(thunkAPI.scenarioId));
  }
);

const initialState = [];

export const commentSlice = createSlice({
  name: "Comments",
  initialState,
  reducers: {
    setCommentList: (state, action) => {
      state.CommentList = action.payload;
    },
    getCommentList: (state) => {
      return state.CommentList;
    },
    setComments: (state, action) => {
      return action.payload;
    },
    clearComments: () => {
      return [];
    },
  },
});

// each case under reducers becomes an action
export const { setCommentList, getCommentList, setComments, clearComments } = commentSlice.actions;
export default commentSlice.reducer;
