export const EN = {
  global: {
    scenarioLibraryLabel: "Scenario library",
    scenarioLibraryDescription:
      "Here the user is able to create new scenarios and/or edit existing ones",
    scenariosLabel: "Scenarios",
    titleLabel: "Title",
    locationAddresslabel: "Location Address",
    authorLabel: "Author",
    commentsLabel: "Comments",
    openLabel: "Open",
    editLabel: "Edit",
    viewLabel: "View",
    saveLabel: "Save",
    updateLabel: "Update",
    deleteLabel: "Delete",
    cancelLabel: "Cancel",
    saveAndGoToNextStep: "Save and go to next step",
    nextStepLabel: "Next Step",
    previousStepLabel: "Previous Step",
    scenarioTitleLabel: "Pandemic Scenario title",
    addElementLabel: "Add element",
    eventCategoryListLabel: "Incident Category List",
    eventCategoryLabel: "Incident Category",
    eventCategoryAddNewLabel: "Add Incident Category",
    eventCategoryDeleteLabel: "Delete the Incident Category",
    scenarioExecutionPanelLabel: "Scenario execution control panel",
    confirmLabel: "Confirm",
    textFundedByEU:
      "This project has received funding from Horizon 2020, the European Union’s Programme for Research and Innovation under grant agreement n° 883441",

    textFundedByEU_NIGHTINGALE:
      "This project has received funding from Horizon 2020, the European Union’s Programme for Research and Innovation under grant agreement n° 101021957",

    textFundedByEU_SILVANUS:
      "This project has received funding from Horizon 2020, the European Union’s Programme for Research and Innovation under grant agreement n° 101037247",
    textFundedByEU_COPROTECT:
      "",

    saveAndOpenScenario: "Save and open scenario",
    openScenario: "Open scenario",
    addScenario: "+ Add Scenario",
    addScenarioBasicData: "Add Scenario - Basic Data",
    languageLabel: "Language",
    addLessonLearned: "+ Add Lesson Learned",
    addComment: "+ Add Comment",
  },
  sidebarLeft: {
    homeLabel: "Home",
    projectDescriptionLabel: "The project",
    toolDescriptionLabel: "PPT tool",
    historyLabel: "History",
    faqsLabel: "FAQs",
    settingsLabel: "Settings",
    privacyPolicyLabel: "Privacy policy",
    contactUsLabel: "Contact us",
    searchLabel: "Search",
  },
  signIn: {
    signInLabel: "LOG IN",
    signOutLabel: "LOG OUT",
    usernamePlaceholder: "EMAIL",
    passwordPlaceholder: "PASSWORD",
    sendNewPassswordLabel: "Send me a new password",
    signInButtonLabel: "LOG IN",
    requiredField: "*Required",
  },
  scenarioLibrary: {
    totalScenariosLabel: "Total scenarios",
    publicScenariosLabel: "Public Scenarios",
    userRegisteredLabel: "Users registered",
    loggedInUserScenarios: "User's Scenarios",
  },
  productLabels: {
    loadingMessageLoading: "Loading...",
    loadingMessageComeLater: "Please come back later",
  },
  addEditScenario: {},
  organization: {
    addNewOrganization: "Add Organization",
  },
  participant: {
    addNewParticipant: "Add Participant",
  },
  headerTitle: "PPT tool",
  breadcrumb: {
    homeScreen: "Home screen",
    scenarioLibrary: "Scenario Library",
    addScenarioBasicData: "Add Scenario - Basic Data",
  },
  kpi: {
    addNewKPI: "Add new KPI",
  },
};
