import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Formik, Form } from "formik";
import properties from "../../utils/properties";
import CreateFormField from "../../utils/helpers/create-form-field";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import { useSelector, useDispatch } from "react-redux";
import { dataSourceFormFieldsInitial } from "../../utils/constants/forms/data-source-form-fields";
import { addToast } from "../../utils/helpers/alerts";
import { saveScenarioThunk } from "../../redux/scenarioSlice";
import { createUseStyles } from "react-jss";
import { fillElementForDisplay } from "./helpers/fillElementForDisplay";
import { createInitialValues } from "./helpers/initialValues";
import { cleanElementForSave } from "./helpers/cleanElementForSave";
import { createFormFieldsSchema } from "./helpers/createFormFieldsSchema";
import { elementStyles } from "./helpers/elementStyles";
import _ from "lodash";
import { deleteElement } from "./helpers";

const useStyles = createUseStyles({ ...elementStyles });
const ElementStatic = (props) => {
  const {
    elementType,
    mapHeight,
    show,
    handleClose,
    eventCategoryList,
    noEditAccess,
  } = props;

  let element = fillElementForDisplay({
    element: { ...useSelector((state) => state.element), type: elementType },
    eventCategoryList,
  });
  if (elementType === "SOCIAL_MEDIA" || elementType === "SOCIAL_MEDIA_BATCH") {
    element = { ...element, socialMediaType: [element.socialMediaType] };
  }

  const classes = useStyles({ elementHasId: Boolean(element.id) });

  const dispatch = useDispatch();

  const [selectedElementObject, setSelectedElementObject] = useState(-1);
  const [deletePrompt, setDeletePrompt] = useState(false);

  const scenario = useSelector((state) => state.scenario);

  const scriptLength = scenario.script.length;

  const participantList = useSelector(
    (state) => state.participant.participantList
  );

  // eslint-disable-next-line max-statements
  const onSubmit = (values) => {
    dispatch(
      saveScenarioThunk(
        cleanElementForSave({
          scenario,
          values,
          elementType,
          element,
        })
      )
    ).then(() => {
      addToast(
        "success",
        `A new ${elementType} has been added successfully!`,
        3000
      );
      handleClose();
    });
  };

  const formFieldsSchema = createFormFieldsSchema({
    elementType,
    eventCategoryList,
    participantList,
    scriptLength,
  });

  useEffect(() => {
    if (element.dataSource) {
      setSelectedElementObject(
        dataSourceFormFieldsInitial.value.findIndex(
          (d) => d.name === element.data[0].dataSource
        )
      );
    }
  }, []);

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        className={classes.modalAddElement}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {elementType
              ? `Add ${properties.elementTypes[elementType]}`
              : `Edit ${properties.elementTypes[element.type]}`}
          </Modal.Title>
        </Modal.Header>

        {elementType !== "SOCIAL_MEDIA_BATCH" ? (
          <Formik
            initialValues={createInitialValues({
              element,
              scriptLength,
              coords: scenario.location.coordinates,
              dataSourceIndex: 0,
            })}
            enableReinitialize={true}
            validationSchema={createValidationSchema({
              formFieldsSchema,
              index: selectedElementObject,
              elementType,
            })}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            <Form>
              <Modal.Body>
                {elementType !== "DATA_SOURCE" && (
                  <React.Fragment>
                    {formFieldsSchema &&
                      formFieldsSchema.value[0]?.data.field.map(
                        (field, idx) => (
                          <CreateFormField
                            key={idx}
                            field={
                              field.type !== "Location"
                                ? { ...field, mapHeight }
                                : {
                                    ...field,
                                    mapHeight,
                                    coordinates: scenario.location.coordinates,
                                  }
                            }
                            index={idx}
                            participantList={participantList}
                            elementType={elementType}
                          />
                        )
                      )}
                  </React.Fragment>
                )}
              </Modal.Body>
              <Modal.Footer>
                {deletePrompt && (
                  <div className={classes.deletePrompt}>
                    Please confirm to delete!
                    <span className={classes.actionBtnsRightColumn}>
                      <button
                        className={`${classes.actionBtn} ${classes.actionBtnCancel}`}
                        onClick={() => setDeletePrompt(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                        onClick={() =>
                          deleteElement({
                            elementId: element.id,
                            scenario,
                            dispatch,
                            handleClose,
                          })
                        }
                        type="button"
                      >
                        DELETE
                      </button>
                    </span>
                  </div>
                )}
                {!deletePrompt && (
                  <span className={classes.actionBtns}>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnCancel}`}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    {!noEditAccess && (
                      <span className={classes.actionBtnsRightColumn}>
                        {element.id && (
                          <button
                            className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                            onClick={() => setDeletePrompt(true)}
                          >
                            Delete
                          </button>
                        )}

                        <button
                          className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
                          type="submit"
                        >
                          Save
                        </button>
                      </span>
                    )}
                  </span>
                )}
              </Modal.Footer>
            </Form>
          </Formik>
        ) : (
          <Formik
            initialValues={createInitialValues({
              element: { ...element, type: "SOCIAL_MEDIA_BATCH" },
              scriptLength,
              coords: scenario.location.coordinates,
              dataSourceIndex: 0,
            })}
            enableReinitialize={true}
            validationSchema={createValidationSchema({
              formFieldsSchema,
              index: selectedElementObject,
              elementType,
            })}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            <Form>
              <Modal.Body>
                <React.Fragment>
                  {formFieldsSchema &&
                    formFieldsSchema.value[0]?.data.field.map((field, idx) => (
                      <CreateFormField
                        key={idx}
                        field={
                          field.type !== "Location"
                            ? field
                            : {
                                ...field,
                                mapHeight,
                                coordinates: scenario.location.coordinates,
                              }
                        }
                        index={idx}
                        participantList={participantList}
                        elementType={elementType}
                      />
                    ))}
                </React.Fragment>
              </Modal.Body>
              <Modal.Footer>
                {deletePrompt && (
                  <div className={classes.deletePrompt}>
                    Please confirm to delete!
                    <span className={classes.actionBtnsRightColumn}>
                      <button
                        className={`${classes.actionBtn} ${classes.actionBtnCancel}`}
                        onClick={() => setDeletePrompt(false)}
                      >
                        Cancel
                      </button>
                      <button
                        className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                        onClick={() =>
                          deleteElement({
                            elementId: element.id,
                            scenario,
                            dispatch,
                            handleClose,
                          })
                        }
                        type="button"
                      >
                        DELETE
                      </button>
                    </span>
                  </div>
                )}
                {!deletePrompt && (
                  <span className={classes.actionBtns}>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnCancel}`}
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    {!noEditAccess && (
                      <span className={classes.actionBtnsRightColumn}>
                        {element.id && (
                          <button
                            className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                            onClick={() => setDeletePrompt(true)}
                          >
                            Delete
                          </button>
                        )}

                          <button
                            className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
                            type="submit"
                          >
                          Save
                        </button>
                      </span>
                    )}
                  </span>
                )}
              </Modal.Footer>
            </Form>
          </Formik>
        )}
      </Modal>
    </React.Fragment>
  );
};

ElementStatic.propTypes = {
  elementType: PropTypes.string.isRequired,
  mapHeight: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  eventCategoryList: PropTypes.array.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
};

ElementStatic.defaultProps = {
  elementType: "",
  formFieldsSchema: {},
  mapHeight: "",
  show: false,
  eventCategoryList: [],
  noEditAccess: true,
};

export default ElementStatic;
