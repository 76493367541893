import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { variables, myButton } from "../../jssVariables";
import { createUseStyles } from "react-jss";
import { AddEditKPI } from "./helpers";
import { addToast } from "../../utils/helpers/alerts";

const keyPerformanceIndicatorsStyles = createUseStyles({
  tablePanel: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: "1rem",
    background: variables.colors.basicLight,
    borderRadius: "0.5rem",
    gridGap: ".5rem",
  },
  title: {
    fontSize: "medium",
    fontFamily: variables.fontHeaderFamily,
  },
  table: {
    fontSize: "small",
  },
  columnBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: ".5rem",
    width: "100%",
    justifyContent: "end",
  },
  actionBtns: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  actionBtnSubmit: {
    marginTop: "2rem",
    justifySelf: "end",
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
export const KeyPerformanceIndicators = (props) => {
  const { t } = useTranslation();
  const { isInTimeline } = props;
  const dispatch = useDispatch();
  const [showModalAddEdit, setShowModalAddEdit] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [kpi, setKpi] = useState({});

  const classes = keyPerformanceIndicatorsStyles();

  const navigate = useNavigate();

  const { kpis } = useSelector((state) => state);

  const [kpisList, setKpisList] = useState([...kpis]);

  useEffect(() => {
    console.log("here kpis ", kpis);
    setKpisList(kpis);
  }, [kpis]);

  const { accessType } = useSelector((state) => state.scenario);
  const noEditAccess = ["READ_ACCESS"].includes(accessType);

  function nextStep() {
    // if (kpisList?.length === 0) {
    //   addToast(
    //     "info",
    //     "You need to create Participant(s) in order to proceed.",
    //     2500
    //   );
    // } else {
    //   navigate("/scenario-settings");
    // }
    navigate("/scenario-settings");
  }

  function previousStep() {
    navigate("/add-edit-participants");
  }
  return (
    <div className={classes.tablePanel}>
      {showModalAddEdit && (
        <AddEditKPI
          showModal={showModalAddEdit}
          setShowModal={setShowModalAddEdit}
          kpi={kpi}
          setKpi={setKpi}
          onDelete={onDelete}
          setOnDelete={setOnDelete}
        />
      )}
      {!isInTimeline && (
        <span className={classes.title}>
          {"Add/Edit Key Performance Indicators (KPIs)"}
        </span>
      )}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.columnIndex}>#</th>
            <th className={classes.columnName}>{"KPI"}</th>
            <th className={classes.columnMetricType}>{"Metric Type"}</th>
            <th className={classes.columnMetricStart}>{"Start"}</th>
            <th className={classes.columnMetricTarget}>{"Target"}</th>
            <th className={classes.columnBtns}></th>
          </tr>
        </thead>

        <tbody>
          {kpisList.length > 0 &&
            kpisList.map((kpiTemp, i) => (
              <tr key={i}>
                <td key={i}>{i + 1}</td>
                <td>{kpiTemp.name}</td>
                <td>{kpiTemp.type.value}</td>
                <td>{kpiTemp.start}</td>
                <td>{kpiTemp.target}</td>
                <td>
                  <div className={classes.columnBtnsContainer}>
                    <button
                      className={classes.actionBtn}
                      onClick={() => {
                        setShowModalAddEdit(true);
                        setKpi({
                          name: "",
                          type: kpiTemp.type,
                          start: kpiTemp.start,
                          target: kpiTemp.target,
                        });
                      }}
                      disabled={noEditAccess}
                    >
                      {"Clone"}
                    </button>
                    <button
                      className={classes.actionBtn}
                      onClick={() => {
                        setShowModalAddEdit(true);
                        setKpi(kpiTemp);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.editLabel")}
                    </button>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                      onClick={() => {
                        setShowModalAddEdit(true);
                        setOnDelete(true);
                        setKpi(kpiTemp);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.deleteLabel")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className={classes.actionBtnPanel}>
        <button
          className={classes.actionBtn}
          disabled={noEditAccess}
          onClick={() => setShowModalAddEdit(true)}
        >
          {t("kpi.addNewKPI")}
        </button>
      </div>
      {!isInTimeline && (
        <div className={classes.actionBtns}>
          <button
            className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
            disabled={false}
            onClick={previousStep}
          >
            {t("global.previousStepLabel")}
          </button>
          <button
            className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
            disabled={false}
            onClick={nextStep}
          >
            {t("global.nextStepLabel")}
          </button>{" "}
        </div>
      )}
    </div>
  );
};
KeyPerformanceIndicators.defaultProps = {
  isInTimeline: false,
};
KeyPerformanceIndicators.propTypes = {
  isInTimeline: PropTypes.bool,
};

const keyPerformanceIndicatorsContainerStyles = createUseStyles({
  addEditparticipantContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    padding: "1rem",
    paddingTop: ".5rem",
    gridGap: ".5rem",
    width: "max-content",
  },
  breadcrumb: {
    padding: "0",
  },
  breadcrumbItem: {
    fontSize: variables.breadcrumb.fontSize,
    color: variables.colors.basicBlue,
  },
});
const KeyPerformanceIndicatorsContainer = () => {
  const classes = keyPerformanceIndicatorsContainerStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.addEditparticipantContainer}>
      <Breadcrumb className={classes.breadcrumb}>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/")}
        >
          Home Screen
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/scenario-library")}
        >
          Scenario Library
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-scenario-main-data")}
        >
          Add/Edit Scenario - Basic data
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-organizations")}
        >
          Add/Edit Organizations
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-participants")}
        >
          Add/Edit Participants
        </Breadcrumb.Item>
        <Breadcrumb.Item className={classes.breadcrumbItem} active>
          Add/Edit Key Performance Indicators (KPIs)
        </Breadcrumb.Item>
      </Breadcrumb>
      <KeyPerformanceIndicators />
    </div>
  );
};
export default KeyPerformanceIndicatorsContainer;
