const lessonLearnedFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "LessonLearned",
      description: "",
      name: "lessonLearned",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Title",
            name: "title",
            placeholder: "Lesson learned title",
            required: true,
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Lesson Learned",
            name: "text",
            placeholder: "Details of this lesson learned",
            rows: 4,
            width: 450,
          },
        ],
      },
    },
  ],
};

const lessonLearnedFormFields = () => {
  return lessonLearnedFormFieldsInitial;
};
export default lessonLearnedFormFields;
