import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import { addToast } from "./../../../utils/helpers/alerts";
import "./form-field.css";
import { createUseStyles } from "react-jss";
import { useField, useFormikContext } from "formik";
import { variables, myButton } from "../../../jssVariables";

const useStyles = createUseStyles({
  label: {
    fontSize: "x-small",
    marginBottom: "0",
  },
  formField: {
    fontSize: "x-small",
  },
  errorMessage: {
    display: "grid",
    justifyItems: "end",
    color: "red",
    fontSize: "x-small",
  },
  requiredLabel: {
    display: "grid",
    justifyItems: "end",
    fontSize: "x-small",
  },
  replaceFileContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gap: "1rem",
  },
  replaceFile: {
    border: `1px solid rgba(204,204,204, 1)`,
    borderRadius: "5px",
    alignItems: "center",
    fontSize: "small",
    padding: ".5rem .75rem",
  },
  replaceFileBtn: {
    ...myButton,
    margin: ".2rem 0",
  },
});
const FileTypeFieldFormik = (props) => {
  const { fieldLabel, required, disabled } = props;

  const fileSizeLimit = 1000000;

  const [field, meta, helpers] = useField(props);

  const [show, setShow] = useState(false);

  const { setValue } = helpers;

  const classes = useStyles();

  const handleFileRead = async (event) => {
    // eslint-disable-next-line prefer-destructuring
    const file = event.target.files[0];

    if (file.size >= fileSizeLimit) {
      addToast(
        "error",
        `File size limit is ${fileSizeLimit / 1000} kBytes`,
        3000
      );
    } else if (
      file.type.includes("png") ||
      file.type.includes("jpeg") ||
      file.type.includes("jpg") ||
      file.type.includes("gif") ||
      file.type.includes("bmp") ||
      file.type.includes("svg") ||
      file.type.includes("webp")
    ) {
      const base64 = await convertBase64(file);

      setValue({
        name: file.name,
        type: file.type,
        size: file.size,
        lastModifiedDate: file.lastModifiedDate,
        encodingMethod: "Base64",
        encodedData: base64,
      });
    } else if (file.type.includes("json")) {
      const fileReader = new FileReader();
      fileReader.readAsText(event.target.files[0], "UTF-8");
      fileReader.onload = (e) => {
        setValue({
          name: file.name,
          type: file.type,
          size: file.size,
          lastModifiedDate: file.lastModifiedDate,
          data: JSON.parse(e.target.result),
        });
      };
    } else {
      addToast("error", `Unsupported format of type: ${file.type}`, 3000);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  return (
    <React.Fragment>
      <Form.Group controlId={`formBasic${fieldLabel}`}>
        <Form.Label>{fieldLabel}</Form.Label>
        {field.value && !show && (
          <div className={classes.replaceFileContainer}>
            <div className={classes.replaceFile}>
              <label>
                {"Saved file's name: "}
                <strong>
                  {field.value.length
                    ? ` ${field.value[0]?.name}`
                    : ` ${field.value?.name}`}
                </strong>
              </label>
            </div>
            <button
              className={classes.replaceFileBtn}
              onClick={() => setShow(true)}
            >
              replace file
            </button>
          </div>
        )}

        {(show || !field.value) && (
          <Form.Control type="file" onChange={(e) => handleFileRead(e)} />
        )}

        {required && <div className={classes.requiredLabel}>*Required</div>}
      </Form.Group>
    </React.Fragment>
  );
};
FileTypeFieldFormik.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

FileTypeFieldFormik.defaultProps = {
  fieldLabel: "",
  required: false,
  disabled: false,
};

export default FileTypeFieldFormik;
