import React from "react";
import euFlag from "../../assets/images/EU-Flag-icon-72x48.png";
import { useTranslation } from "react-i18next";
import { createUseStyles } from "react-jss";
import { variables } from "../../jssVariables";

const proj_name = process.env.REACT_APP_PROJECT_NAME || "default";

const useStyles = createUseStyles({
  footer: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    justifyContent: "center",
    padding: ".5rem 0",
  },
  text: {
    width: "100%",
    color: variables.colors.basicDark,
    fontSize: "small",
  },
  imageContainer: {
    width: "100%",
    display: "grid",
    gridTemplateRows: "1fr",
    justifyContent: "center",
  },
  image: {
    width: "3rem",
  },
});
const Footer = () => {
  const { t } = useTranslation();
  const locationUrl = location.pathname;
  const classes = useStyles();

  return locationUrl !== "/scenario" ? (
    <div className={classes.footer}>
      <span className={classes.text}>
        {proj_name === "nightingale"
          ? t("global.textFundedByEU_NIGHTINGALE")
          : proj_name === "silvanus"
          ? t("global.textFundedByEU_SILVANUS")
          : proj_name === "co-protect"
          ? t("global.textFundedByEU_COPROTECT")
          : t("global.textFundedByEU")}
      </span>
      {(proj_name !== "co-protect") &&
        <div className={classes.imageContainer}>
          <img className={classes.image} src={euFlag} alt="EU Flag" />
        </div>
      }
    </div>
  ) : null;
};

export default Footer;
