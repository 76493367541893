import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { variables, myButton } from "../../jssVariables";
import { createUseStyles } from "react-jss";
import { AddEditParticipant } from "./helpers";
import { addToast } from "../../utils/helpers/alerts";

const addEditParticipantsStyles = createUseStyles({
  tablePanel: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: "1rem",
    background: variables.colors.basicLight,
    borderRadius: "0.5rem",
    gridGap: ".5rem",
  },
  title: {
    fontSize: "medium",
    fontFamily: variables.fontHeaderFamily,
  },
  table: {
    fontSize: "small",
  },
  columnBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: ".5rem",
    width: "100%",
    justifyContent: "end",
  },
  actionBtns: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  actionBtnSubmit: {
    marginTop: "2rem",
    justifySelf: "end",
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
// eslint-disable-next-line max-statements
export const AddEditParticipants = (props) => {
  const { t } = useTranslation();
  const { isInTimeline } = props;

  const [participantNames, setParticipantNames] = useState();

  const [showModalAddEdit, setShowModalAddEdit] = useState(false);
  const [onDelete, setOnDelete] = useState(false);
  const [participant, setParticipant] = useState({});

  const classes = addEditParticipantsStyles();

  const navigate = useNavigate();

  const participantListFromState = useSelector(
    (state) => state.participant.participantList
  );

  const { accessType } = useSelector((state) => state.scenario);
  const noEditAccess = ["READ_ACCESS"].includes(accessType);

  const [participantList, setParticipantList] = useState([]);

  useEffect(() => {
    setParticipantList(participantListFromState);
  }, [participantListFromState]);

  function nextStep() {
    if (participantList?.length === 0) {
      addToast(
        "info",
        "You need to create Participant(s) in order to proceed.",
        2500
      );
    } else {
      navigate("/scenario-KPIs");
    }
  }

  function previousStep() {
    navigate("/add-edit-organizations");
  }

  function getParticipantNames() {
    if (participantListFromState.length) {
      setParticipantNames(participantListFromState.map((p) => p.name));
    }
  }

  useEffect(() => {
    getParticipantNames();
  }, [participantListFromState]);

  return (
    <div className={classes.tablePanel}>
      {showModalAddEdit && (
        <AddEditParticipant
          showModal={showModalAddEdit}
          setShowModal={setShowModalAddEdit}
          participant={participant}
          setParticipant={setParticipant}
          onDelete={onDelete}
          setOnDelete={setOnDelete}
          participantListFromState={participantListFromState}
        />
      )}
      {!isInTimeline && (
        <span className={classes.title}>{"Add/Edit Participants"}</span>
      )}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.columnIndex}>#</th>
            <th className={classes.columnName}>{"Name"}</th>
            <th className={classes.columnLevelOfCommand}>
              {"Level of Command"}
            </th>
            <th className={classes.columnOrganization}>{"Organization"}</th>
            <th className={classes.columnBtns}></th>
          </tr>
        </thead>

        <tbody>
          {participantList &&
            participantList.map((participantTemp, i) => (
              <tr key={i}>
                <td key={i}>{i + 1}</td>
                <td>{participantTemp.name}</td>
                <td>{participantTemp.levelOfCommand.value}</td>
                <td>{participantTemp.organization.name}</td>
                <td>
                  <div className={classes.columnBtnsContainer}>
                    <button
                      className={classes.actionBtn}
                      onClick={() => {
                        setShowModalAddEdit(true);
                        setParticipant({
                          name: "",
                          levelOfCommand: participantTemp.levelOfCommand,
                          organization: participantTemp.organization,
                          scenario_id: participantTemp.scenario_id,
                        });
                      }}
                      disabled={noEditAccess}
                    >
                      {"Clone"}
                    </button>
                    <button
                      className={classes.actionBtn}
                      onClick={() => {
                        setShowModalAddEdit(true);
                        setParticipant(participantTemp);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.editLabel")}
                    </button>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                      onClick={() => {
                        setShowModalAddEdit(true);
                        setOnDelete(true);
                        setParticipant(participantTemp);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.deleteLabel")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className={classes.actionBtnPanel}>
        <button
          className={classes.actionBtn}
          disabled={noEditAccess}
          onClick={() => setShowModalAddEdit(true)}
        >
          {t("participant.addNewParticipant")}
        </button>
      </div>
      {!isInTimeline && (
        <div className={classes.actionBtns}>
          <button
            className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
            disabled={false}
            onClick={previousStep}
          >
            {t("global.previousStepLabel")}
          </button>
          <button
            className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
            disabled={false}
            onClick={nextStep}
          >
            {t("global.nextStepLabel")}
          </button>{" "}
        </div>
      )}
    </div>
  );
};
AddEditParticipants.defaultProps = {
  isInTimeline: false,
};
AddEditParticipants.propTypes = {
  isInTimeline: PropTypes.bool,
};

const addEditParticipantsContainerStyles = createUseStyles({
  addEditparticipantContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    padding: "1rem",
    paddingTop: ".5rem",
    gridGap: ".5rem",
    width: "max-content",
  },
  breadcrumb: {
    padding: "0",
  },
  breadcrumbItem: {
    fontSize: variables.breadcrumb.fontSize,
    color: variables.colors.basicBlue,
  },
});
const AddEditParticipantsContainer = () => {
  const classes = addEditParticipantsContainerStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.addEditparticipantContainer}>
      <Breadcrumb className={classes.breadcrumb}>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/")}
        >
          Home Screen
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/scenario-library")}
        >
          Scenario Library
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-scenario-main-data")}
        >
          Add/Edit Scenario - Basic data
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-organizations")}
        >
          Add/Edit Organizations
        </Breadcrumb.Item>
        <Breadcrumb.Item className={classes.breadcrumbItem} active>
          Add/Edit Participants
        </Breadcrumb.Item>
      </Breadcrumb>
      <AddEditParticipants />
    </div>
  );
};

export default AddEditParticipantsContainer;
