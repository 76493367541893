const organizationFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "Organization",
      description: "",
      name: "organization",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Name",
            name: "name",
            placeholder: "Organization name",
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Country",
            name: "country",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Level of command",
            name: "levelOfCommand",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Acronym",
            name: "acronym",
            placeholder: "Organization acronym",
            required: true,
          },
        ],
      },
    },
  ],
};

const organizationFormFields = (levelOfCommandList, involvedCountries) => {
  organizationFormFieldsInitial.value[0].data.field[1].constraint.value =
    involvedCountries;
  organizationFormFieldsInitial.value[0].data.field[2].constraint.value =
    levelOfCommandList;
  return organizationFormFieldsInitial;
};
export default organizationFormFields;
