import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import evaluateIcon from "../../../assets/icons/evaluateIcon.svg";
import closeIconX from "../../../assets/icons/closeIconX.svg";
import { variables } from "../../../jssVariables";

import TabButton from "../../../components/TabButton";
import { KpisEval, LessonsLearned, Comments } from "./helpers";

const useStyles = createUseStyles({
  editScenarioInTimeline: {},
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .8)",
    zIndex: 1,
  },
  modalContainer: {
    marginTop: "3rem",
    background: "white",
    minWidth: "60rem",
    borderRadius: ".5rem",
  },
  tabs: {
    display: "grid",
    gridAutoFlow: "column",
  },
  content: {
    maxHeight: "30rem",
    overflowY: "scroll",
  },
  modal: {
    display: "grid",
    gridTemplateRows: " 1fr",
    top: "5rem",
    left: "1rem",
    background: "white",
    zIndex: 2,
    borderRadius: ".5rem",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  headerContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
    fontFamily: variables.fontHeaderFamily,
  },
  title: {
    paddingLeft: "1rem",
    paddingTop: ".5rem",
    fontFamily: variables.fontHeaderFamily,
  },
  icon: {
    width: "1.6rem",
    cursor: "pointer",
  },
  iconClose: {
    width: "2.5rem",
    cursor: "pointer",
    margin: ".5rem",
  },
});
const EvaluateScenarioSession = () => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(-1);

  return (
    <div className={classes.editScenarioInTimeline}>
      <img
        src={evaluateIcon}
        alt=""
        className={classes.icon}
        onClick={() => setShowModal(0)}
      />
      {showModal >= 0 && (
        <div className={classes.modalOverlay}>
          <div className={classes.modalContainer}>
            <div className={classes.modal}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>
                  <h4>Scenario Session Evaluation</h4>
                  <img
                    src={closeIconX}
                    alt=""
                    className={`${classes.icon} ${classes.iconClose}`}
                    onClick={() => setShowModal(-1)}
                  />
                </div>
                <div className={classes.tabs}>
                  <TabButton
                    tabId={0}
                    label={"KPIs"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                  <TabButton
                    tabId={1}
                    label={"Lessons learned"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                  <TabButton
                    tabId={2}
                    label={"Comments"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                </div>
              </div>
              <div className={classes.content}>
                {showModal === 0 && "test0" && <KpisEval />}
                {showModal === 1 && "test1" && <LessonsLearned />}
                {showModal === 2 && "test2" && <Comments />}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
EvaluateScenarioSession.defaultProps = {};
EvaluateScenarioSession.propTypes = {};

export default EvaluateScenarioSession;
