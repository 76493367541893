import React from "react";
import exusLogo from "./../assets/images/EXUS_logo_white76x31.png";
import exusLogoSmall from "./../assets/images/EXUS_logo_white20x31_small.png";
import PropTypes from "prop-types";

import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  exusBrand: (props) => ({
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    justifyItems: props.isExpanded ? "start" : "center",
    paddingBottom: "1rem",
  }),
  createdYear: {
    paddingLeft: ".3rem",
  },
});
const ExusBrand = (props) => {
  const { isExpanded } = props;
  const classes = useStyles({ isExpanded });

  return (
    <div className={classes.exusBrand}>
      <img
        src={isExpanded ? exusLogo : exusLogoSmall}
        alt="EXUS"
        className={classes.exusLogo}
      />
      {isExpanded && <span className={classes.createdYear}>&copy; 2022</span>}
    </div>
  );
};
ExusBrand.defaultProps = {
  isExpanded: true,
};
ExusBrand.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
};
export default ExusBrand;
