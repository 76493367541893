import { dataSourceFormFieldsInitial } from "../../utils/constants/forms/data-source-form-fields";

export function getElementsListWithTimestamps(scenario) {
  let elementsTimes = {};

  // eslint-disable-next-line max-statements
  scenario?.script.forEach((element, i) => {
    if (element.type === "EVENT") {
      if (!elementsTimes[element.timeOccurred.substr(0, 16)]) {
        elementsTimes = {
          ...elementsTimes,
          [element.timeOccurred.substr(0, 16)]: [],
        };
      }
      elementsTimes[element.timeOccurred.substr(0, 16)].push({
        scenarioId: scenario.id,
        type: element.type,
        sender: element.sender,
        receivers: element.receivers,
        message: element.message,
        indexInScript: i,
        elementId: element.id,
        time: element.timeOccurred,
        active: true,
      });
      if (!elementsTimes[element.timeEnded.substr(0, 16)]) {
        elementsTimes = {
          ...elementsTimes,
          [element.timeEnded.substr(0, 16)]: [],
        };
      }
      elementsTimes[element.timeEnded.substr(0, 16)].push({
        scenarioId: scenario.id,
        type: element.type,
        sender: element.sender,
        receivers: element.receivers,
        message: element.message,
        indexInScript: i,
        elementId: element.id,
        time: element.timeEnded,
        active: false,
      });
    } else if (element.type === "MESSAGE") {
      if (!elementsTimes[element.timeOccurred.substr(0, 16)]) {
        elementsTimes = {
          ...elementsTimes,
          [element.timeOccurred.substr(0, 16)]: [],
        };
      }
      elementsTimes[element.timeOccurred.substr(0, 16)].push({
        scenarioId: scenario.id,
        type: element.type,
        elementId: element.id,
        sender: element.sender,
        message: element.message,
        receivers: element.receivers,
        indexInScript: i,
        time: element.timeOccurred,
        active: false,
      });
    } else if (element.type === "SOCIAL_MEDIA") {
      if (!elementsTimes[element.timeOccurred.substr(0, 16)]) {
        elementsTimes = {
          ...elementsTimes,
          [element.timeOccurred.substr(0, 16)]: [],
        };
      }
      elementsTimes[element.timeOccurred.substr(0, 16)].push({
        scenarioId: scenario.id,
        type: element.type,
        elementId: element.id,
        sender: element.sender,
        message: element.message,
        socialMediaType: element.socialMediaType,
        receivers: [],
        indexInScript: i,
        time: element.timeOccurred,
        active: false,
      });
    } else if (element.type === "SOCIAL_MEDIA_BATCH") {
      if (!elementsTimes[element.timeOccurred.substr(0, 16)]) {
        elementsTimes = {
          ...elementsTimes,
          [element.timeOccurred.substr(0, 16)]: [],
        };
      }
      elementsTimes[element.timeOccurred.substr(0, 16)].push({
        scenarioId: scenario.id,
        type: element.type,
        elementId: element.id,
        socialMediaType: element.socialMediaType,
        indexInScript: i,
        time: element.timeOccurred,
        active: false,
      });
    } else if (element.type === "DATA_SOURCE") {
      if (!elementsTimes[element.timeOccurred.substr(0, 16)]) {
        elementsTimes = {
          ...elementsTimes,
          [element.timeOccurred.substr(0, 16)]: [],
        };
      }

      const dataSourceIdx = dataSourceFormFieldsInitial.value.findIndex(
        (d) => d.name === element.elementName
      );

      elementsTimes[element.timeOccurred.substr(0, 16)].push({
        dataSource:
          dataSourceIdx >= 0
            ? dataSourceFormFieldsInitial.value[dataSourceIdx].label
            : "",
        scenarioId: scenario.id,
        type: element.type,
        elementId: element.id,
        data: element.data,
        indexInScript: i,
        time: element.timeOccurred,
        active: false,
      });
    }
  });

  return elementsTimes;
}
