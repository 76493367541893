/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import properties from "./../../utils/properties";
import { createUseStyles } from "react-jss";
import { myButton } from "../../jssVariables";
import markerDefault from "../../assets/icons/markerDefault.svg";
import { getCoordsFromAddress } from "./helpers";

const useStyles = createUseStyles({
  mapContainer: () => ({
    height: "max-content",
  }),
  map: (props) => ({
    position: "relative",
    height: props.height,
    width: "auto",
    borderRadius: ".5rem ",
  }),
  mapOverlay: (props) => ({
    display: props.disabled ? "block" : "none",
    position: "absolute",
    background: "rgba(0,0,0, .3)",
    width: "100%",
    height: "100%",
    zIndex: 1,
  }),
  mapControlpanel: {
    position: "absolute",
    bottom: ".5rem",
    left: ".5rem",
  },
  expandedBtn: {
    ...myButton,
    fontSize: "small",
  },
  addressInputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "1rem",
    marginBottom: "1rem",
    alignItems: "center",
  },
  addressInputField: {
    borderRadius: ".2rem",
    border: "1px solid #ced4da",
    padding: " 0.25rem 0.5rem",
    // height: "2.4rem",
    width: "100%",
    "&:disabled": {
      background: "#e9ecef",
      cursor: "no-drop",
    },
  },
  addressInputBtn: {
    ...myButton,
    height: "max-content",
    "&:disabled": {
      background: "#e9ecef",
      cursor: "no-drop",
    },
  },
  markerDefault: {
    height: "2rem",
  },
});
const Map = (props) => {
  const { location, height, updateState, disabled } = props;

  const classes = useStyles({ height, disabled });
  const [textValue, setTextValue] = useState("");

  const [mapProps, setMapProps] = useState({
    mapApiLoaded: false,
    mapInstance: null,
    mapApi: null,
    geoCoder: null,
    places: [],
    center: [location[0], location[1]],
    zoom: 12,
    address: "",
    draggable: true,
    lat: location[0],
    lng: location[1],
  });

  function onChange({ center, zoom }) {
    setMapProps({
      ...mapProps,
      center,
      zoom,
    });
  }

  function onMarkerInteraction(childKey, childProps, mouse) {
    setMapProps({
      ...mapProps,
      draggable: false,
      lat: mouse.lat,
      lng: mouse.lng,
    });
  }

  function onMarkerInteractionMouseUp(childKey, childProps, mouse) {
    setMapProps({
      ...mapProps,
      draggable: true,
      center: { lat: mouse.lat, lng: mouse.lng },
    });
    updateState([mouse.lat, mouse.lng]);
  }

  function onClick(value) {
    setMapProps({
      ...mapProps,
      lat: value.lat,
      lng: value.lng,
    });
  }

  function apiHasLoaded(map, maps) {
    setMapProps({
      ...mapProps,
      mapApiLoaded: true,
      mapInstance: map,
      mapApi: maps,
    });
  }

  return (
    <div className={classes.mapContainer}>
      <div className={classes.addressInputContainer}>
        <input
          className={classes.addressInputField}
          type="text"
          name="addressInput"
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
          disabled={disabled}
        />
        <button
          className={classes.addressInputBtn}
          type="button"
          onClick={() =>
            getCoordsFromAddress({
              textValue,
              updateState,
              mapProps,
              setMapProps,
            })
          }
          disabled={disabled}
        >
          Find
        </button>
      </div>
      <div className={classes.map}>
        <div className={classes.mapOverlay}> </div>

        <GoogleMapReact
          height="13rem"
          center={mapProps.center}
          zoom={mapProps.zoom}
          draggable={mapProps.draggable}
          onChange={onChange}
          onChildMouseDown={onMarkerInteraction}
          onChildMouseUp={onMarkerInteractionMouseUp}
          onChildMouseMove={onMarkerInteraction}
          onChildClick={() => console.log("child click")}
          onClick={onClick}
          // key={properties.googleMapsApi.key}
          bootstrapURLKeys={{
            key: properties.googleMapsApi.key,
            libraries: ["places", "geometry"],
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
        >
          <div lat={mapProps.lat} lng={mapProps.lng}>
            <img src={markerDefault} alt="" className={classes.markerDefault} />
          </div>
          {/* <Marker text={mapProps.address} lat={mapProps.lat} lng={mapProps.lng} /> */}
        </GoogleMapReact>
      </div>
    </div>
  );
};

Map.propTypes = {
  updateState: PropTypes.func,
  location: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  height: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Map.defaultProps = {
  location: {},
  height: "20rem",
  isScenarioTimelineMap: false,
  disabled: false,
};

export default Map;
