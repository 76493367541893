import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import BootstrapForm from "react-bootstrap/Form";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import { addToast } from "../../utils/helpers/alerts";
import { createUseStyles } from "react-jss";
import { variables, myButton } from "../../jssVariables";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../utils/helpers/storageHelpers";
import { DateTime } from "luxon";
import { ChangeTimeModal } from "./helpers";
import { getElementsListWithTimestamps } from "../../pages/scenario/helpers";

const useStyles = createUseStyles({
  clock: {
    display: "grid",
    gridTemplateColumns: "repeat(4, max-content)",
    alignItems: "center",
    gridGap: ".5rem",
  },
  timeAndGmtText: {},
  actionBtn: {
    ...myButton,
  },
  actionBtnPlay: (props) => ({
    background: props.hasStarted ? "gray" : "green",
    color: variables.colors.basicLight,
  }),
  actionBtnStop: () => ({
    color: "white",
    background: "red",
    borderColor: "red",
    "&:disabled": {
      background: "rgba(255,0,0,.4)",
      borderColor: "rgba(255,0,0,.4)",

      cursor: "no-drop",
    },
  }),
  dateTimePickerContainer: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
  },
  clockModal: {
    marginTop: "2rem",
  },
});
const Clock = ({
  scenario,
  systemTime,
  editTime,
  setEditTime,
  minDate,
  setManualDatetime,
  hasEditTime,
  setIsExpandedRight,
  setElementTimes,
  isPaused,
  setIsPaused,
  setElementsSent,
}) => {
  const [hasStarted, setHasStarted] = useState(false);

  const classes = useStyles({ hasStarted });

  const timeString = systemTime.toTimeString().split("(");
  const timeAndGmtText = timeString[0].split(" ");

  function play() {
    setManualDatetime(hasEditTime ? DateTime.fromJSDate(editTime).valueOf() : minDate);
    setIsExpandedRight(true);
    saveToLocalStorage("scenario_is_running", true);
    addToast("success", `Scenario has started!`, 1000);
    setHasStarted(true);
  }

  function pause() {
    setEditTime();
    setIsPaused(true);
    const pausedTime = DateTime.fromISO(new Date(systemTime).toISOString());
    saveToLocalStorage("scenario_paused_time", pausedTime.valueOf());
    addToast("success", `Scenario has been paused!`, 1000);
  }

  function resume() {
    const pausedTime = getFromLocalStorage("scenario_paused_time");
    setIsPaused(false);
    setManualDatetime(hasEditTime ? DateTime.fromJSDate(editTime).valueOf() : pausedTime);
    addToast("success", `Scenario has been resumed!`, 1000);
  }

  function playPause() {
    if (!hasStarted) {
      play();
    } else if (hasStarted && !isPaused) {
      pause();
    } else if (hasStarted && isPaused) {
      resume();
    }
  }

  function stop() {
    setManualDatetime();
    setEditTime();
    setIsExpandedRight(false);
    saveToLocalStorage("scenario_is_running", false);
    saveToLocalStorage("scenario_paused_time", 0);
    addToast("success", `Scenario has been stopped!`, 1000);
    setHasStarted(false);
    setIsPaused(false);
    setElementsSent([]);
    setElementTimes(getElementsListWithTimestamps(scenario));
  }
  return systemTime ? (
    <div className={classes.clock}>
      <button
        className={`${classes.actionBtn} ${classes.actionBtnPlay}`}
        onClick={() => {
          playPause();
        }}
      >
        {!hasStarted ? "START" : isPaused ? "RESUME" : "PAUSE"}
      </button>
      <button
        className={`${classes.actionBtn} ${classes.actionBtnStop}`}
        disabled={!hasStarted}
        onClick={() => stop()}
      >
        {"STOP"}
      </button>
      <span className={classes.timeAndGmtText}>{timeAndGmtText[0]}</span>
      <ChangeTimeModal
        systemTime={systemTime}
        setEditTime={setEditTime}
        // setIsExpandedRight={setIsExpandedRight}
        // setTimeInExecution={setTimeInExecution}
        hasStarted={hasStarted}
        isPaused={isPaused}
      />{" "}
    </div>
  ) : null;
};
Clock.propTypes = {
  scenario: PropTypes.object.isRequired,
  minDate: PropTypes.number.isRequired,
  systemTime: PropTypes.object.isRequired,
  editTime: PropTypes.object.isRequired,
  setEditTime: PropTypes.func.isRequired,
  setManualDatetime: PropTypes.func.isRequired,
  hasEditTime: PropTypes.bool.isRequired,
  setIsExpandedRight: PropTypes.func.isRequired,
  setElementTimes: PropTypes.func.isRequired,
  isPaused: PropTypes.bool.isRequired,
  setIsPaused: PropTypes.func.isRequired,
  setElementsSent: PropTypes.func.isRequired,
};

export default Clock;
