import { createSlice } from "@reduxjs/toolkit";
import getEmptyElement from "./../utils/constants/data/element-empty";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

const initialState = getFromLocalStorage("element", "object")
  ? getFromLocalStorage("element", "object")
  : getEmptyElement("", 0);

export const elementSlice = createSlice({
  name: "element",
  initialState,
  reducers: {
    setElement: (state, action) => {
      saveToLocalStorage("element", action.payload);

      return action.payload;
    },
    setElementDataDource: (state, action) => {
      saveToLocalStorage("element", action.payload);

      return action.payload;
    },

    clearElement: () => {
      saveToLocalStorage("element", getEmptyElement("", 0));
      return getEmptyElement("", 0);
    },

    setOrUpdateElementField: (state, action) => {
      return {
        ...state.element,
        [action.payload.fieldName]: action.payload.value,
      };
    },
    // saveElementToDb: (state, action) => {
    //   state.element[action.payload.fieldName] = action.payload.value;
    // },
  },
});

// each case under reducers becomes an action
export const { setElement, clearElement, setOrUpdateElementField } =
  elementSlice.actions;
export default elementSlice.reducer;
