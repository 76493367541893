/* eslint-disable max-statements */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import CreateFormField from "../../utils/helpers/create-form-field";
import scenarioBasicDataFormFields from "../../utils/constants/forms/scenario-basic-data-form-fields";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { saveScenarioThunk } from "../../redux/scenarioSlice";
import { createUseStyles } from "react-jss";
import { loadOrganizationListThunk } from "../../redux/organizationSlice";
import { variables, myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import { getScenarioList } from "../../services";
import { useState } from "react";
import { addToast } from "../../utils/helpers/alerts";

const addEditScenarioMainDataStyles = createUseStyles({
  addEditScenarioMain: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: "1rem",
    paddingTop: ".5rem",
    gridGap: ".5rem",
    width: "max-content",
  },
  breadcrumb: {
    padding: "0",
  },
  breadcrumbItem: {
    fontSize: variables.breadcrumb.fontSize,
    color: variables.colors.basicBlue,
  },
  formContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: ".5rem 1rem",
    gridGap: ".5rem",
    background: variables.colors.basicLight,
    borderRadius: "0.5rem",
  },
  title: {
    fontSize: "medium",
    fontFamily: variables.fontHeaderFamily,
  },
  form: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: "2rem",
    fontSize: "small",
  },
  formFieldsPanel: {
    display: "grid",
    gridAutoFlow: "row",
    gridGap: ".5rem",
    width: "25rem",
  },
  mapPanel: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    alignContent: "space-between",
    height: "100%",
    width: "30rem",
  },
  submitBtnContainer: (props) => ({
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: props.isInTimeline ? "end" : "space-between",
  }),
  submitBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
});
export const AddEditScenarioMainData = (props) => {
  const [scenarioTitles, setScenarioTitles] = useState([]);
  const { t } = useTranslation();

  const { isInTimeline } = props;

  const classes = addEditScenarioMainDataStyles({ isInTimeline });

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const mapHeight = "15rem";

  const user = useSelector((state) => state.authentication.user);

  const scenario = useSelector((state) => {
    return {
      ...state.scenario,
      involvedCountries: state.scenario.involvedCountries.map((c) => {
        return { label: c, value: c };
      }),
    };
  });

  const noEditAccess = ["READ_ACCESS"].includes(scenario.accessType);

  const onSubmit = (values) => {
    if (noEditAccess) {
      if (!isInTimeline) {
        dispatch(loadOrganizationListThunk(scenario.id));
        navigate("/add-edit-organizations");
      }
    } else {
      if (scenarioTitles.includes(values.title)) {
        addToast(
          "error",
          `Error message: Scenario with title '${values.title}' already exists`,
          false
        );
      } else {
        dispatch(
          saveScenarioThunk({
            ...scenario,
            title: values.title,
            scenarioGoal: values.scenarioGoal,
            location: {
              type: "Point",
              coordinates: values.coordinates,
            },
            locationAddress: values.locationAddress,
            involvedCountries: values.involvedCountries.map((c) => c.value),
            author: scenario.author ? scenario.author : user.email,
            trial: values.trial.toUpperCase(),
            comments: values.comments,
          })
        ).then((result) => {
          if (!isInTimeline) {
            dispatch(loadOrganizationListThunk(result.payload.id));
            navigate("/add-edit-organizations");
          }
        });
      }
    }
  };

  function previousStep() {
    navigate("/scenario-library");
  }
  function getScenarioTitles() {
    getScenarioList().then((response) => {
      if (response.length) {
        setScenarioTitles(
          response
            .filter((s) => s.title !== scenario.title)
            .map((sc) => sc.title)
        );
      }
    });
  }

  useEffect(() => {
    getScenarioTitles();
  }, []);

  return (
    <div className={classes.mainPanel}>
      <Formik
        initialValues={{
          title: scenario.title,
          scenarioGoal: scenario.scenarioGoal,
          locationCoordinates: scenario.location.coordinates,
          coordinates: [
            scenario.location.coordinates[0],
            scenario.location.coordinates[1],
          ],
          locationAddress: scenario.locationAddress,
          involvedCountries: scenario.involvedCountries,
          trial: scenario.trial,
          comments: scenario.comments,
          locationPointOnMap: scenario.location.coordinates,
        }}
        validationSchema={createValidationSchema({
          formFieldsSchema: scenarioBasicDataFormFields,
        })}
        onSubmit={(values) => {
          onSubmit(values);
        }}
      >
        <Form>
          <div className={classes.formContainer}>
            {!isInTimeline && (
              <span className={classes.title}>
                {t("global.addScenarioBasicData")}
              </span>
            )}

            <div className={classes.form}>
              <div className={classes.formFieldsPanel}>
                {scenarioBasicDataFormFields &&
                  scenarioBasicDataFormFields.value[0].data.field.map(
                    (field, idx) => (
                      <CreateFormField
                        key={idx}
                        field={{
                          ...field,
                          disabled: field.disabled
                            ? field.disabled
                            : noEditAccess,
                        }}
                        index={idx}
                      />
                    )
                  )}
              </div>

              <div className={classes.mapPanel}>
                <CreateFormField
                  field={{
                    type: "Coordinates",
                    label: "Select the main scenario location on map",
                    name: "coordinates",
                    mapHeight,
                    disabled: noEditAccess,
                  }}
                />
                <span className={classes.submitBtnContainer}>
                  {!isInTimeline && (
                    <button
                      className={classes.submitBtn}
                      disabled={false}
                      onClick={previousStep}
                    >
                      {"Back"}
                    </button>
                  )}
                  <button
                    type="submit"
                    className={classes.submitBtn}
                    disabled={isInTimeline && noEditAccess}
                  >
                    {isInTimeline
                      ? t("save")
                      : [
                          noEditAccess
                            ? t("global.nextStepLabel")
                            : t("global.saveAndGoToNextStep"),
                        ]}
                  </button>
                </span>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
AddEditScenarioMainData.defaultProps = {
  isInTimeline: false,
};
AddEditScenarioMainData.propTypes = {
  isInTimeline: PropTypes.bool,
};

const useStyles = createUseStyles({
  addEditScenarioMain: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: "1rem",
    paddingTop: ".5rem",
    gridGap: ".5rem",
    width: "max-content",
  },
  breadcrumb: {
    padding: "0",
  },
  breadcrumbItem: {
    fontSize: variables.breadcrumb.fontSize,
    color: variables.colors.basicBlue,
  },
});
const AddEditScenarioMainDataContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.addEditScenarioMain}>
      <Breadcrumb className={classes.breadcrumb}>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/")}
        >
          {t("breadcrumb.homeScreen")}
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/scenario-library")}
        >
          {t("breadcrumb.scenarioLibrary")}
        </Breadcrumb.Item>
        <Breadcrumb.Item className={classes.breadcrumbItem} active>
          {t("breadcrumb.addScenarioBasicData")}
        </Breadcrumb.Item>
      </Breadcrumb>

      <AddEditScenarioMainData />
    </div>
  );
};

export default AddEditScenarioMainDataContainer;
