/* eslint-disable max-statements */
/* eslint-disable complexity */
import * as Yup from "yup";

function addValidations(field) {
  switch (field.type) {
    case "Category":
      if (field.multiSelect) {
        if (
          field.constraint &&
          field.constraint.minValue &&
          field.constraint.maxValue &&
          field.required
        ) {
          return Yup.array()
            .max(
              field.constraint.maxValue,
              `Please select at least ${field.constraint.maxValue}`
            )
            .min(
              field.constraint.minValue,
              `Please select at least ${field.constraint.minValue}`
            )
            .required("Required");
        } else if (
          field.constraint &&
          field.constraint.minValue &&
          field.required
        ) {
          return Yup.array()
            .min(
              field.constraint.minValue,
              `Please select at least ${field.constraint.minValue}`
            )
            .required("Required");
        } else if (
          field.constraint &&
          field.constraint.maxValue &&
          field.required
        ) {
          return Yup.array()
            .max(
              field.constraint.maxValue,
              `Please select at least ${field.constraint.maxValue}`
            )
            .required("Required");
        }
        return Yup.array();
      }
      if (field.constraint && field.constraint.minValue && field.required) {
        return Yup.array()
          .min(field.constraint.minValue, `Please select at least ${field.constraint.minValue}`)
          .required("Required");
      }
      if (field.required) {
        return Yup.object().required("Required");
      }
      return Yup.array();

    case "Quantity":
      if (
        field.constraint &&
        field.constraint.minValue &&
        field.constraint.maxValue &&
        field.required
      ) {
        return Yup.number()
          .max(
            field.constraint.maxValue,
            `Must be at most ${field.constraint.maxValue}`
          )
          .min(
            field.constraint.minValue,
            `Must be at least ${field.constraint.minValue}`
          )
          .required("Required");
      } else if (
        field.constraint &&
        (typeof field.constraint.minValue !== undefined) &&
        field.required
      ) {
        return Yup.number()
          .min(
            field.constraint.minValue,
            `Must be at least ${field.constraint.minValue}`
          )
          .required("Required");
      } else if (
        field.constraint &&
        field.constraint.maxValue &&
        field.required
      ) {
        return Yup.number()
          .max(
            field.constraint.maxValue,
            `Must be at most  ${field.constraint.maxValue}`
          )
          .required("Required");
      }
      if (
        field.constraint &&
        field.constraint.minValue &&
        field.constraint.maxValue
      ) {
        return Yup.number()
          .max(
            field.constraint.maxValue,
            `Must be at most ${field.constraint.maxValue}`
          )
          .min(
            field.constraint.minValue,
            `Must be at most ${field.constraint.maxValue}`
          );
      } else if (field.constraint && field.constraint.minValue) {
        return Yup.number().min(
          field.constraint.minValue,
          `Must be at most ${field.constraint.maxValue}`
        );
      } else if (field.constraint && field.constraint.maxValue) {
        return Yup.number().max(
          field.constraint.maxValue,
          `Must be at most ${field.constraint.maxValue}`
        );
      }
      break;
    case "Time":
      return field.required ? Yup.date().required("Required") : Yup.date();

    case "Text":
      if (field.name === "trial") {
        return Yup.string()
          .matches(/^[a-zA-Z-]+$/, "Only letters and dashes are allowed")
          .required("Required");
      }
      return field.required ? Yup.string().required("Required") : Yup.string();

    case "Textarea":
      return field.required ? Yup.string().required("Required") : Yup.string();

    case "Password":
      return field.required ? Yup.string().required("Required") : Yup.string();

    case "File":
      return field.required ? Yup.object().required("Required") : Yup.object();

    default:
      return null;
  }
  return null;
}

export function createValidationSchema({ formFieldsSchema, index }) {
  const idx = index && index >= 0 ? index : 0;
  let validationSchema = {}; //initialValidationSchema ? initialValidationSchema : {};

  Array.from(formFieldsSchema.value[idx].data.field).forEach((field) => {
    validationSchema = {
      ...validationSchema,
      [field.name]: addValidations(field),
    };
  });
  return Yup.object(validationSchema);
}
