import React, { useState } from "react";
import PropTypes from "prop-types";
import properties from "../../utils/properties";
import { useDispatch } from "react-redux";
import { addToast } from "../../utils/helpers/alerts";
import { clearElement } from "../../redux/elementSlice";
import { createUseStyles } from "react-jss";
import { myButton } from "../../jssVariables";
import ElementStatic from "../AddElement/ElementStatic";
import ElementDynamic from "../AddElement/ElementDynamic";

const addElementStyles = createUseStyles({
  addElementContainer: (props) => ({
    display: "grid",
    width: "100%",
    gridTemplateColumns: props.expanded
      ? "max-content max-content"
      : "max-content",
    alignItems: "center",
    gridGap: "1rem",
    borderRadius: "2rem",
    justifyContent: "end",
  }),
  addElementBtns: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: ".6rem",
  },
  addElementBtn: {
    display: "grid",
    alignContent: "center",
    width: "2rem",
    height: "2rem",
    background: "#0d6efd",
    borderRadius: "50%",
    border: "none",
    justifyContent: "start",

    cursor: "pointer",
  },
  addElementBtnTxt: (props) => ({
    margin: "auto",
    paddingLeft: props.expanded ? ".7rem" : ".4rem",
    paddingBottom: props.expanded ? ".4rem" : 0,
    fontSize: "xx-large",
    color: "white",
    fontWeight: 600,
  }),
  btn: {
    ...myButton,
  },
  btnDisabled: {
    color: "lightgray",
    borderColor: "lightgray",
  },
});
const ScenarioAddElement = (props) => {
  const {
    showElementModal,
    setShowElementModal,
    eventCategoryList,
    noEditAccess,
  } = props;
  const mapHeight = "13rem";

  const [expanded, setExpanded] = useState(true);
  const classes = addElementStyles({ expanded });

  const dispatch = useDispatch();

  const handleShowModal = (input) => {
    if (eventCategoryList) {
      setShowElementModal(input);
    } else {
      addToast("info", `Please add a new Incident first!`, 3000);
    }
  };

  const handleHideModal = () => {
    setShowElementModal("");
    dispatch(clearElement());
  };

  const deleteElement = false;

  return (
    <div className={classes.addElementContainer}>
      {expanded && (
        <div className={classes.addElementBtns}>
          <button
            className={classes.btn}
            onClick={() => handleShowModal("EVENT")}
            // disabled={!hasEventCategories}
          >
            {properties.elementTypes.EVENT}
          </button>{" "}
          <button
            className={classes.btn}
            onClick={function () {
              handleShowModal("MESSAGE");
            }}
          >
            {properties.elementTypes.MESSAGE}
          </button>{" "}
          <button
            className={classes.btn}
            onClick={function () {
              handleShowModal("SOCIAL_MEDIA");
            }}
          >
            {properties.elementTypes.SOCIAL_MEDIA}
          </button>{" "}
          {/*deactivated for co-protect*/}
          {/*<button*/}
          {/*  className={classes.btn}*/}
          {/*  onClick={function () {*/}
          {/*    handleShowModal("SOCIAL_MEDIA_BATCH");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {properties.elementTypes.SOCIAL_MEDIA_BATCH}*/}
          {/*</button>{" "}*/}
          {/*<button*/}
          {/*  className={`${classes.btn} ${classes.btnDisabled1}`}*/}
          {/*  onClick={function () {*/}
          {/*    handleShowModal("DATA_SOURCE");*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {properties.elementTypes.DATA_SOURCE}*/}
          {/*</button>{" "}*/}
          {/*<button className={`${classes.btn} ${classes.btnDisabled}`} disabled>*/}
          {/*  {properties.elementTypes.MEDICAL_ASSET}*/}
          {/*</button>{" "}*/}
        </div>
      )}
      <div
        className={classes.addElementBtn}
        onClick={() => setExpanded(!expanded)}
      >
        <span className={classes.addElementBtnTxt}>
          {!expanded ? "+" : "-"}
        </span>
      </div>
      {showElementModal === "EVENT" && (
        <ElementStatic
          elementType="EVENT"
          deleteElement={deleteElement}
          mapHeight={mapHeight}
          show={showElementModal === "EVENT"}
          handleClose={handleHideModal}
          eventCategoryList={eventCategoryList}
          noEditAccess={noEditAccess}
        />
      )}
      {showElementModal === "MESSAGE" && (
        <ElementStatic
          elementType="MESSAGE"
          deleteElement={deleteElement}
          mapHeight={mapHeight}
          show={showElementModal === "MESSAGE"}
          handleClose={handleHideModal}
          eventCategoryList={eventCategoryList}
          noEditAccess={noEditAccess}
        />
      )}
      {showElementModal === "SOCIAL_MEDIA" && (
        <ElementStatic
          elementType="SOCIAL_MEDIA"
          deleteElement={deleteElement}
          mapHeight={mapHeight}
          show={showElementModal === "SOCIAL_MEDIA"}
          handleClose={handleHideModal}
          eventCategoryList={eventCategoryList}
          noEditAccess={noEditAccess}
        />
      )}
      {showElementModal === "SOCIAL_MEDIA_BATCH" && (
        <ElementStatic
          elementType="SOCIAL_MEDIA_BATCH"
          deleteElement={deleteElement}
          mapHeight={mapHeight}
          show={showElementModal === "SOCIAL_MEDIA_BATCH"}
          handleClose={handleHideModal}
          eventCategoryList={eventCategoryList}
          noEditAccess={noEditAccess}
        />
      )}
      {showElementModal === "DATA_SOURCE" && (
        <ElementDynamic
          elementType="DATA_SOURCE"
          deleteElement={deleteElement}
          mapHeight={mapHeight}
          show={showElementModal === "DATA_SOURCE"}
          handleClose={handleHideModal}
          eventCategoryList={eventCategoryList}
          noEditAccess={noEditAccess}
        />
      )}
    </div>
  );
};
ScenarioAddElement.defaultProps = {
  showElementModal: "",
  eventCategoryList: [],
  noEditAccess: false,
};
ScenarioAddElement.propTypes = {
  showElementModal: PropTypes.string.isRequired,
  setShowElementModal: PropTypes.func.isRequired,
  eventCategoryList: PropTypes.array.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
};
export default ScenarioAddElement;
