import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUsersAndOrganizations } from "../services";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

export const getUsersAndOrganizationsThunk = createAsyncThunk(
  "usersAndOrgs/getUsersAndOrgs",
  async (thunkAPI, { dispatch }) => {
    const response = await getUsersAndOrganizations(thunkAPI);
    dispatch(setUsersAndOrgs({ ...response }));
    return response;
  }
);

const initialState = getFromLocalStorage("usersAndOrgs", "object")
  ? getFromLocalStorage("usersAndOrgs", "object")
  : [];

export const usersAndOrgsSlice = createSlice({
  name: "usersAndOrgs",
  initialState,
  reducers: {
    setUsersAndOrgs: (state, action) => {
      saveToLocalStorage("usersAndOrgs", action.payload);
      return action.payload;
    },

    clearUsersAndOrgs: () => {
      saveToLocalStorage("usersAndOrgs", []);
      return [];
    },
  },
});

// each case under reducers becomes an action
export const { setUsersAndOrgs, clearUsersAndOrgs } = usersAndOrgsSlice.actions;
export default usersAndOrgsSlice.reducer;
