import React from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";
import Select from "react-select";
import { useField } from "formik";
import { createUseStyles } from "react-jss";
import { LabelRequiredAndErrorMessage } from "./helpers";

const useStyles = createUseStyles({
  label: {
    fontSize: "x-small",
    marginBottom: "0",
  },
  formField: {
    fontSize: "x-small",
  },
  errorMessage: {
    display: "grid",
    justifyItems: "end",
    color: "red",
    fontSize: "x-small",
  },
  requiredLabel: {
    display: "grid",
    justifyItems: "end",
    fontSize: "x-small",
  },
});

const CategoryTypeFieldFormik = (props) => {
  const [field, meta, helpers] = useField(props);

  const {
    availableOptionsList,
    isMultiSelect,
    fieldLabel,
    required,
    disabled,
  } = props;

  const classes = useStyles();
  const { setValue } = helpers;

  const optionList = availableOptionsList.map((option, i) => {
    return {
      value: option.name,
      label: option.label,
      index: i,
      id: option.id ? option.id : "",
    };
  });

  const updateState = (input) => {
    if (isMultiSelect) {
      setValue(handleChangeOptions(input));
    } else {
      setValue([input]);
    }
  };

  const handleChangeOptions = (e) => {
    return Array.isArray(e) ? e.map((x) => x) : [];
  };

  return (
    <React.Fragment>
      <BootstrapForm.Group controlId={`formBasic${fieldLabel}`}>
        <BootstrapForm.Label className={classes.label}>
          {fieldLabel}
        </BootstrapForm.Label>
        <Select
          className={classes.formField}
          placeholder={fieldLabel}
          closeMenuOnSelect={!isMultiSelect}
          isSearchable={true}
          isDisabled={disabled}
          value={
            isMultiSelect
              ? optionList.filter((obj1) =>
                  field.value?.some((obj2) => obj1.value === obj2.value)
                )
              : field.value
          }
          options={optionList}
          onChange={(e) => updateState(e)}
          isMulti={isMultiSelect}
        />
        <LabelRequiredAndErrorMessage meta={meta} required={required} />
      </BootstrapForm.Group>
    </React.Fragment>
  );
};

CategoryTypeFieldFormik.propTypes = {
  availableOptionsList: PropTypes.array.isRequired,
  isMultiSelect: PropTypes.bool.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CategoryTypeFieldFormik.defaultProps = {
  availableOptionsList: [],
  isMultiSelect: false,
  disabled: false,
  fieldLabel: "",
  required: false,
};

export default CategoryTypeFieldFormik;
