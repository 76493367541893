import React from "react";
import PropTypes from "prop-types";
import properties from "./../../utils/properties";
import { createUseStyles } from "react-jss";
import { variables } from "../../jssVariables";
import closeIconX from "../../assets/icons/closeIconX.svg";
import Geocode from "react-geocode";
import eventIcon from "../../assets/elementTypes/event.svg";
import messageIcon from "../../assets/elementTypes/message.svg";
import twitterIcon from "../../assets/icons/twitter.svg";
import redditIcon from "../../assets/icons/reddit.svg";
import facebookIcon from "../../assets/icons/facebook.svg";
import instagramIcon from "../../assets/icons/instagram.svg";
import socialMedia from "../../assets/elementTypes/socialMedia.svg";

Geocode.setApiKey(properties.googleMapsApi.key);
Geocode.setLanguage("en");

export function getCoordsFromAddress({
  textValue,
  updateState,
  mapProps,
  setMapProps,
}) {
  if (textValue) {
    Geocode.fromAddress(textValue).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        updateState([lat, lng]);
        setMapProps({
          ...mapProps,
          lat,
          lng,
          center: { lat, lng },
          zoom: 16,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

const poPupStyles = createUseStyles({
  popUp: () => ({
    display: "grid",
    width: "max-content",
    background: "white",
    borderRadius: ".5rem",
    padding: ".5rem",
  }),
  header: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    justifyContent: "space-between",
    fontSize: "medium",
    alignItems: "center",
    fontWeight: "bold",
    fontFamily: variables.fontHeaderFamily,
  },
  divider: {
    width: "97%",
    background: variables.colors.basicBlue,
    height: "1px",
    margin: ".3rem 0",
  },
  closeIcon: {
    cursor: "pointer",
    width: "1.4rem",
  },
  content: {
    display: "grid",
    gridAutoFlow: "row",
    gridGap: ".3rem",
  },
  contentRow: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: ".3rem",
    alignItems: "center",
    maxWidth: "20rem",
    justifyContent: "start",
  },
  contentRowLabel: {
    fontWeight: "bold",
    width: "4rem",
  },
  contentRowItem: {
    background: "#e0e0f7",
    width: "max-content",
    padding: ".2rem",
    borderRadius: ".3rem",
  },
});
export const PoPup = (props) => {
  const { element, setShowPopUp } = props;
  const classes = poPupStyles();

  return (
    <div className={classes.popUp}>
      <div className={classes.header}>
        {properties.elementTypes[element.type]}
        <img
          src={closeIconX}
          alt=""
          className={classes.closeIcon}
          onClick={() => setShowPopUp(false)}
        />
      </div>
      <span className={classes.divider}></span>
      <div className={classes.content}>
        <span className={classes.contentRow}>
          <label className={classes.contentRowLabel}>Message:</label>{" "}
          {element.message}
        </span>
        <span className={classes.divider}></span>
        <span className={classes.contentRow}>
          <label className={classes.contentRowLabel}>Sender:</label>
          {element.sender.name}
        </span>
        <span className={classes.contentRow}>
          <label className={classes.contentRowLabel}>Receivers:</label>

          {element.receivers.map((r, idx) => (
            <span className={classes.contentRowItem} key={idx}>
              {`${r.name}`}
            </span>
          ))}
        </span>
        <span className={classes.contentRow}>
          <label className={classes.contentRowLabel}>Time occurred:</label>
          {new Date(element.timeOccurred).toLocaleString()}
        </span>
        {element.type === "EVENT" && (
          <span className={classes.contentRow}>
            <label className={classes.contentRowLabel}>Time ended:</label>
            {new Date(element.timeEnded).toLocaleString()}
          </span>
        )}
      </div>
    </div>
  );
};
PoPup.defaultProps = {
  element: {},
};
PoPup.propTypes = {
  element: PropTypes.object.isRequired,
  setShowPopUp: PropTypes.func.isRequired,
};

const legendStyles = createUseStyles({
  legendContainer: () => ({
    display: "grid",
    gridAutoFlow: "column",
    padding: ".5rem 1rem",
    marginLeft: "4rem",
    gridGap: "2rem",
    justifyContent: "start",
  }),
  item: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    textTransform: "uppercase",
    gridGap: ".3rem",
    cursor: "pointer",
  },
  icon: {
    width: "1.5rem",
  },
});
export const LegendContainer = (props) => {
  const { elementsToShowType, setElementsToShowType } = props;

  const classes = legendStyles();
  return (
    <div className={classes.legendContainer}>
      <span
        className={classes.item}
        onClick={() =>
          setElementsToShowType([
            "EVENT",
            "MESSAGE",
            "SOCIAL_MEDIA",
            "DATA_SOURCE",
          ])
        }
      >
        all
      </span>
      <span
        className={classes.item}
        onClick={() => {
          const index = elementsToShowType.indexOf("EVENT");
          return setElementsToShowType(
            index > -1
              ? [...elementsToShowType].splice(index, 1)
              : [...elementsToShowType, "EVENT"]
          );
        }}
      >
        <img src={eventIcon} alt="" className={classes.icon} />
        event
      </span>
      <span
        className={classes.item}
        onClick={() => {
          const index = elementsToShowType.indexOf("MESSAGE");
          return setElementsToShowType(
            index > -1
              ? [...elementsToShowType].splice(index, 1)
              : [...elementsToShowType, "MESSAGE"]
          );
        }}
      >
        <img src={messageIcon} alt="" className={classes.icon} />
        message
      </span>
      <span
        className={classes.item}
        onClick={() => {
          const index = elementsToShowType.indexOf("SOCIAL_MEDIA");
          return setElementsToShowType(
            index > -1
              ? [...elementsToShowType].splice(index, 1)
              : [...elementsToShowType, "SOCIAL_MEDIA"]
          );
        }}
      >
        <img src={socialMedia} alt="" className={classes.icon} />
        SOCIAL MEDIA
      </span>
      {/* <span
        className={classes.item}
        onClick={() => {
          const index = elementsToShowType.indexOf("SOCIAL_MEDIA");
          return setElementsToShowType(
            index > -1
              ? [...elementsToShowType].splice(index, 1)
              : [...elementsToShowType, "SOCIAL_MEDIA"]
          );
        }}
      >
        <img src={getSocialMediaIcon} alt="" className={classes.icon} />
        social media
      </span>
      <span
        className={classes.item}
        onClick={() => setElementsToShowType(["SOCIAL_MEDIA"])}
      >
        <img src={redditIcon} alt="" className={classes.icon} />
        reddit
      </span> */}
    </div>
  );
};
LegendContainer.propTypes = {
  setElementsToShowType: PropTypes.func.isRequired,
  elementsToShowType: PropTypes.array.isRequired,
};
LegendContainer.defaultProps = {
  elementsToShowType: [],
};

export function getElementIcon(element) {
  function getSocialMediaIcon(socialMediaType) {
    switch (socialMediaType) {
      case "Twitter":
        return twitterIcon;
      case "Reddit":
        return redditIcon;
      case "Facebook":
        return facebookIcon;
      case "Instagram":
        return instagramIcon;
      default:
        return null;
    }
  }
  switch (element.type) {
    case "EVENT":
      return eventIcon;
    case "MESSAGE":
      return messageIcon;
    case "SOCIAL_MEDIA":
      return getSocialMediaIcon(element.socialMediaType);
    case "DATA_SOURCE":
      return null;
    default:
      return null;
  }
}
