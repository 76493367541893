import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import editIcon from "../../assets/icons/editIcon.svg";
import closeIconX from "../../assets/icons/closeIconX.svg";
import { variables } from "../../jssVariables";
import { AddEditScenarioMainData } from "./AddEditScenarioMainData";
import { AddEditOrganizations } from "../../components/organization/index";
import { AddEditParticipants } from "../../components/participant/index";
import { ScenarioSettings } from "../scenario-settings/scenario-settings";
import TabButton from "../../components/TabButton";
import { KeyPerformanceIndicators } from "../../components/key-performance-indicators";

const useStyles = createUseStyles({
  editScenarioInTimeline: {},
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .8)",
    zIndex: 1,
  },
  modalContainer: {
    marginTop: "3rem",
    background: "white",
    width: "60rem",
    borderRadius: ".5rem",
  },
  tabs: {
    display: "grid",
    gridAutoFlow: "column",
  },
  modal: {
    display: "grid",
    gridTemplateRows: " 1fr",
    top: "5rem",
    left: "1rem",
    background: "white",
    zIndex: 2,
    borderRadius: ".5rem",
  },
  header: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "1rem",
  },
  headerContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
    fontFamily: variables.fontHeaderFamily,
  },
  title: {
    paddingLeft: "1rem",
    paddingTop: ".5rem",
    fontFamily: variables.fontHeaderFamily,
  },
  icon: {
    width: "1.5rem",
    cursor: "pointer",
  },
  iconClose: {
    width: "2.5rem",
    cursor: "pointer",
    margin: ".5rem",
  },
});
const EditScenarioInTimeline = () => {
  const classes = useStyles();
  const [showModal, setShowModal] = useState(-1);

  return (
    <div className={classes.editScenarioInTimeline}>
      <img
        src={editIcon}
        alt=""
        className={classes.icon}
        onClick={() => setShowModal(0)}
      />
      {showModal >= 0 && (
        <div className={classes.modalOverlay}>
          <div className={classes.modalContainer}>
            <div className={classes.modal}>
              <div className={classes.headerContainer}>
                <div className={classes.header}>
                  <h4>Edit Scenario </h4>
                  <img
                    src={closeIconX}
                    alt=""
                    className={`${classes.icon} ${classes.iconClose}`}
                    onClick={() => setShowModal(-1)}
                  />
                </div>
                <div className={classes.tabs}>
                  <TabButton
                    tabId={0}
                    label={"Main Data"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                  <TabButton
                    tabId={1}
                    label={"Organizations"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                  <TabButton
                    tabId={2}
                    label={"Participants"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                  <TabButton
                    tabId={3}
                    label={"Scenario KPIs"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                  <TabButton
                    tabId={4}
                    label={"Scenario Settings"}
                    tabNum={showModal}
                    setTabNum={setShowModal}
                  />
                </div>

                <span className={classes.title}>
                  Edit{" "}
                  {showModal === 0
                    ? "Scenario Data"
                    : [
                        showModal === 1
                          ? "Organizations"
                          : [
                              showModal === 2
                                ? "Participants"
                                : [
                                    showModal === 3
                                      ? "Scenario KPIs"
                                      : [
                                          showModal === 4
                                            ? "Scenario Settings"
                                            : null,
                                        ],
                                  ],
                            ],
                      ]}
                </span>
              </div>

              <div className={classes.content}>
                {showModal === 0 && (
                  <AddEditScenarioMainData
                    isInTimeline={true}
                    setShowModal={setShowModal}
                  />
                )}
                {showModal === 1 && (
                  <AddEditOrganizations
                    isInTimeline={true}
                    setShowModal={setShowModal}
                  />
                )}
                {showModal === 2 && (
                  <AddEditParticipants
                    isInTimeline={true}
                    setShowModal={setShowModal}
                  />
                )}
                {showModal === 3 && (
                  <KeyPerformanceIndicators
                    isInTimeline={true}
                    setShowModal={setShowModal}
                  />
                )}
                {showModal === 4 && (
                  <ScenarioSettings
                    isInTimeline={true}
                    setShowModal={setShowModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
EditScenarioInTimeline.defaultProps = {};
EditScenarioInTimeline.propTypes = {};

export default EditScenarioInTimeline;
