import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { Formik, Form, useFormikContext } from "formik";
import properties from "../../utils/properties";
import CategoryTypeFieldFormik from "../forms/fields/category-type-field-formik";
import CreateFormField from "../../utils/helpers/create-form-field";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import { useSelector, useDispatch } from "react-redux";
import dataSourceFormFields from "../../utils/constants/forms/data-source-form-fields";
import { addToast } from "../../utils/helpers/alerts";
import { saveScenarioThunk } from "../../redux/scenarioSlice";
import { createUseStyles } from "react-jss";
import { fillElementForDisplay } from "./helpers/fillElementForDisplay";
import { createInitialValues } from "./helpers/initialValues";
import { cleanElementForSave } from "./helpers/cleanElementForSave";
import { createFormFieldsSchema } from "./helpers/createFormFieldsSchema";
import _ from "lodash";
import { elementStyles } from "./helpers/elementStyles";
import { deleteElement } from "./helpers";

const FormDynamic = ({
  formFieldsSchema,
  mapHeight,
  eventCategoryList,
  dataSourceIndex,
  setDataSourceIndex,
  setFinalValues,
}) => {
  const { values } = useFormikContext();

  const [dataSource, setDataSource] = useState("");

  useEffect(() => {
    if (values.dataSource && values.dataSource.length > 0) {
      setDataSourceIndex(values.dataSource[0].index);
      setDataSource(values.dataSource[0].value);
    }

    setFinalValues({
      ...values,
      elementName: values?.dataSource ? Array.isArray(values?.dataSource) ? values.dataSource[0]?.value : values.dataSource.value : "",
    });
  }, [values]);

  const { participantList, scenarioLocation } = useSelector((state) => {
    return {
      scenarioLocation: _.get(state.scenario, "location", {}),
      participantList: _.get(state.participant, "participantList", []),
    };
  });

  return (
    <React.Fragment>
      <CategoryTypeFieldFormik
        availableOptionsList={dataSourceFormFields(eventCategoryList).value.map(
          (e, i) => {
            return { name: e.name, label: e.label, i };
          }
        )}
        fieldLabel="Data Source"
        isMultiSelect={false}
        value={dataSource}
        required={true}
        description=""
        name="dataSource"
      />

      {dataSourceIndex > -1 &&
        formFieldsSchema.value[dataSourceIndex].data.field.map((field, idx) => (
          <CreateFormField
            key={idx}
            field={
              field.type !== "Location"
                ? field
                : {
                    ...field,
                    mapHeight,
                    coordinates: scenarioLocation.coordinates,
                  }
            }
            index={idx}
            participantList={participantList}
            elementType="DATA_SOURCE"
          />
        ))}
    </React.Fragment>
  );
};
FormDynamic.propTypes = {
  formFieldsSchema: PropTypes.object.isRequired,
  mapHeight: PropTypes.string.isRequired,
  eventCategoryList: PropTypes.array.isRequired,
  dataSourceIndex: PropTypes.number.isRequired,
  setDataSourceIndex: PropTypes.func.isRequired,
  setFinalValues: PropTypes.func.isRequired,
};
FormDynamic.defaultProps = {
  dataSourceIndex: -1,
};

const useStyles = createUseStyles({ ...elementStyles });

const ElementDynamic = ({
  elementType,
  mapHeight,
  show,
  handleClose,
  eventCategoryList,
  noEditAccess,
}) => {
  const { element, scriptLength, participantList, scenarioLocation, scenario } =
    useSelector((state) => {
      return {
        element: fillElementForDisplay({
          element: {
            ...state.element,
            type: elementType,
          },
          eventCategoryList,
        }),
        scriptLength: _.get(state.scenario, "script.length", 0),
        scenarioLocation: _.get(state.scenario, "location", {}),
        participantList: _.get(state.participant, "participantList", []),
        scenario: _.get(state, "scenario", {}),
      };
    });

  const dispatch = useDispatch();

  const [dataSourceIndex, setDataSourceIndex] = useState(-1);

  const [finalValues, setFinalValues] = useState({ ...element });

  const [initialValues, setInitialValues] = useState({});
  const [deletePrompt, setDeletePrompt] = useState(false);

  useEffect(() => {
    if (dataSourceIndex >= 0) {
      setInitialValues(
        createInitialValues({
          element,
          scriptLength,
          coords: scenarioLocation.coordinates,
          dataSourceIndex,
        })
      );
    }
  }, [dataSourceIndex]);

  useEffect(() => {
    if (element && element.id) {
      const dataSourceIdx = dataSourceFormFields(
        eventCategoryList
      ).value.findIndex((d) => d.name === element.elementName);
      if (dataSourceIdx >= 0) {
        setDataSourceIndex(dataSourceIdx);
      }
    }
  }, []);

  const classes = useStyles({ elementHasId: Boolean(element.id) });

  const formFieldsSchema = createFormFieldsSchema({
    elementType,
    eventCategoryList,
    participantList,
    scriptLength,
  });

  const onSubmit = () => {
    dispatch(
      saveScenarioThunk(
        cleanElementForSave({
          scenario,
          values: finalValues,
          elementType,
          element,
        })
      )
    ).then(() => {
      addToast(
        "success",
        `A new ${elementType} has been added successfully!`,
        3000
      );
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        className={classes.modalAddElement}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {elementType
              ? `Add ${properties.elementTypes[elementType]}`
              : `Edit ${properties.elementTypes[element.type]}`}
          </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={createValidationSchema({
            formFieldsSchema,
            // index: selectedElementObjectIndex,
            elementType,
          })}
          onSubmit={() => {
            onSubmit();
          }}
        >
          <Form>
            <Modal.Body>
              <FormDynamic
                formFieldsSchema={formFieldsSchema}
                mapHeight={mapHeight}
                eventCategoryList={eventCategoryList}
                dataSourceIndex={dataSourceIndex}
                setDataSourceIndex={setDataSourceIndex}
                setFinalValues={setFinalValues}
              />
            </Modal.Body>
            <Modal.Footer>
              {deletePrompt && (
                <div className={classes.deletePrompt}>
                  Please confirm to delete!
                  <span className={classes.actionBtnsRightColumn}>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnCancel}`}
                      onClick={() => setDeletePrompt(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                      onClick={() =>
                        deleteElement({
                          elementId: element.id,
                          scenario,
                          dispatch,
                          handleClose,
                        })
                      }
                      type="button"
                    >
                      DELETE
                    </button>
                  </span>
                </div>
              )}
              {!deletePrompt && (
                <span className={classes.actionBtns}>
                  <button
                    className={`${classes.actionBtn} ${classes.actionBtnCancel}`}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  {!noEditAccess && (
                    <span className={classes.actionBtnsRightColumn}>
                      {element.eventCategory[0] && (
                        <button
                          className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                          onClick={() => setDeletePrompt(true)}
                        >
                          Delete
                        </button>
                      )}
                      <button
                        className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
                        type="submit"
                        onClick={() => onSubmit}
                      >
                        Save{" "}
                      </button>{" "}
                    </span>
                  )}
                </span>
              )}
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal>
    </React.Fragment>
  );
};
ElementDynamic.propTypes = {
  elementType: PropTypes.string.isRequired,
  mapHeight: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  eventCategoryList: PropTypes.array.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
};
ElementDynamic.defaultProps = {
  elementType: "",
  formFieldsSchema: {},
  mapHeight: "",
  show: false,
  eventCategoryList: [],
};
export default ElementDynamic;
