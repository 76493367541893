import axios from "axios";
import properties from "./../utils/properties";
import { addToast } from "./../utils/helpers/alerts";
import {
  getFromLocalStorage,
  clearLocalStorage,
} from "../utils/helpers/storageHelpers";
import { createFullParticipantName } from "./helpers";

const URL =
  properties.deploymentLevel === "dev"
    ? properties.url.mainUrl.local
    : properties.url.mainUrl.server;

const FULL_PATH = {
  scenarioAPI: URL + properties.url.apiScenario,
  cloneScenarioAPI: URL + properties.url.apiScenarioDuplicate,
  organizationAPI: URL + properties.url.apiOrganization,
  participantAPI: URL + properties.url.apiParticipant,
  eventCategoryAPI: URL + properties.url.apiEventCategory,
  kpiAPI: URL + properties.url.apiKPI,
  lessonLearnedAPI: URL + properties.url.apiLessonLearned,
  commentAPI: URL + properties.url.apiComment,
  usersListAndOrganizationsAPI:
    URL + properties.url.apiUsersListAndOrganizations,
  kafkaSendElementAPI:
    URL +
    (properties.deploymentLevel === "dev"
      ? properties.url.apiKafkaSendElement.local
      : properties.url.apiKafkaSendElement.server),
};

const toastSuccess = (input) => {
  if (input) {
    addToast("success", input, 3000);
  }
};

const toastError = (error) => {
  if (error.message) {
    if (error.response.status === 401) {
      addToast("error", "Please sign-out and sign-in again", 2000);
      clearLocalStorage();
    } else {
      if (error.response) {
        addToast(
          "error",
          `Error message: ${error.response.data.message} `,
          false
        );
      } else {
        addToast("error", `Error message: ${error.message} `, false);
      }
    }
  }
};

const show404Error = (error) => {
  if (error.message.includes("404")) {
    addToast("error", "Error 404", false);
  } else if (error.message.includes("Network Error")) {
    addToast("error", "Network Error", false);
  } else {
    if (error.response) {
      addToast("error", `Error message: ${error.response.data.message}`, false);
    } else {
      addToast("error", `Error message: ${error.message}`, false);
    }
  }
};

function getAuthHeaders() {
  return {
    headers: {
      Authorization: `Bearer ${getFromLocalStorage(
        "authentication",
        "string",
        "accessToken"
      )}`,
      "Content-Type": "application/json",
    },
  };
}

// ---------- LOAD ALL SCENARIOS ----------
export async function getScenarioList() {
  try {
    const response = await axios.get(FULL_PATH.scenarioAPI, getAuthHeaders());

    return response.data;
  } catch (error) {
    console.error("getScenarioList: ", error.message);
    toastError(error);
    return null;
  }
}

// ---------- SAVE SCENARIO ----------
export async function saveScenario(scenario) {
  try {
    const response = await axios.post(
      FULL_PATH.scenarioAPI,
      scenario,
      getAuthHeaders()
    );
    // sessionStorage.setItem("scenario", JSON.stringify(response.data));
    return response.data;
  } catch (error) {
    return toastError(error);
  }
}

// ---------- DELETE SCENARIO ----------
export async function deleteScenario(scenarioId, title) {
  try {
    const response = await axios.delete(
      FULL_PATH.scenarioAPI + scenarioId,
      getAuthHeaders()
    );
    // sessionStorage.setItem("scenario", JSON.stringify(response.data));
    toastSuccess(`Scenario ${title} has been deleted successfully!`);
    return response.data;
  } catch (error) {
    return toastError(error);
  }
}

// ---------- CLONE SCENARIO ----------
export async function cloneScenario({ id, title }) {
  try {
    const response = await axios.get(FULL_PATH.cloneScenarioAPI, {
      params: {
        id,
        title,
      },
      ...getAuthHeaders(),
    });
    return response.data;
  } catch (error) {
    // return error;
    return toastError(error);
  }
}

// ---------- LOAD ORGANIZATIONS FROM DB ----------
export function loadOrganizationList(scenarioId, fillObject) {
  return axios
    .get(FULL_PATH.organizationAPI, {
      params: {
        scenario_id: scenarioId,
      },
      ...getAuthHeaders(),
    })
    .then((response) => {
      let tempOrgsList = [];
      if (fillObject) {
        tempOrgsList = response.data.map((e) => {
          return {
            value: e.name,
            label: e.name,
            acronym: {
              value: e.acronym,
              label: e.acronym,
            },
            country: {
              value: e.country,
              label: e.country,
            },
            levelOfCommand: {
              value: e.levelOfCommand,
              label: e.levelOfCommand,
            },
            id: e.id,
          };
        });
      } else {
        tempOrgsList = response.data.map((e) => {
          return {
            name: e.name,
            acronym: e.acronym,
            country: {
              value: e.country,
              label: e.country,
            },
            levelOfCommand: {
              value: e.levelOfCommand,
              label: e.levelOfCommand,
            },
            id: e.id,
          };
        });
      }

      return tempOrgsList;
    })
    .catch((result) => {
      if (result.message.includes("404")) {
        addToast("error", "Error 404", false);
      } else if (result.message.includes("Network Error")) {
        addToast("error", "Network Error", false);
      } else {
        addToast("error", `Error message: ${result.message}`, false);
      }
    });
}

// ---------- SAVE ORGANIZATION ----------
export function saveOrganization(input) {
  const organization = {
    ...input,
    levelOfCommand: input.levelOfCommand,
  };
  const optional = {};
  if (organization.id) {
    optional.id = organization.id;
  }

  return axios
    .post(
      FULL_PATH.organizationAPI,
      {
        ...organization,
      },
      getAuthHeaders()
    )
    .then((response) => {
      addToast(
        "success",
        `Successfully saved organization ${organization.name} to Database`,
        1500
      );
      return {
        name: response.data.name,
        acronym: response.data.acronym,
        country: {
          value: response.data.country,
          label: response.data.country,
        },
        levelOfCommand: {
          value: response.data.levelOfCommand,
          label: response.data.levelOfCommand,
        },
        id: response.data.id,
      };
    })
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- DELETE ORGANIZATION ----------
export function deleteOrganization(input) {
  if (input?.id) {
    return axios
      .delete(FULL_PATH.organizationAPI + input.id, getAuthHeaders())
      .then(() => {
        addToast(
          "success",
          `Successfully deleted Organization ${input.name} from Database`,
          1500
        );
      })
      .catch((error) => {
        show404Error(error);
      });
  }
  return null;
}

// ---------- LOAD PARTICIPANTS ----------
export function loadParticipantList(scenarioId) {
  return axios
    .get(FULL_PATH.participantAPI, {
      ...getAuthHeaders(),
      params: { scenario_id: scenarioId },
    })
    .then((response) => {
      let tempParticipantList = [];
      tempParticipantList = response.data.map((e) => {
        return {
          id: e.id,
          name: e.name,
          fullName: createFullParticipantName(e),
          scenario: scenarioId,
          levelOfCommand: {
            value: e.level_of_command,
            label: e.level_of_command,
          },
          organization: {
            ...e.organization,
            value: e.organization.name,
            label: e.organization.name,
          },
        };
      });
      return tempParticipantList;
    })
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- SAVE PARTICIPANT ----------
export function saveParticipant(participant) {
  return axios
    .post(
      FULL_PATH.participantAPI,
      {
        ...participant,
        level_of_command: participant.levelOfCommand,
        organization: participant.organization.id,
      },
      getAuthHeaders()
    )
    .then((response) => {
      addToast(
        "success",
        `Successfully saved participant ${response.data.name} to Database`,
        1500
      );

      return {
        id: response.data.id,
        name: response.data.name,
        scenario: participant.scenarioId,
        levelOfCommand: {
          value: response.data.level_of_command,
          label: response.data.level_of_command,
        },
        organization: response.data.organization,
      };
    })
    .catch((result) => {
      if (result.message.includes("404")) {
        addToast("error", "Error 404", false);
      } else if (result.message.includes("Network Error")) {
        addToast("error", "Network Error", false);
      } else {
        addToast("error", `Error message: ${result}`, false);
      }
    });
}

// ---------- DELETE PARTICIPANT ----------
export function deleteParticipant(input) {
  if (input?.id) {
    return axios
      .delete(FULL_PATH.participantAPI + input.id, getAuthHeaders())
      .then(() => {
        addToast(
          "success",
          `Successfully deleted Participant: ${input.name} from Database`,
          1500
        );
      })
      .catch((error) => {
        show404Error(error);
      });
  }
  return null;
}

// ---------- SAVE EVENT-CATEGORY ----------
export function saveEventCategory(eventCategory) {
  return axios
    .post(FULL_PATH.eventCategoryAPI, eventCategory, getAuthHeaders())
    .then(
      (response) => {
        addToast(
          "success",
          `Successfully saved Incident Category: ${eventCategory.name} to Database`,
          1500
        );
        return response.data;
      },
      (error) => {
        addToast("error", `Error message: ${error.message}`, false);
      }
    )
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- GET EVENT-CATEGORY list based on scenarioId ----------
export function loadEventCategoryList(scenarioId) {
  return axios
    .get(FULL_PATH.eventCategoryAPI, {
      ...getAuthHeaders(),
      params: { scenarioId },
    })
    .then((response) => {
      let tempEventCategoriyList = [];
      tempEventCategoriyList = response.data.map((e) => {
        return {
          name: e.name,
          color: e.color,
          id: e.id,
          scenarioId,
        };
      });
      return tempEventCategoriyList;
    })
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- DELETE EVENT-CATEGORY ----------
export function deleteEventCategory(eventCategory) {
  if (eventCategory?.id) {
    return axios
      .delete(FULL_PATH.eventCategoryAPI + eventCategory.id, getAuthHeaders())
      .then(
        () => {
          addToast(
            "success",
            `Successfully deleted Incident Category: ${eventCategory.name} from Database`,
            1500
          );
        },
        (error) => {
          addToast("error", `Error message: ${error.message}`, false);
        }
      )
      .catch((error) => {
        show404Error(error);
      });
  }
  return null;
}

// ---------- SEND TO KAFKA ----------
export function sendElementToKafkaService(element) {
  console.log("Sending to Kafka");
  return axios
    .post(FULL_PATH.kafkaSendElementAPI, element, {
      ...getAuthHeaders(),
      params: {
        test_mode: false, //properties.deploymentLevel === "dev",
      },
    })
    .catch((error) => toastError(error));
}

// ---------- LOAD KPIs ----------
export async function getKPIs(scenarioId) {
  try {
    let kpisList = [];
    const response = await axios
      .get(FULL_PATH.kpiAPI, {
        params: {
          scenario_id: scenarioId,
        },
        ...getAuthHeaders(),
      })
      .then((response) => {
        kpisList = response.data.map((e) => {
          return {
            achieved: e.achieved,
            comment: e.comment,
            id: e.id,
            name: e.name,
            scenarioId: e.scenarioId,
            start: e.start,
            target: e.target,
            type: {
              value: e.type,
              label: e.type,
            },
          };
        });
        return kpisList;
      });
    return kpisList;
  } catch (error) {
    toastError(error);
    return null;
  }
}

// ---------- SAVE KPI ----------
export function saveKPI(input) {
  const kpi = {
    ...input,
  };
  console.log("kpi ", kpi);
  // kpi.type = input.type[0].value;
  return axios
    .post(
      FULL_PATH.kpiAPI,
      {
        ...kpi,
      },
      getAuthHeaders()
    )
    .then((response) => {
      addToast(
        "success",
        `Successfully saved KPI ${kpi.name} to Database`,
        1500
      );
      return response;
      // {
      //   name: response.data.name,
      //   acronym: response.data.acronym,
      //   country: {
      //     value: response.data.country,
      //     label: response.data.country,
      //   },
      //   levelOfCommand: {
      //     value: response.data.levelOfCommand,
      //     label: response.data.levelOfCommand,
      //   },
      //   id: response.data.id,
      // };
    })
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- DELETE KPI ----------
export function deleteKPI(input) {
  if (input?.id) {
    return axios
      .delete(FULL_PATH.kpiAPI + input.id, getAuthHeaders())
      .then(() => {
        addToast(
          "success",
          `Successfully deleted KPI ${input.name} from Database`,
          1500
        );
      })
      .catch((error) => {
        show404Error(error);
      });
  }
  return null;
}

// ---------- LOAD Lessons Learned ----------
export async function getLessonsLearned(scenarioId) {
  try {
    let lessonsLearnedList = [];
    const response = await axios
      .get(FULL_PATH.lessonLearnedAPI, {
        params: {
          scenario_id: scenarioId,
        },
        ...getAuthHeaders(),
      })
      .then((response) => {
        lessonsLearnedList = response.data;
      });
    return lessonsLearnedList;
  } catch (error) {
    toastError(error);
    return null;
  }
}

// ---------- SAVE Lesson Learned ----------
export function saveLessonLearned(input) {
  return axios
    .post(
      FULL_PATH.lessonLearnedAPI,
      {
        ...input,
      },
      getAuthHeaders()
    )
    .then((response) => {
      addToast(
        "success",
        `Successfully saved Lesson Learned ${input.title} to Database`,
        1500
      );
      return response;
    })
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- DELETE Lesson Learned ----------
export function deleteLessonLearned(input) {
  if (input?.id) {
    return axios
      .delete(FULL_PATH.lessonLearnedAPI + input.id, getAuthHeaders())
      .then(() => {
        addToast(
          "success",
          `Successfully deleted Lesson Learned ${input.title} from Database`,
          1500
        );
      })
      .catch((error) => {
        show404Error(error);
      });
  }
  return null;
}

// ---------- LOAD Comments ----------
export async function getComments(scenarioId) {
  try {
    let commentsList = [];
    const response = await axios
      .get(FULL_PATH.commentAPI, {
        params: {
          scenario_id: scenarioId,
        },
        ...getAuthHeaders(),
      })
      .then((response) => {
        commentsList = response.data;
      });
    return commentsList;
  } catch (error) {
    toastError(error);
    return null;
  }
}

// ---------- SAVE Comment ----------
export function saveComment(input) {
  return axios
    .post(
      FULL_PATH.commentAPI,
      {
        ...input,
      },
      getAuthHeaders()
    )
    .then((response) => {
      addToast(
        "success",
        `Successfully saved Lesson Learned ${input.title} to Database`,
        1500
      );
      return response;
    })
    .catch((error) => {
      show404Error(error);
    });
}

// ---------- DELETE Comment ----------
export function deleteComment(input) {
  if (input?.id) {
    return axios
      .delete(FULL_PATH.commentAPI + input.id, getAuthHeaders())
      .then(() => {
        addToast(
          "success",
          `Successfully deleted Lesson Learned ${input.title} from Database`,
          1500
        );
      })
      .catch((error) => {
        show404Error(error);
      });
  }
  return null;
}

// ---------- LOAD ALL USERS and ORGANIZATIONS ----------
export async function getUsersAndOrganizations() {
  try {
    const response = await axios.get(
      FULL_PATH.usersListAndOrganizationsAPI,
      getAuthHeaders()
    );

    return response.data;
  } catch (error) {
    toastError(error);
    return null;
  }
}

// const kpis = [
//   {
//     name: "Performance",
//     type: "Percentage",
//     start: 0,
//     target: 100,
//   },
//   {
//     name: "Endurance",
//     type: "Numeric",
//     start: 0,
//     target: 5,
//   },
// ];
