function fillEventCategory(eventCategory, eventCategoryList) {
  let eventCategoryFilled = eventCategoryList.find(
    (e) => e.id === eventCategory
  );
  return [
    {
      ...eventCategoryFilled,
      value: eventCategoryFilled.name,
      label: eventCategoryFilled.name,
    },
  ];
}

export function fillElementForDisplay({ element, eventCategoryList }) {
  const { type } = element;

  let elementFilled = {
    ...element,
    eventCategory: element.eventCategory
      ? fillEventCategory(element.eventCategory, eventCategoryList)
      : [],
  };

  if (
    ["EVENT", "MESSAGE", "SOCIAL_MEDIA", "SOCIAL_MEDIA_BATCH"].includes(type)
  ) {
    elementFilled = {
      ...elementFilled,
      sender: ["EVENT", "MESSAGE"].includes(type)
        ? [
            {
              ...elementFilled.sender,
              value: elementFilled.sender.name,
              label: elementFilled.sender.name,
            },
          ]
        : (elementFilled.sender ? elementFilled.sender.name : elementFilled.sender),
      receivers: elementFilled.receivers
        ? elementFilled.receivers.map((r) => {
            return { id: r.id, label: r.name, value: r.name };
          })
        : [],
      socialMediaType: ["SOCIAL_MEDIA", "SOCIAL_MEDIA_BATCH"].includes(type)
        ? {
            value: elementFilled.socialMediaType,
            label: elementFilled.socialMediaType,
          }
        : "",
    };
  } else if (type === "DATA_SOURCE") {
    elementFilled = {
      ...elementFilled,
    };
  }
  return elementFilled;
}
