/* eslint-disable class-methods-use-this */
import qs from "qs";
import axios from "axios";
import properties from "./../../utils/properties";
import { addToast } from "./../helpers/alerts";

const URL =
  properties.deploymentLevel === "dev"
    ? properties.url.mainUrlAuth.local
    : properties.url.mainUrlAuth.server;

const FULL_PATH = {
  signinAPI: URL + properties.auth.authServerSignIn,
};

export const authLogin = (credentials) => {
  return axios({
    method: "post",
    url: FULL_PATH.signinAPI,
    data: qs.stringify({
      scope: properties.auth.scope,
      email: credentials.email,
      password: credentials.password,
    }),
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  })
    .then((result) => {
      if (result.status === 200) {
        addToast("success", "You have successfully signed in", 1500);
        return { data: result.data, status: result.status };
      }
      // addToast("error", "Wrong email and/or password", false);
      return {};
    })
    .catch((result) => {
      if (result.message.includes("404")) {
        addToast("error", "Error 404", false);
      } else if (result.message.includes("Network Error")) {
        addToast("error", "Network Error", false);
      } else if (result.message.includes("401")) {
        addToast("error", "Wrong email and/or password", false);
      }
      return { data: "", status: 404 };
    });
};
