import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authLogin } from "../utils/authentication/AuthService";
import jwt_decode from "jwt-decode";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "./../utils/helpers/storageHelpers";

export const signInThunk = createAsyncThunk(
  "authentication/signIn",
  async (credentials) => {
    const response = await authLogin(credentials);
    return response.status === 200 ? response.data : {};
  }
);

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    isAuthenticated: getFromLocalStorage(
      "authentication",
      "boolean",
      "isAuthenticated"
    ),
    user: getFromLocalStorage("authentication", "string", "user"),

    accessToken: getFromLocalStorage("authentication", "string", "accessToken"),
    loading: "idle",
    exp: getFromLocalStorage("authentication", "number", "exp"),
    client_id: getFromLocalStorage("authentication", "string", "client_id"),
    tokenType: getFromLocalStorage("authentication", "string", "tokenType"),
    roles: getFromLocalStorage("authentication", "array", "roles"),
  },
  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(signInThunk.fulfilled, (state, action) => {
      if (action.payload) {
        state.accessToken = action.payload.access_token;
        state.tokenType = action.payload.token_type;
        state.isAuthenticated = true;
        state.exp = jwt_decode(action.payload.access_token).exp;

        const subject = action.payload.user_details;
        // const subject = JSON.parse(
        //   jwt_decode(action.payload.access_token).subject
        // );
        state.user = {
          email: subject.email,
          username: subject.email.split("@")[0],
        };
        // state.client_id = subject.client_id;

        // if (subject.email === "a.karalis@exus.co.uk") {
        //   state.roles = ["admin"];
        // } else {
        //   state.roles = ["trainer"];
        // }
        state.roles = subject.roles;
        saveToLocalStorage("authentication", state);
      }
    });
  },
});
// each case under reducers becomes an action
//export const { login } = authSlice.actions;
export default authSlice.reducer;
