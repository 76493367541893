import eventFormFields from "../../../utils/constants/forms/event-form-fields";
import messageFormFields from "../../../utils/constants/forms/message-form-fields";
import socialMediaFormFields from "../../../utils/constants/forms/social-media-form-fields";
import dataSourceFormFields from "../../../utils/constants/forms/data-source-form-fields";
import participantFormFields from "../../../utils/constants/forms/participant-form-fields";
import organizationFormFields from "../../../utils/constants/forms/organization-form-fields";
import socialMediaBatchFormFields from "../../../utils/constants/forms/social-media-batch-form-fields";
import keyPerformanceIndicatorsFormFields from "../../../utils/constants/forms/key-performance-indicators-form-fields";
import lessonLearnedFormFields from "../../../utils/constants/forms/lesson-learned-form-fields";
import commentFormFields from "../../../utils/constants/forms/comment-form-fields";
import keyPerformanceIndicatorsEvalFormFields
  from "../../../utils/constants/forms/key-performance-indicators-eval-from-fields";


export function createFormFieldsSchema({
  elementType,
  eventCategoryList,
  participantList,
  scriptLength,
  commandLevelsList,
  organizationList,
  involvedCountries,
  startKPI,
  targetKPI,
}) {
  switch (elementType) {
    case "EVENT":
      return eventFormFields(eventCategoryList, participantList, scriptLength);
    case "MESSAGE":
      return messageFormFields(
        eventCategoryList,
        participantList,
        scriptLength
      );
    case "SOCIAL_MEDIA":
      return socialMediaFormFields(
        eventCategoryList,
        participantList,
        scriptLength
      );
    case "SOCIAL_MEDIA_BATCH":
      return socialMediaBatchFormFields(
        eventCategoryList,
        participantList,
        scriptLength
      );
    case "DATA_SOURCE":
      return dataSourceFormFields(
        eventCategoryList,
        participantList,
        scriptLength
      );
    case "PARTICIPANT":
      return participantFormFields(commandLevelsList, organizationList);

    case "ORGANIZATION":
      return organizationFormFields(commandLevelsList, involvedCountries);

    case "KPI":
      return keyPerformanceIndicatorsFormFields(scriptLength);

    case "LESSON_LEARNED":
      return lessonLearnedFormFields();

    case "COMMENT":
      return commentFormFields();

    case "KPI_EVALUATION":
      return keyPerformanceIndicatorsEvalFormFields(startKPI, targetKPI);

    default:
      return {};
  }
}
