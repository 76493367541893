const socialMediaFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "SOCIAL_MEDIA",
      description: "",
      name: "socialMedia",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident Category",
            name: "eventCategory",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Data File Selection",
            name: "dataObjectSelection",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [
                "spanish_trial_reddit_step_01_130522",
                "spanish_trial_tweets_step_01_fake_injected_120522",
                "spanish_trial_reddit_step_07_130522",
                "spanish_trial_tweets_step_07_fake_injected_120522",
                "romanian_trial_reddit_batch",
                "romanian_trial_tweets_batch",
              ],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Time of the post",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
          },
          // {
          //   type: "Quantity",
          //   valueType: "integer",
          //   description: "",
          //   label: "Index",
          //   name: "index",
          //   placeholder: "",
          //   constraint: {
          //     minValue: 1,
          //   },
          //   required: true,
          // },
        ],
      },
    },
  ],
};

const socialMediaBatchFormFields = (eventCategoryList) => {
  socialMediaFormFieldsInitial.value[0].data.field[0].constraint.value =
    eventCategoryList;
  return socialMediaFormFieldsInitial;
};

export default socialMediaBatchFormFields;
