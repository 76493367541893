const messageFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "MESSAGE",
      description: "",
      name: "message",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident Category",
            name: "eventCategory",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Sender",
            name: "sender",
            placeholder: "Sender of this message",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Receiver(s)",
            name: "receivers",
            placeholder: "Receiver(s) of this message",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: true,
            required: true,
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Message",
            name: "message",
            required: true,
            rows: 3,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location coordinates",
            name: "locationCoordinates",
            placeholder: "Will appear after point selection on map",
            disabled: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location Address",
            name: "locationAddress",
            placeholder: "If exists will appear after point selection on map",
            required: false,
            disabled: true,
          },
          {
            type: "Coordinates",
            valueType: "string",
            description: "",
            label: "Point selection on map",
            name: "coordinates",
          },
          // {
          //   type: "File",
          //   valueType: "string",
          //   description:
          //     "with any type of values: string, integer, float etc...",
          //   label: "Upload a file (optional). Size up to 500 kBytes",
          //   name: "file",
          //   constraint: {
          //     encoding: "Base64",
          //     value: {},
          //   },
          //   required: false,
          // },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Time Occurred",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
            isRange: false,
          },
        ],
      },
    },
  ],
};

const messageFormFields = (eventCategoryList) => {
  messageFormFieldsInitial.value[0].data.field[0].constraint.value =
    eventCategoryList;
  return messageFormFieldsInitial;
};

export default messageFormFields;
