import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { EN } from "./constants/translations/en.js";
import { GR } from "./constants/translations/gr.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: EN,
      },
      gr: {
        translation: GR,
      },
    },
  });

i18n.changeLanguage("en");
//   '/resources/i18n/translations/{{lng}}.json'
