const participantFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "Participant",
      description: "",
      name: "participant",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Name",
            name: "name",
            placeholder: "Participant name",
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Level of command",
            name: "levelOfCommand",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Organization",
            name: "organization",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
        ],
      },
    },
  ],
};

const participantFormFields = (levelOfCommandList, organizationList) => {
  participantFormFieldsInitial.value[0].data.field[1].constraint.value =
    levelOfCommandList;
  participantFormFieldsInitial.value[0].data.field[2].constraint.value =
    organizationList;
  return participantFormFieldsInitial;
};
export default participantFormFields;
