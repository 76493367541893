import React from "react";
import { createUseStyles } from "react-jss";
import closeIconX from "../../assets/icons/closeIconX.svg";
import { variables, myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import CreateFormField from "../../utils/helpers/create-form-field";
import { useTranslation } from "react-i18next";
import { createFormFieldsSchema } from "../AddElement/helpers/createFormFieldsSchema";
import { saveKPIThunk, deleteKPIThunk } from "../../redux/kpisSlice";

const addEditKPIStyles = createUseStyles({
  editparticipant: {},
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    alignContent: "top",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .9)",
    zIndex: 1,
  },
  modal: {
    position: "absolute",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    background: "white",
    borderRadius: ".5rem",
    zIndex: 2,
    padding: "1rem",
    top: "5rem",
    width: "max-content",
  },
  header: (props) => ({
    display: "grid",
    color: props.onDelete ? "red" : variables.colors.basicDark,
    gridTemplateColumns: "1fr max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
  }),
  icon: {
    width: "1.5rem",
    cursor: "pointer",
  },
  form: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "1.5rem",
  },
  formFields: {
    display: "grid",
    gridTemplateColumns: "20rem repeat(3, 8rem)",
    gridGap: "1rem",
  },
  actionBtnContainer: {
    width: "100%",
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "1fr auto",
  },
  actionBtn: {
    ...myButton,
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
export const AddEditKPI = (props) => {
  const { kpi, onDelete, showModal, setShowModal, setKpi, setOnDelete } = props;
  const classes = addEditKPIStyles({ onDelete });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: scenarioId } = useSelector((state) => state.scenario);

  const formFieldsSchema = createFormFieldsSchema({
    elementType: "KPI",
  });

  function saveToDB(values) {
    let kpiTemp = {
      name: values.name,
      type: values.type[0].value,
      start: values.start,
      target: values.target,
      scenarioId,
    };
    if (kpi.id) {
      kpiTemp = {
        ...kpiTemp,
        id: kpi.id,
      };
    }
    console.log("kpiTemp ", kpiTemp);
    dispatch(saveKPIThunk(kpiTemp)).then((response) => {
      console.log("thunk ", response);
      setKpi({});
      setShowModal(false);
    });
  }

  function deleteFunc() {
    dispatch(deleteKPIThunk({ ...kpi, scenario: scenarioId })).then(() => {
      setKpi({});
      setOnDelete(false);
      setShowModal(false);
    });
  }

  return (
    <div className={classes.editparticipant}>
      {showModal > 0 && (
        <React.Fragment>
          <div className={classes.modal}>
            <div className={classes.header}>
              {onDelete ? "Delete" : [kpi.id ? "Edit" : "Add"]} KPI
              <img
                src={closeIconX}
                alt=""
                className={classes.icon}
                onClick={() => {
                  setKpi({});
                  setOnDelete(false);
                  setShowModal(false);
                }}
              />
            </div>

            <div className={classes.content}>
              <Formik
                initialValues={{
                  name: kpi.name,
                  type: kpi.type ? [kpi.type] : [],
                  start: kpi.start ? kpi.start : 0,
                  target: kpi.target ? kpi.target : 0,
                }}
                validationSchema={createValidationSchema({
                  formFieldsSchema,
                  elementType: "KPI",
                })}
                onSubmit={(values) => {
                  saveToDB(values);

                  /*
                   * setTimeout(() => {
                   *   alert(JSON.stringify(values, null, 2));
                   *   setSubmitting(false);
                   * }, 400);
                   */
                }}
              >
                <Form className={classes.form}>
                  <span className={classes.formFields}>
                    {formFieldsSchema &&
                      formFieldsSchema.value[0].data.field.map((field, i) => (
                        <CreateFormField
                          key={i}
                          field={{ ...field, disabled: onDelete }}
                          index={i}
                          hideRequiredText={true}
                        />
                      ))}
                  </span>
                  <span className={classes.actionBtnContainer}>
                    {onDelete ? (
                      <button
                        type="button"
                        className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                        onClick={() => {
                          deleteFunc();
                        }}
                      >
                        {t("confirm")}
                      </button>
                    ) : (
                      <button type="submit" className={classes.actionBtn}>
                        {t("save")}
                      </button>
                    )}
                  </span>
                </Form>
              </Formik>
            </div>
          </div>
          <div
            className={classes.modalOverlay}
            onClick={() => {
              setKpi({});
              setShowModal(false);
            }}
          >
            {" "}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
AddEditKPI.defaultProps = {
  showModal: false,
  kpi: {},
  onDelete: false,
};
AddEditKPI.propTypes = {
  kpi: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onDelete: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setKpi: PropTypes.func.isRequired,
  setOnDelete: PropTypes.func.isRequired,
};
