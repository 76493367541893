import React from "react";
import { createUseStyles } from "react-jss";
import closeIconX from "../../assets/icons/closeIconX.svg";
import { variables, myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import CreateFormField from "../../utils/helpers/create-form-field";
import { useTranslation } from "react-i18next";
import commandLevelsList from "../../utils/constants/data/command-levels-list";
import { createFormFieldsSchema } from "../AddElement/helpers/createFormFieldsSchema";
import {
  saveParticipantThunk,
  deleteParticipantThunk,
} from "../../redux/participantSlice";
import { addToast } from "../../utils/helpers/alerts";
import { useEffect, useState } from "react";

const addEditParticipantStyles = createUseStyles({
  editparticipant: {},
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    alignContent: "top",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .9)",
    zIndex: 1,
  },
  modal: {
    position: "absolute",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    background: "white",
    borderRadius: ".5rem",
    zIndex: 2,
    padding: "1rem",
    top: "5rem",
    width: "max-content",
  },
  header: (props) => ({
    display: "grid",
    color: props.onDelete ? "red" : variables.colors.basicDark,
    gridTemplateColumns: "1fr max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
  }),
  icon: {
    width: "1.5rem",
    cursor: "pointer",
  },
  form: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "1.5rem",
  },
  formFields: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 10rem)",
    gridGap: "1rem",
  },
  actionBtnContainer: {
    width: "100%",
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "1fr auto",
  },
  actionBtn: {
    ...myButton,
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
export const AddEditParticipant = (props) => {
  const {
    participant,
    onDelete,
    showModal,
    setShowModal,
    setParticipant,
    setOnDelete,
    participantListFromState,
  } = props;
  const classes = addEditParticipantStyles({ onDelete });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: scenarioId } = useSelector((state) => state.scenario);

  const organizationListFromState = useSelector((state) =>
    state.organization.organizationList.map((org) => org)
  );

  const [participantNames, setParticipantNames] = useState([]);

  function getParticipantNames() {
    if (participantListFromState.length) {
      setParticipantNames(
        participantListFromState.filter((p) => p.name !== participant.name).map((pp) => pp.name)
      );
    }
  }

  useEffect(() => {
    getParticipantNames();
  }, [participantListFromState]);

  const formFieldsSchema = createFormFieldsSchema({
    elementType: "PARTICIPANT",
    commandLevelsList: commandLevelsList.list.map((level) => level.value),
    organizationList: organizationListFromState.map((org) => org.name),
  });

  function saveToDB(values) {
    if (participantNames.includes(values.name)) {
      setParticipant({});
      setShowModal(false);
      addToast(
        "error",
        `Error message: Participant with name '${values.name}' already exists`,
        false
      );
    } else {
      let participantTemp = {
        name: values.name,
        levelOfCommand: values.levelOfCommand[0].value,
        organization: organizationListFromState.find(
          (org) => org.name === values.organization[0].value
        ),
        scenario: scenarioId,
      };
      if (participant.id) {
        participantTemp = {
          ...participantTemp,
          id: participant.id,
        };
      }
      dispatch(saveParticipantThunk(participantTemp)).then(() => {
        setParticipant({});
        setShowModal(false);
      });
    }
  }

  function deleteFunc() {
    dispatch(
      deleteParticipantThunk({ ...participant, scenario: scenarioId })
    ).then(() => {
      setParticipant({});
      setOnDelete(false);
      setShowModal(false);
    });
  }

  return (
    <div className={classes.editparticipant}>
      {showModal > 0 && (
        <React.Fragment>
          <div className={classes.modal}>
            <div className={classes.header}>
              {onDelete ? "Delete" : [participant.id ? "Edit" : "Add"]}{" "}
              Participant
              <img
                src={closeIconX}
                alt=""
                className={classes.icon}
                onClick={() => {
                  setParticipant({});
                  setOnDelete(false);
                  setShowModal(false);
                }}
              />
            </div>

            <div className={classes.content}>
              <Formik
                initialValues={{
                  name: participant.name,
                  levelOfCommand: participant.levelOfCommand ? [participant.levelOfCommand] : [],
                  organization: participant.organization ? [participant.organization] : [],
                }}
                validationSchema={createValidationSchema({
                  formFieldsSchema,
                })}
                onSubmit={(values) => {
                  saveToDB(values);

                  /*
                   * setTimeout(() => {
                   *   alert(JSON.stringify(values, null, 2));
                   *   setSubmitting(false);
                   * }, 400);
                   */
                }}
              >
                <Form className={classes.form}>
                  <span className={classes.formFields}>
                    {formFieldsSchema &&
                      formFieldsSchema.value[0].data.field.map((field, i) => (
                        <CreateFormField
                          key={i}
                          field={{ ...field, disabled: onDelete }}
                          index={i}
                          hideRequiredText={true}
                        />
                      ))}
                  </span>
                  <span className={classes.actionBtnContainer}>
                    {onDelete ? (
                      <button
                        type="button"
                        className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                        onClick={() => {
                          deleteFunc();
                        }}
                      >
                        {t("confirm")}
                      </button>
                    ) : (
                      <button type="submit" className={classes.actionBtn}>
                        {t("save")}
                      </button>
                    )}
                  </span>
                </Form>
              </Formik>
            </div>
          </div>
          <div
            className={classes.modalOverlay}
            onClick={() => {
              setParticipant({});
              setShowModal(false);
            }}
          >
            {" "}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
AddEditParticipant.defaultProps = {
  showModal: false,
  participant: {},
  onDelete: false,
};
AddEditParticipant.propTypes = {
  participant: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onDelete: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setParticipant: PropTypes.func.isRequired,
  setOnDelete: PropTypes.func.isRequired,
  participantListFromState: PropTypes.array.isRequired,
};
