import React from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";
import { useField } from "formik";
import { LabelRequiredAndErrorMessage } from "./helpers";
import "./form-field.css";

const QuantityTypeFieldFormik = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { type, uom, fieldLabel, required } = props;

  const { setValue } = helpers;
  const updateState = (input) => {
    setValue(input);
  };
  return (
    <React.Fragment>
      <BootstrapForm.Group controlId={`formBasic${fieldLabel}`}>
        <BootstrapForm.Label>
          {`Select ${fieldLabel}`} {uom ? `in ${uom}` : ``}
        </BootstrapForm.Label>
        <BootstrapForm.Control
          type={type}
          placeholder={`Select ${fieldLabel}`}
          value={field.value}
          onChange={(e) => updateState(Number(e.target.value))}
          className="selection"
          step={".01"}
        />
        <LabelRequiredAndErrorMessage meta={meta} required={required} />
      </BootstrapForm.Group>
    </React.Fragment>
  );
};

QuantityTypeFieldFormik.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  uom: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
};

QuantityTypeFieldFormik.defaultProps = {
  type: "",
  name: "objectName",
  minValue: 0,
  maxValue: 0,
  fieldLabel: "",
  required: false,
  uom: "",
};

export default QuantityTypeFieldFormik;
