import React, { useState, useEffect } from "react";
import Map from "./../../components/map/MapTimeline";
import ScenarioAddElement from "../../components/ScenarioAddElement";
import EventCategory from "../../components/EventCategory";
import { useSelector, useDispatch } from "react-redux";
import {
  addElementToScenarioReducer,
  updateElementToScenario,
} from "../../redux/scenarioSlice";
import TimelineGantt from "../../components/timeline-gantt";
import SidebarRight from "../../components/sidebar/sidebarRight";
import { createUseStyles } from "react-jss";
import { variables, myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import { getElementsListWithTimestamps } from "./helpers";
import OrganizationStructure from "../../components/OrganizationStructure";
import EditScenarioInTimeline from "./EditScenarioInTimeline";
import EvaluateScenarioSession from "./EvaluateScenarioSession";
import arrowUp from "../../assets/icons/arrowUp.svg";
import arrowDown from "../../assets/icons/arrowDown.svg";
import { loadLessonsLearnedThunk } from "../../redux/lessonsLearnedSlice";
import { loadCommentsThunk } from "../../redux/commentSlice";

const useStyles = createUseStyles({
  scenario: (props) => ({
    display: "grid",
    gridTemplateColumns: props.isExpandedRight ? "1fr max-content" : "1fr",
  }),
  scenarioMain: () => ({
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: ".5rem",
    gridGap: ".5rem",
  }),
  firstRow: {
    display: "block",
  },
  mapContainer: {
    width: "100%",
    background: "white",
    borderRadius: "12px",
    overflow: "hidden",
    boxShadow: variables.myShadow,
    position: "relative",
  },
  firstRowColumn2: {
    background: "white",
  },
  secondRow: {
    display: "grid",
    gridGap: ".5rem",
    padding: ".5rem",
    background: "white",
    borderRadius: "12px",
  },
  row1: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content) 1fr max-content max-content",
    gridGap: ".5rem",
  },
  row2: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    gridGap: ".5rem",
  },
  btn: {
    ...myButton,
  },
  btnLabel: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: ".5rem",
    alignItems: "center",
  },
  eventCategory: {
    background: "white",
  },
  elementSentBox: {
    background: "white",
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: variables.myShadow,
  },
  timeline: {
    background: "white",
    borderRadius: ".5rem",
    boxShadow: variables.myShadow,
  },
  timelineBtmPanel: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    padding: ".5rem 1rem",
  },
  sidebarContainer: {
    width: "100%",
    height: "95vh",
  },
  headerPanel: {
    display: "grid",
    gridTemplateColumns: "1fr  max-content",
    gridGap: ".5rem",
  },
  header: {
    fontSize: "large",
    fontFamily: variables.fontHeaderFamily,
  },
});
// eslint-disable-next-line max-statements
const Scenario = (props) => {
  const { isExpandedRight, setIsExpandedRight } = props;

  const classes = useStyles({ isExpandedRight });
  const heightMap = "14rem";

  const dispatch = useDispatch();

  const { scenario, eventCategory } = useSelector((state) => state);
  const noEditAccess = ["READ_ACCESS"].includes(scenario.accessType);

  const scenarioLength = scenario ? scenario.length : 0;

  const [elementsSent, setElementsSent] = useState([]);
  const [showMap, setShowMap] = useState(true);
  const [controlPanelExpanded, setControlPanelExpanded] = useState(true);

  const [eventCategoryList, setEventCategoryList] = useState(
    eventCategory.eventCategoryList
  );

  const [elementTimes, setElementTimes] = useState({});

  const [showElementModal, setShowElementModal] = useState("");

  useEffect(() => {
    setElementTimes(getElementsListWithTimestamps(scenario));
  }, [scenario]);

  useEffect(() => {
    setEventCategoryList(eventCategory.eventCategoryList);
  }, [eventCategory.eventCategoryList]);

  const addElementToScenario = (input) => {
    dispatch(addElementToScenarioReducer(input));
  };

  const updateExistingElementToScenario = (input) => {
    dispatch(updateElementToScenario(input));
  };

  useEffect(() => {
    dispatch(loadLessonsLearnedThunk(scenario.id));
    dispatch(loadCommentsThunk(scenario.id));
    }, [scenario]);

  return (
    // <ScenarioContext.Provider value={{ ...scenario, trial: "test" }}>
    <div className={classes.scenario}>
      {scenario && scenario.script && (
        <div className={classes.scenarioMain}>
          <div className={classes.firstRow}>
            <div className={classes.mapContainer}>
              {showMap ? (
                <Map
                  location={scenario.location.coordinates}
                  script={scenario.script.filter((element) =>
                    ["EVENT", "MESSAGE", "SOCIAL_MEDIA"].includes(element.type)
                  )}
                  height={heightMap}
                  isScenarioTimelineMap={true}
                  setShowMap={setShowMap}
                />
              ) : (
                <OrganizationStructure height={heightMap} />
              )}
            </div>
            <div className={classes.firstRowColumn2}></div>
          </div>
          <div className={classes.secondRow}>
            <div className={classes.row1}>
              <div className={classes.header}>{scenario.title}</div>
              <EditScenarioInTimeline />
              <EvaluateScenarioSession />
              {/* <OrganizationStructure /> */}
              {scenario.trial && (
                <span className={classes.trialName}>
                  Trial: {scenario.trial}
                </span>
              )}

              <button
                className={classes.btn}
                onClick={() => setShowMap((e) => !e)}
              >
                {showMap ? "Show Org chart" : "Show Map"}
              </button>
              <button
                className={classes.btn}
                onClick={() => setControlPanelExpanded((e) => !e)}
              >
                <span className={classes.btnLabel}>
                  {controlPanelExpanded ? (
                    <React.Fragment>
                      {"options"}
                      <img src={arrowUp} alt="" />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {"options"}
                      <img src={arrowDown} alt="" />
                    </React.Fragment>
                  )}
                </span>
              </button>
            </div>
            {controlPanelExpanded && (
              <div className={classes.row2}>
                <div className={classes.eventCategory}>
                  <EventCategory />
                </div>
                {eventCategoryList && (
                  <ScenarioAddElement
                    addElementToScenario={addElementToScenario}
                    updateExistingElementToScenario={
                      updateExistingElementToScenario
                    }
                    eventCategoryList={eventCategoryList}
                    showElementModal={showElementModal}
                    setShowElementModal={setShowElementModal}
                    noEditAccess={noEditAccess}
                  />
                )}
              </div>
            )}
          </div>
          <div className={classes.timeline}>
            {scenario.script.length > 0 && (
              <TimelineGantt
                scenario={scenario}
                scenarioLength={scenarioLength}
                setShowElementModal={setShowElementModal}
                elementTimes={elementTimes}
                setElementTimes={setElementTimes}
                elementsSent={elementsSent}
                setElementsSent={setElementsSent}
                setIsExpandedRight={setIsExpandedRight}
                noEditAccess={noEditAccess}
              />
            )}
            {/* <div className={classes.timelineBtmPanel}>
              <div className={classes.eventCategory}>
                <EventCategory />
              </div>

              {eventCategoryList && (
                <ScenarioAddElement
                  addElementToScenario={addElementToScenario}
                  updateExistingElementToScenario={
                    updateExistingElementToScenario
                  }
                  eventCategoryList={eventCategoryList}
                  showElementModal={showElementModal}
                  setShowElementModal={setShowElementModal}
                  noEditAccess={noEditAccess}
                />
              )}
            </div> */}
          </div>
        </div>
      )}
      {isExpandedRight && (
        <div className={classes.sidebarContainer}>
          <SidebarRight
            elementsSent={elementsSent}
            isExpanded={isExpandedRight}
          />
        </div>
      )}
    </div>
    // </ScenarioContext.Provider>
  );
};
Scenario.defaultProps = {
  isExpandedRight: false,
};
Scenario.propTypes = {
  isExpandedRight: PropTypes.bool.isRequired,
  setIsExpandedRight: PropTypes.func.isRequired,
};
export default Scenario;
