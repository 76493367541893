const signInFormFields = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "SIGN-IN",
      description: "",
      name: "signIn",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            placeholder: "email",
            name: "email",
            required: "true",
          },
          {
            type: "Password",
            valueType: "string",
            description: "",
            placeholder: "password",
            name: "password",
            required: "true",
          },
        ],
      },
    },
  ],
};

export default signInFormFields;
