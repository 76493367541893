import React from "react";
import staminaLogoSmall from "./../../assets/images/STAMINA_logo_small.png";
import staminaLogoTextOnly from "./../../assets/images/STAMINA_logo_text_only.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Formik, Form } from "formik";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import CreateFormField from "../../utils/helpers/create-form-field";
import signInFormFields from "../../utils/constants/forms/sign-in-form-fields";
import { useDispatch } from "react-redux";
import { signInThunk } from "../../redux/authSlice";
import { getUsersAndOrganizationsThunk } from "../../redux/usersAndOrgsSlice";
import { createUseStyles } from "react-jss";
import { variables, myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import NIGHTINGALE_vertical_logo from "../../resources/NIGHTINGALE_vertical_logo.svg";
import SILVANUS_logo from "../../resources/SILVANUS_logo.svg";
import EXUS_logo from "../../assets/images/EXUS_logo_white76x31.png";

const proj_name = process.env.REACT_APP_PROJECT_NAME || "default";

const useStyles = createUseStyles({
  signInPanel: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    gridGap: "2rem",
    background: "white",
    height: "max-content",
    marginTop: "7rem",
    justifySelf: "center",
    padding: "1rem",
    borderRadius: "1rem",
    boxShadow: variables.myShadow,
  },
  signInForm: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    width: "15rem",
  },
  signInFormTitle: {
    fontSize: "large",
    fontFamily: variables.fontHeaderFamily,
  },
  logos: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    padding: ".5rem 0",
    justifyItems: "center",
    gridGap: "2.2rem",
  },
  button: {
    ...myButton,
    marginTop: "1.5rem",
    color: variables.colors.basicBlue,
    borderColor: variables.colors.basicBlue,
  },
  projectLogo: {
    width: "7rem",
  },
  projectLogoNightingale: {
    width: "7rem",
    gridRow: "span 2",
  },
  projectLogoSilvanus: {
    marginTop: "4rem",
    width: "12rem",
  },
  projectLogoCoprotect: {
    marginTop: "5rem",
    width: "5rem",
  },
  projectLogoSmall: {
    width: "3rem",
  },
});
const SignIn = (props) => {
  const { setIAuthenticated } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const classes = useStyles();

  const signIn = (credentials) => {
    dispatch(signInThunk(credentials)).then((result) => {
      if (result.payload.access_token) {
        setIAuthenticated(true);
        navigate("/");
        dispatch(getUsersAndOrganizationsThunk()).then(() => {
          console.log("resultUsersAndOrgs");
        });
      }
    });
  };

  return (
    <React.Fragment>
      <div className={classes.signInPanel}>
        <div className={classes.signInForm}>
          <span className={classes.signInFormTitle}>
            {t("signIn.signInLabel")}
          </span>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={createValidationSchema({
              formFieldsSchema: signInFormFields,
            })}
            onSubmit={(values) => {
              signIn({ email: values.email, password: values.password });

              /*
               * setTimeout(() => {
               *   alert(JSON.stringify(values, null, 2));
               *   setSubmitting(false);
               * }, 400);
               */
            }}
          >
            <Form>
              <span>
                {signInFormFields &&
                  signInFormFields.value[0].data.field.map((field, i) => (
                    <CreateFormField
                      key={i}
                      field={field}
                      index={i}
                      hideRequiredText={true}
                    />
                  ))}
              </span>
              <button type="submit" className={classes.button}>
                {t("signIn.signInButtonLabel")}
              </button>
            </Form>
          </Formik>
        </div>
        <div className={classes.logos}>
          {proj_name === "nightingale" ? (
            <img
              src={NIGHTINGALE_vertical_logo}
              alt=""
              className={classes.projectLogoNightingale}
            />
          ) : proj_name === "silvanus" ? (
            <img
              src={SILVANUS_logo}
              alt=""
              className={classes.projectLogoSilvanus}
            />
          ) : proj_name === "co-protect" ? (
            <img
              src={EXUS_logo}
              alt=""
              className={classes.projectLogoCoprotect}
            />
          ) : (
            <React.Fragment>
              <img
                src={staminaLogoTextOnly}
                alt=""
                className={classes.projectLogo}
              />
              <img
                src={staminaLogoSmall}
                alt=""
                width=""
                height=""
                className={classes.projectLogoSmall}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
SignIn.defaultProps = {};
SignIn.propTypes = {
  setIAuthenticated: PropTypes.func.isRequired,
};
export default SignIn;
