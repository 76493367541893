import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import Clock from "../clock/index";
import { useSystemClock } from "../../utils/helpers/customHooks/SystemClock";
import { createDateRange, sendElementTokafka } from "./helpers";
import { Chart } from "react-google-charts";
import { useSelector, useDispatch } from "react-redux";
import { setElement } from "../../redux/elementSlice";
import { variables } from "../../jssVariables";
import EvaluateScenarioSession from "../../pages/scenario/EvaluateScenarioSession";
import moment from "moment";
import { DateTime } from "luxon";
import { getElementsListWithTimestamps } from "../../pages/scenario/helpers";

const useStyles = createUseStyles({
  timelineContainer: () => ({
    display: "grid",
    gridTemplateRows: "repeat(3, max-content)",
    padding: ".5rem 1rem",
    paddingBottom: "2rem",
    borderRadius: "12px",
    gridGap: ".5rem",
    //height: "42vh",
    height: "max-content",
  }),
  headerPanel: {
    display: "grid",
    gridTemplateColumns: "1fr  max-content",
    gridGap: ".5rem",
  },
  headerPanelLeft: {
    display: "grid",
    gridTemplateColumns: "repeat(4, max-content)",
    gridGap: ".5rem",
  },
  header: {
    fontSize: "large",
    fontFamily: variables.fontHeaderFamily,
  },
  clock: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    justifyContent: "end",
  },
  startTimeEndTime: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    height: "fit-parent",
    justifyContent: "space-between",
    fontSize: "small",
  },
  timeline: {
    height: "max-content",
    display: "grid",
    gridTemplateRows: "max-content max-content",
  },
  timelineSingleLineScenario: {
    overflow: "hidden",
    float: "left",
  },
  timelineAllElements: (props) => ({
    overflow: "scroll",
    float: "left",
    height: `calc(${props.scenarioLength} * 26px + 1rem)`,
    maxHeight: "15rem",
  }),
});
// eslint-disable-next-line max-statements
const TimelineGantt = (props) => {
  const { scenario, eventCategoryList } = useSelector((state) => {
    return {
      scenario: state.scenario,
      eventCategoryList: state.eventCategory.eventCategoryList,
    };
  });
  const {
    setShowElementModal,
    elementTimes,
    setElementTimes,
    elementsSent,
    setElementsSent,
    setIsExpandedRight,
    noEditAccess,
  } = props;

  const classes = useStyles({ scenarioLength: scenario.script.length });

  const dispatch = useDispatch();

  const [systemTime, givenTime, isPaused, setManualDatetime, setIsPaused] =
    useSystemClock();

  function addHours(dateObj, hours) {
    let d = new Date(dateObj);
    d.setHours(d.getHours() + hours - 1);
    return d;
  }

  const { minDate, maxDate } = createDateRange(scenario.script);

  const [editTime, setEditTime] = useState();

  let currMinDate = minDate;

  useEffect(() => {
    if (editTime) {
      currMinDate = DateTime.fromJSDate(editTime).valueOf();
      setElementTimes(getElementsListWithTimestamps(scenario));
    } else {
      currMinDate = minDate;
    }
  }, [editTime]);

  let remainingHours = "";
  let systemTimeInEpoch = moment(systemTime);

  if (currMinDate < systemTime && maxDate > systemTime) {
    const remainingEpochTime = moment.duration(
      moment(maxDate).diff(systemTimeInEpoch)
    );

    const days = remainingEpochTime.asDays().toFixed();

    const hours = parseInt(remainingEpochTime.asHours(), 10) % 24;
    const minutes = parseInt(remainingEpochTime.asMinutes(), 10) % 60;
    const seconds = parseInt(remainingEpochTime.asSeconds(), 10) % 60;

    remainingHours = `${days} ${days > 1 ? `days` : `day`} - ${
      hours < 10 ? `0${hours}` : hours
    }:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  }

  function padWithSpaces(text) {
    const padWidth = 15;
    return text.toUpperCase().padEnd(padWidth, "_");
  }

  function createRows() {
    if (eventCategoryList !== undefined) {
      let percentage = 0;
      if (
        systemTime.valueOf() > currMinDate.valueOf() &&
        systemTime.valueOf() < maxDate.valueOf()
      ) {
        percentage =
          ((systemTime.valueOf() - currMinDate.valueOf()) /
            (maxDate.valueOf() - currMinDate.valueOf())) *
          100;
      } else if (systemTime.valueOf() > maxDate.valueOf()) {
        percentage = 100;
      }

      let rows = [
        [
          scenario.id,
          padWithSpaces("Full Scenario"),
          "0",
          new Date(currMinDate.valueOf()),
          new Date(maxDate.valueOf()),
          1000,
          Math.round(percentage),
          "",
        ],
      ];

      scenario.script.forEach((element) => {
        addHours(element.timeEnded, 1);

        rows.push([
          element.id,
          padWithSpaces(
            element.type.slice(0, 1) + element.type.slice(1).toLowerCase()
          ), //`${element.type} - ${element.message}`,
          eventCategoryList.find((evCat) => {
            return evCat.id === element.eventCategory;
          })?.name,
          new Date(element.timeOccurred),
          addHours(element.timeEnded, 1),
          1000,
          100,
          "",
        ]);
      });
      return rows;
    }
    return [];
  }

  const columns = [
    { type: "string", label: "Task ID" },
    { type: "string", label: "Task Name" },
    { type: "string", label: "Resource" },
    { type: "date", label: "Start Date" },
    { type: "date", label: "End Date" },
    { type: "number", label: "Duration" },
    { type: "number", label: "Percent Complete" },
    { type: "string", label: "Dependencies" },
  ];

  useEffect(() => {
    if (eventCategoryList !== undefined) {
      // getData();

      sendElementTokafka({
        systemTime,
        setElementTimes,
        elementTimes,
        elementsSent,
        setElementsSent,
      });
    }
  }, [systemTime]);

  const dataFull = [columns, ...createRows()];

  function getDataSingleLine() {
    if (eventCategoryList !== undefined) {
      if (dataFull.length > 0) {
        return [[...dataFull[0]], [...dataFull[1]]];
      }
    }
    return [];
  }
  function getDataAllElements() {
    if (eventCategoryList !== undefined) {
      const temp = [...dataFull];
      temp.splice(1, 1);
      return temp;
    }
    return [];
  }

  function getOptions(input) {
    const baseColors = [];
    const baseColorsSingleLineScenario = [
      {
        color: "#5e97f6",
        dark: "#2a56c6",
        light: "#c6dafc",
      },
    ];
    if (eventCategoryList !== undefined) {
      const temp = [...dataFull];
      temp.splice(0, 2);
      temp.sort(function(a, b) {
        return new Date(a[3]) - new Date(b[3]);
      });
      let colorsHelper = [];
      temp.forEach((t) => {
        const colorCat = eventCategoryList.find((evCat) => {
          return evCat.name === t[2];
        })?.color;
        if (!colorsHelper.includes(colorCat)) {
          colorsHelper.push(colorCat);
          baseColors.push({
            color: colorCat,
            dark: colorCat,
            light: colorCat,
          });
        }
      });
    }
    const options = {
      // eslint-disable-next-line no-mixed-operators
      height: scenario.script.length * 25, //scenario.script.length * 50 + 70,
      gantt: {
        percentEnabled: false,
        criticalPathEnabled: false,
        defaultStartDateMillis: new Date(2015, 3, 28),
        labelStyle: {
          fontSize: "x-small",
          color: "#000000",
          width: 500,
          background: "red",
        },
        barHeight: 15,
        //innerGridHorizLine: { strokeWidth: 1 },
        trackHeight: 25,
        palette: baseColors,
      },
    };

    const optionsSingleLineScenario = {
      height: "9rem",
      gantt: {
        defaultStartDateMillis: new Date(2015, 3, 28),
        labelStyle: {
          fontSize: "x-small",
          color: "#000000",
          width: 500,
          background: "red",
        },
        barHeight: 15,
        //innerGridHorizLine: { strokeWidth: 1 },
        trackHeight: 25,
        palette: baseColorsSingleLineScenario,
      },
    };
    if (input === "options") {
      return options;
    }
    return optionsSingleLineScenario;
  }

  function editElement(elementData) {
    if (elementData.c[2].v !== "0") {
      const elementId = elementData.c[0].v;
      let element = scenario.script.find((el) => el.id === elementId);

      dispatch(setElement(element));
      setShowElementModal(element.type);
    }
  }

  return (
    <div className={classes.timelineContainer}>
      <div className={classes.headerPanel}>
        <span className={classes.headerPanelLeft}>
          {/* <div className={classes.header}>{scenario.title}</div> */}
          {/* <EditScenarioInTimeline />
          <EvaluateScenarioSession />
          {/* <OrganizationStructure /> */}
          {/* {scenario.trial && (
            <span className={classes.trialName}>Trial: {scenario.trial}</span>
          )} */}
        </span>
        <Clock
          systemTime={systemTime}
          editTime={editTime}
          setEditTime={setEditTime}
          minDate={minDate}
          scenario={scenario}
          setManualDatetime={setManualDatetime}
          hasEditTime={Boolean(editTime)}
          setIsExpandedRight={setIsExpandedRight}
          setElementTimes={setElementTimes}
          isPaused={isPaused}
          setIsPaused={setIsPaused}
          setElementsSent={setElementsSent}
        />{" "}
      </div>
      <div className={classes.startTimeEndTime}>
        <span>
          Start time:{" "}
          {new Date(currMinDate).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            day: "2-digit",
            month: "short",
          })}
        </span>
        <span>{remainingHours && `  ${remainingHours} remaining`}</span>
        <span>
          End time:{" "}
          {new Date(maxDate).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            day: "2-digit",
            month: "short",
          })}
        </span>
      </div>

      {scenario.script.length > 0 && (
        <div className={classes.timeline}>
          <span className={classes.timelineSingleLineScenario}>
            <Chart
              chartType="Gantt"
              width="fit-parent"
              height="4rem"
              data={getDataSingleLine()}
              options={getOptions("optionsSingleLineScenario")}
            />
          </span>
          <span className={classes.timelineAllElements}>
            <Chart
              chartType="Gantt"
              width="fit-parent"
              data={getDataAllElements()}
              options={getOptions("options")}
              chartEvents={[
                {
                  eventName: "ready",
                  callback: ({ chartWrapper, google }) => {
                    const chart = chartWrapper;
                    google.visualization.events.addListener(
                      chart,
                      "onmouseover",
                      (e) => {
                        const { row, column } = e;
                        console.warn("MOUSE OVER ", { row, column });
                      }
                    );
                    google.visualization.events.addListener(
                      chart,
                      "select",
                      () => {
                        const [rowArray] = chart.getChart().getSelection();
                        if (rowArray) {
                          editElement(chart.getDataTable().Wf[rowArray.row]);
                        }
                      }
                    );
                  },
                },
              ]}
            />
          </span>
        </div>
      )}
    </div>
  );
};
TimelineGantt.defaultProps = {
  elementsSent: [],
};
TimelineGantt.propTypes = {
  elementsSent: PropTypes.array.isRequired,
  setShowElementModal: PropTypes.func.isRequired,
  setElementTimes: PropTypes.func.isRequired,
  elementTimes: PropTypes.object.isRequired,
  setElementsSent: PropTypes.func.isRequired,
  setIsExpandedRight: PropTypes.func.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
};

export default TimelineGantt;
