import { toast } from "react-toastify";

export function addToast(type, message, autoClose) {
  const conf = {
    position: "top-center",
    autoClose: autoClose !== undefined ? autoClose : 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    icon: true,
  };

  switch (type) {
    case "success":
      toast.success(message, conf);
      break;
    case "info":
      toast.info(message, conf);
      break;
    case "error":
      toast.error(message, conf);
      break;
    case "warn":
      toast.warn(message, conf);
      break;
    default:
      break;
  }
}
