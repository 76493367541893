import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";
import { useField, useFormikContext } from "formik";
import { getLocationAddress } from "../../../services/helpers";
import { createUseStyles } from "react-jss";
import { LabelRequiredAndErrorMessage } from "./helpers";
import { addToast } from "../../../utils/helpers/alerts";

const useStyles = createUseStyles({
  label: {
    fontSize: "x-small",
    marginBottom: "0",
  },
  formField: (props) => ({
    fontSize: "x-small",
    minHeight: "2.4rem",
    "&:disabled": {
      background: props.noValueInField ? "red" : "lightgray",
    },
  }),
});

const TextTypeFieldFormik = (props) => {
  const [field, meta, helpers] = useField(props);
  const { name, type, placeholder, disabled, fieldLabel, rows, width, required } =
    props;

  const [noValueInField, setNoValueInField] = useState(false);

  const classes = useStyles({ noValueInField });

  const { setValue } = helpers;

  const updateState = (input) => {
    setValue(input);
  };

  const { values } = useFormikContext();
  const { coordinates } = values;

  useEffect(() => {
    if (name === "locationCoordinates") {
      setValue(`${values.coordinates}`);
      if (!values.coordinates[0] && !values.coordinates[1]) {
        setNoValueInField(true);
        addToast(
          "error",
          "Error in requesting coordinates and address! Please choose a different location on map",
          2000
        );
      } else {
        setNoValueInField(false);
      }
    }
    if (name === "locationAddress") {
      if (typeof values.coordinates === "string") {
        const coords = values.coordinates.split(",");
        getLocationAddress([Number(coords[0]), Number(coords[1])]).then(
          (result) => {
            setValue(result);
          }
        );
      } else {
        getLocationAddress(values.coordinates).then((result) => {
          setValue(result);
        });
      }
      if (!values.coordinates[0] && !values.coordinates[1]) {
        setNoValueInField(true);
      } else {
        setNoValueInField(false);
      }
    }
  }, [coordinates ? coordinates : null]);

  return (
    <React.Fragment key={name}>
      <BootstrapForm.Group controlId={`formBasic${name}`}>
        <BootstrapForm.Label className={classes.label}>
          {fieldLabel}
        </BootstrapForm.Label>
        {type !== "textarea" && (
          <BootstrapForm.Control
            className={classes.formField}
            size="sm"
            type={type}
            placeholder={placeholder}
            value={field.value}
            onChange={(e) => updateState(e.target.value)}
            disabled={disabled}
          />
        )}
        {type === "textarea" && (
          <BootstrapForm.Control
            className={classes.formField}
            as="textarea"
            placeholder={placeholder}
            value={field.value}
            onChange={(e) => updateState(e.target.value)}
            disabled={disabled}
            rows={rows ? rows : 2}
            style={{ width }}
          />
        )}
        <LabelRequiredAndErrorMessage meta={meta} required={required} />
      </BootstrapForm.Group>
    </React.Fragment>
  );
};

TextTypeFieldFormik.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  rows: PropTypes.number.isRequired,
  width: PropTypes.number,
};

TextTypeFieldFormik.defaultProps = {
  type: "",
  name: "objectName",
  fieldLabel: "",
  required: false,
  placeholder: "",
  disabled: false,
  rows: 3,
};

export default TextTypeFieldFormik;
