const commentFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "Comment",
      description: "",
      name: "comment",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Title",
            name: "title",
            placeholder: "Comment title",
            required: true,
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Comment",
            name: "text",
            placeholder: "Details of this comment",
            rows: 4,
            width: 450,
          },
        ],
      },
    },
  ],
};

const commentFormFields = () => {
  return commentFormFieldsInitial;
};
export default commentFormFields;
