import { DateTime, Interval } from "luxon";
import { sendElementToKafkaService } from "../../services";
import { SEND_TO_KAFKA } from "../../utils/properties";

export function createDateRange(script) {
  let minDate = DateTime.fromISO("2050-10-22T21:38:00");
  let maxDate = DateTime.fromISO("2000-10-22T21:38:00");

  script.forEach((element) => {
    const elemMin = DateTime.fromISO(element.timeOccurred);
    const elemMax = DateTime.fromISO(element.timeEnded);
    minDate = minDate > elemMin ? elemMin : minDate;
    maxDate = maxDate < elemMax ? elemMax : maxDate;
  });
  return {
    minDate: minDate.valueOf(),
    maxDate: maxDate.valueOf(),
  };
}

export function findDatesDiff(date1, date2) {
  const interval = Interval.fromDateTimes(date2, date1);
  return interval.length("hours");
}

export function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000;
}

export function sendElementTokafka({
  systemTime,
  setElementTimes,
  elementTimes,
  elementsSent,
  setElementsSent,
}) {
  let elementsSentTemp = [];
  if (elementTimes[`${systemTime.toISOString().substr(0, 16)}`]) {
    if (elementTimes[`${systemTime.toISOString().substr(0, 16)}`].length) {
      elementTimes[`${systemTime.toISOString().substr(0, 16)}`].forEach(
        (el) => {
          if (SEND_TO_KAFKA) {
            sendElementToKafkaService(el);
          }

          elementsSentTemp.push(el);
        }
      );
      setElementsSent([...elementsSent, ...elementsSentTemp]);
      const elementTimesTemp = { ...elementTimes };
      delete elementTimesTemp[`${systemTime.toISOString().substr(0, 16)}`];
      setElementTimes({ ...elementTimesTemp });
    }
  }
}
