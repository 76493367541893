import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import BootstrapForm from "react-bootstrap/Form";
import DateTimePicker from "react-datetime-picker/dist/entry.nostyle";
import { addToast } from "../../utils/helpers/alerts";
import { createUseStyles } from "react-jss";
import { variables, myButton } from "../../jssVariables";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../../utils/helpers/storageHelpers";
import { DateTime } from "luxon";

const useStyles = createUseStyles({
  clock: {
    display: "grid",
    gridTemplateColumns: "repeat(4, max-content)",
    alignItems: "center",
    gridGap: ".5rem",
  },
  timeAndGmtText: {},
  actionBtn: (props) => ({
    ...myButton,
    background: props.hasStarted ? "gray" : "none",
    color: props.hasStarted
      ? variables.colors.basicLight
      : variables.colors.basicBlue,
    "&:disabled": {
      background: "rgba(0,0,255,.4)",
      borderColor: "rgba(0,0,255,.4)",
      cursor: "no-drop",
    },
  }),
  actionBtnPlay: {
    background: "green",
    color: variables.colors.basicLight,
  },
  actionBtnReset: {
    color: "red",
    borderColor: "red",
  },
  dateTimePickerContainer: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
  },
  clockModal: {
    marginTop: "2rem",
  },
});

export const ChangeTimeModal = ({
  systemTime,
  setEditTime,
  hasStarted,
  isPaused,
}) => {

  const classes = useStyles({ hasStarted });

  const [showModal, setShowModal] = useState(false);

  const [dateAndTimeTemp, setDateAndTimeTemp] = useState(systemTime);

  return (
    <React.Fragment>
      <button
        className={classes.actionBtn}
        onClick={() => setShowModal(true)}
        disabled={hasStarted && !isPaused}
      >
        EDIT
      </button>
      {/* <ClockButton
        hasManualTime={hasManualTime}
        setManualDatetime={setManualDatetime}
        setDateAndTimeTemp={setDateAndTimeTemp}
        closeModal={closeModal}
      /> */}

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        size="md"
        className={classes.clockModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Edit date and time"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BootstrapForm.Group controlId={"formBasicSystemDateAndTime"}>
            <BootstrapForm.Label>{"Select date and time"}</BootstrapForm.Label>
            <div className={classes.dateTimePickerContainer}>
              <DateTimePicker
                value={dateAndTimeTemp}
                onChange={(e) => {
                  setDateAndTimeTemp(e);
                }}
              />
              {/* <ClockButton
                hasManualTime={hasManualTime}
                setManualDatetime={setManualDatetime}
                setDateAndTimeTemp={setDateAndTimeTemp}
                closeModal={closeModal}
              /> */}
            </div>
          </BootstrapForm.Group>
          <br />
        </Modal.Body>
        <Modal.Footer>
          <button
            className={classes.actionBtn}
            onClick={() => {
              setEditTime(dateAndTimeTemp);
              setShowModal(false);
            }}
          >
            CONFIRM{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
ChangeTimeModal.propTypes = {
  systemTime: PropTypes.object.isRequired,
  setEditTime: PropTypes.func.isRequired,
  hasStarted: PropTypes.func.isRequired,
  isPaused: PropTypes.func.isRequired,
};
