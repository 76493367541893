import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  errorMessage: {
    display: "grid",
    justifyItems: "end",
    color: "red",
    fontSize: "x-small",
  },
  requiredLabel: {
    display: "grid",
    justifyItems: "end",
    fontSize: "x-small",
  },
});

export const LabelRequiredAndErrorMessage = (props) => {
  const { meta, required } = props;

  const classes = useStyles();
  if (meta.touched && meta.error) {
    return (
      <React.Fragment>
        <div className={classes.errorMessage}>{meta.error}</div>
      </React.Fragment>
    );
  } else if (required) {
    return <div className={classes.requiredLabel}>*Required</div>;
  }
  return null;
};

LabelRequiredAndErrorMessage.propTypes = {
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool.isRequired,
};

LabelRequiredAndErrorMessage.defaultProps = {
  meta: {},
  required: false,
};
