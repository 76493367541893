const emptyOrganization = {
  name: "",
  levelOfCommand: {
    value: "",
    label: "",
  },
  country: {
    value: "",
    label: "",
  },
  acronym: "",
};

export default emptyOrganization;
