const emptyParticipant = {
  name: "",

  levelOfCommand: {
    value: "",
    label: "",
  },
  organization: {
    value: "",
    label: "",
    acronym: {
      value: "",
      label: "",
    },
    country: {
      value: "",
      label: "",
    },
    levelOfCommand: {
      value: "",
      label: "",
    },
  },
};

export default emptyParticipant;
