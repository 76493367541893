import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { myButton, variables } from "../../../jssVariables";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { saveKPIThunk } from "../../../redux/kpisSlice";
import { deleteLessonLearnedThunk, saveLessonLearnedThunk } from "../../../redux/lessonsLearnedSlice";
import { deleteCommentThunk, saveCommentThunk } from "../../../redux/commentSlice";
import { Modal } from "react-bootstrap";
import closeIconX from "../../../assets/icons/closeIconX.svg";
import PropTypes from "prop-types";
import { createFormFieldsSchema } from "../../../components/AddElement/helpers/createFormFieldsSchema";
import { Form, Formik } from "formik";
import { createValidationSchema } from "../../../utils/helpers/validation-helpers";
import CreateFormField from "../../../utils/helpers/create-form-field";

const kpisEvalStyles = createUseStyles({
  evaluateScenarioInTimeline: {
    padding: "1rem",
  },
  table: {
    width: "100%",
  },
  row: {
    verticalAlign: "top",
  },
  kpi: {
    display: "grid",
    gridTemplateColumns: "repeat(5, max-content)",
    gridGap: "1rem",
  },
  evaluationNumber: {
    width: "3rem",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
});
export const KpisEval = () => {
  const classes = kpisEvalStyles();
  const { t } = useTranslation();
  const { kpis } = useSelector((state) => state);
  const [kpiList, setKpiList] = useState(kpis);
  const { accessType } = useSelector((state) => state.scenario);
  const noEditAccess = ["READ_ACCESS"].includes(accessType);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [editKpi, setEditKpi] = useState({});

  useEffect(() => {
      setKpiList(kpis);
    },
    [kpis]);

  function updateKpi(data, fieldType) {
    let kpiTemp = _.cloneDeep(editKpi);
    switch (fieldType) {
      case "achieved":
        kpiTemp.achieved = data;
        setEditKpi(kpiTemp);
        break;
      case "comment":
        kpiTemp.comment = data;
        setEditKpi(kpiTemp);
        break;

      default:
        break;
    }
  }
  function saveKPIToDB(kpi) {
    const kpiTemp = _.cloneDeep(kpi);
    if (typeof kpiTemp.type === 'object') {
      kpiTemp.type = kpiTemp.type.value;
    }
    dispatch(saveKPIThunk(kpiTemp)).then(
      setShowModal(false),
      setEditKpi({}),
    );
  }
  return (
    <div>
      {showModal &&
        <KpiEvalModal
          editKpi={editKpi}
          setEditKpi={setEditKpi}
          showModal={showModal}
          setShowModal={setShowModal}
          updateKpi={updateKpi}
          saveKPIToDB={saveKPIToDB}
          noEditAccess={noEditAccess}
        />
      }
    <div className={classes.evaluateScenarioInTimeline}>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.column}>#</th>
            <th className={classes.column}>{"KPI"}</th>
            <th className={classes.column}>{"Metric Type"}</th>
            <th className={classes.column}>{"Start"}</th>
            <th className={classes.column}>{"Target"}</th>
            <th className={classes.column}>{"Achieved"}</th>
            <th className={classes.column}>{"Comment"}</th>
            <th className={classes.columnBtns}></th>
          </tr>
        </thead>
        <tbody>
          {kpiList &&
            kpiList.map((kpi, idx) => (
              <tr key={idx} className={classes.row}>
                <td>{idx + 1}</td>
                <td>{kpi.name}</td>
                <td>{kpi.type.label}</td>
                <td>{kpi.start}</td>
                <td>{kpi.target}</td>
                <td>
                  <input
                    className={classes.evaluationNumber}
                    type="number"
                    id="achieved"
                    name="achieved"
                    value={kpi.achieved}
                    disabled="true"
                  ></input>
                </td>
                <td>
                  <textarea
                    id="comment"
                    name="comment"
                    value={kpi.comment}
                    rows="2"
                    cols="40"
                    disabled="true"
                  ></textarea>
                </td>
                <td>
                  <button
                    className={classes.actionBtn}
                    onClick={() => {
                      setEditKpi(kpi);
                      setShowModal(true);
                    }}
                    disabled={noEditAccess}
                  >
                    {t("global.editLabel")}
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
    </div>
  );
};
KpisEval.defaultProps = {};
KpisEval.propTypes = {};

const kpiEvalModalStyles = createUseStyles({
  evaluateScenarioInTimeline: {
    padding: "1rem",
  },
  table: {
    width: "100%",
  },
  row: {
    verticalAlign: "top",
  },
  kpi: {
    display: "grid",
    gridTemplateColumns: "repeat(5, max-content)",
    gridGap: "1rem",
  },
  evaluationNumber: {
    width: "3rem",
  },
  columnBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: ".5rem",
    width: "100%",
    justifyContent: "end",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    alignContent: "top",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .9)",
    zIndex: 1,
  },
  modalContainer: {
    marginTop: "3rem",
    background: "white",
    minWidth: "60rem",
    borderRadius: ".5rem",
  },
  modal: {
    position: "absolute",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    background: "white",
    borderRadius: ".5rem",
    zIndex: 2,
    padding: "1rem",
    top: "5rem",
    width: "max-content",
  },
  headerContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
    fontFamily: variables.fontHeaderFamily,
  },
  header: {
    display: "grid",
    color: variables.colors.basicDark,
    gridTemplateColumns: "1fr max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
  },
  icon: {
    width: "1.5rem",
    cursor: "pointer",
  },
  actionBtnContainer: {
    width: "100%",
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "1fr auto",
  },
  form: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "1.5rem",
  },
  formFields: {
    display: "grid",
    gridTemplateColumns: "14rem repeat(3, 10rem)",
    gridGap: "1rem",
  },
});
export const KpiEvalModal = (props) => {
  const classes = kpiEvalModalStyles();
  const { t } = useTranslation();
  const { editKpi, setEditKpi, showModal, setShowModal, updateKpi, saveKPIToDB, noEditAccess } = props;
  const formFieldsSchema = createFormFieldsSchema({
      elementType: "KPI_EVALUATION",
      startKPI: editKpi.start,
      targetKPI: editKpi.target,
    });
  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContainer}>
        <div className={classes.modal}>
          <div className={classes.headerContainer}>
            <div className={classes.header}>
              <h4>Add KPI Evaluation</h4>
              <img
                src={closeIconX}
                alt=""
                className={`${classes.icon} ${classes.iconClose}`}
                onClick={() => {
                  setEditKpi({});
                  setShowModal(false);
                }}
              />
            </div>
          </div>
          <div className={classes.evaluateScenarioInTimeline}>
            <Formik
              initialValues={{
                name: editKpi.name,
                type: [editKpi.type],
                start: editKpi.start,
                target: editKpi.target,
                achieved: editKpi.achieved ? editKpi.achieved : "",
                comment: editKpi.comment ? editKpi.comment : "",
              }}
              validationSchema={createValidationSchema({
                formFieldsSchema,
              })}
              onSubmit={(values) => {
                const tempEditKpi = _.cloneDeep(editKpi);
                tempEditKpi.achieved = values.achieved;
                tempEditKpi.comment = values.comment;
                saveKPIToDB(tempEditKpi);
              }}
            >
              <Form className={classes.form}>
                  <span className={classes.formFields}>
                    {formFieldsSchema &&
                      formFieldsSchema.value[0].data.field.map((field, i) => (
                        <CreateFormField
                          key={i}
                          field={{ ...field }}
                          index={i}
                          hideRequiredText={true}
                        />
                      ))}
                  </span>
                <span className={classes.actionBtnContainer}>
                    <button type="submit"
                            className={classes.actionBtn}
                            disabled={noEditAccess}
                    >
                      {t("save")}
                    </button>
                  </span>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
KpiEvalModal.defaultProps = {};
KpiEvalModal.propTypes = {
  editKpi: PropTypes.object.isRequired,
  setEditKpi: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  updateKpi: PropTypes.func.isRequired,
  saveKPIToDB: PropTypes.func.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
};

const lessonsLearnedStyles = createUseStyles({
  evaluateScenarioInTimeline: {
    padding: "1rem",
  },
  table: {
    width: "100%",
    borderSpacing: "1rem",
    borderCollapse: "unset",
  },
  row: {
    verticalAlign: "top",
  },
  title: {
    width: "20rem",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
    gridGap: "2rem",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  columnBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: ".5rem",
    width: "100%",
    justifyContent: "end",
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
export const LessonsLearned = () => {
  const classes = lessonsLearnedStyles();
  const { t } = useTranslation();
  const { lessonsLearned } = useSelector((state) => state);
  const [lessonLearnedList, setLessonLearnedList] = useState(lessonsLearned);
  const { accessType } = useSelector((state) => state.scenario);
  const noEditAccess = ["READ_ACCESS"].includes(accessType);
  const { id } = useSelector((state) => state.scenario);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editLesson, setEditLesson] = useState({});
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  useEffect(() => {
    setLessonLearnedList(lessonsLearned);
    },
    [lessonsLearned]);

  function updateLesson(data, fieldType) {
    let lessonTemp = _.cloneDeep(editLesson);
    switch (fieldType) {
      case "title":
        lessonTemp.title = data;
        setEditLesson(lessonTemp);
        break;
      case "text":
        lessonTemp.text = data;
        setEditLesson(lessonTemp);
        break;

      default:
        break;
    }
  }
  function saveLessonLearnedToDB(lesson) {
    dispatch(saveLessonLearnedThunk(lesson)).then(
      setShowAddEditModal(false),
      setEditLesson({}),
    );
  }
  function deleteLessonLearnedFromDB(item) {
    dispatch(deleteLessonLearnedThunk(item)).then(
      setShowDeleteModal(false),
      setEditLesson({}),
    );
  }
  return (
    <div className={classes.evaluateScenarioInTimeline}>
      {showDeleteModal && (
        <DeleteItemModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          item={editLesson}
          setItem={setEditLesson}
          deleteItemFromDB={deleteLessonLearnedFromDB}
          type="Lesson Learned"
        />
      )}
      {showAddEditModal && (
        <AddEditItemModal
          editItem={editLesson}
          setEditItem={setEditLesson}
          showModal={showAddEditModal}
          setShowModal={setShowAddEditModal}
          updateItem={updateLesson}
          saveItemToDB={saveLessonLearnedToDB}
          noEditAccess={noEditAccess}
          type="Lesson Learned"
        />
      )}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.column}>#</th>
            <th className={classes.column}>{"Title"}</th>
            <th className={classes.column}>{"Lesson learned"}</th>
            <th className={classes.columnBtns}></th>
          </tr>
        </thead>
        <tbody>
          {lessonLearnedList &&
            lessonLearnedList.map((lesson, idx) => (
              <tr key={idx} className={classes.row}>
                <td className={classes.leftCell}>{idx + 1}</td>

                <td>
                  <input
                    className={classes.title}
                    type="text"
                    id="title"
                    name="title"
                    value={lesson.title}
                    disabled="true"
                  ></input>
                </td>
                <td>
                  <textarea
                    id="text"
                    name="text"
                    value={lesson.text}
                    rows="4"
                    cols="60"
                    disabled="true"
                  ></textarea>
                </td>
                <td>
                  <div className={classes.columnBtnsContainer}>
                    <button
                      className={classes.actionBtn}
                      onClick={() => {
                        setEditLesson(lesson);
                        setShowAddEditModal(true);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.editLabel")}
                    </button>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEditLesson(lesson);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.deleteLabel")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className={classes.actionBtnPanel}>
        <button
          className={classes.actionBtn}
          onClick={() => {
            setEditLesson({ title: "", text: "", scenarioId: id });
            setShowAddEditModal(true);
          }}
          disabled={noEditAccess}
        >
          {t("global.addLessonLearned")}
        </button>
      </div>
    </div>
  );
};
LessonsLearned.defaultProps = {};
LessonsLearned.propTypes = {};

const commentsStyles = createUseStyles({
  evaluateScenarioInTimeline: {
    padding: "1rem",
  },
  table: {
    width: "100%",
    borderSpacing: "1rem",
    borderCollapse: "unset",
  },
  row: {
    verticalAlign: "top",
  },
  title: {
    width: "20rem",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
    gridGap: "2rem",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  columnBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: ".5rem",
    width: "100%",
    justifyContent: "end",
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
export const Comments = () => {
  const classes = commentsStyles();
  const { t } = useTranslation();
  const { comments } = useSelector((state) => state);
  const [commentList, setCommentList] = useState(comments);
  const { accessType } = useSelector((state) => state.scenario);
  const noEditAccess = ["READ_ACCESS"].includes(accessType);
  const { id } = useSelector((state) => state.scenario);
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [editComment, setEditComment] = useState({});
  const [showAddEditModal, setShowAddEditModal] = useState(false);

  useEffect(() => {
    setCommentList(comments);
    },
    [comments]);

  function updateComment(data, fieldType) {
    let commentTemp = _.cloneDeep(editComment);
    switch (fieldType) {
      case "title":
        commentTemp.title = data;
        setEditComment(commentTemp);
        break;
      case "text":
        commentTemp.text = data;
        setEditComment(commentTemp);
        break;
      default:
        break;
    }
  }
  function saveCommentToDB(comment) {
    dispatch(saveCommentThunk(comment)).then(
      setShowAddEditModal(false),
      setEditComment({}),
    );
  }
  function deleteCommentFromDB(item) {
    dispatch(deleteCommentThunk(item)).then(
      setShowDeleteModal(false),
      setEditComment({}),
    );
  }
  return (
    <div className={classes.evaluateScenarioInTimeline}>
      {showDeleteModal && (
        <DeleteItemModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          item={editComment}
          setItem={setEditComment}
          deleteItemFromDB={deleteCommentFromDB}
          type="Comment"
        />
      )}
      {showAddEditModal && (
        <AddEditItemModal
          editItem={editComment}
          setEditItem={setEditComment}
          showModal={showAddEditModal}
          setShowModal={setShowAddEditModal}
          updateItem={updateComment}
          saveItemToDB={saveCommentToDB}
          noEditAccess={noEditAccess}
          type="Comment"
        />
      )}
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.column}>#</th>
            <th className={classes.column}>{"Title"}</th>
            <th className={classes.column}>{"Comment"}</th>
            <th className={classes.columnBtns}></th>
          </tr>
        </thead>
        <tbody>
          {commentList &&
            commentList.map((comment, idx) => (
              <tr key={idx} className={classes.row}>
                <td className={classes.leftCell}>{idx + 1}</td>

                <td>
                  <input
                    className={classes.title}
                    type="text"
                    id="title"
                    name="title"
                    value={comment.title}
                    disabled="true"
                  ></input>
                </td>
                <td>
                  <textarea
                    id="text"
                    name="text"
                    value={comment.text}
                    rows="4"
                    cols="60"
                    disabled="true"
                  ></textarea>
                </td>
                <td>
                  <div className={classes.columnBtnsContainer}>
                    <button
                      className={classes.actionBtn}
                      onClick={() => {
                        setEditComment(comment);
                        setShowAddEditModal(true);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.editLabel")}
                    </button>
                    <button
                      className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEditComment(comment);
                      }}
                      disabled={noEditAccess}
                    >
                      {t("global.deleteLabel")}
                    </button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <div className={classes.actionBtnPanel}>
        <button
          className={classes.actionBtn}
          onClick={() => {
            setEditComment({ title: "", text: "", scenarioId: id });
            setShowAddEditModal(true);
          }}
          disabled={noEditAccess}
        >
          {t("global.addComment")}
        </button>
      </div>
    </div>
  );
};
Comments.defaultProps = {};
Comments.propTypes = {};

const deleteItemModalStyles = createUseStyles({
  actionBtn: {
    ...myButton,
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
  modalDelete: {
    marginTop: "3rem",
  },
  closeIconX: {
    width: "3rem",
    height: "2rem",
    cursor: "pointer",
  },
});
export const DeleteItemModal = (props) => {
  const classes = deleteItemModalStyles();
  const { t } = useTranslation();
  const { showModal, setShowModal, item, setItem, deleteItemFromDB, type } = props;

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        animation={false}
        size="lg"
        className={classes.modalDelete}
      >
        <Modal.Header>
          <Modal.Title>{"Delete scenario"}</Modal.Title>
          <img
            src={closeIconX}
            alt=""
            onClick={() => {
              setItem({});
              setShowModal(false);
            }}
            className={classes.closeIconX}
          />
        </Modal.Header>
        <Modal.Body>
          {`Delete the ${type}: ${item.title}?`}
        </Modal.Body>
        <Modal.Footer>
          <button
            className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
            onClick={() => deleteItemFromDB(item)}
          >
            {t("global.confirmLabel")}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
DeleteItemModal.defaultProps = {};
DeleteItemModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  setItem: PropTypes.func.isRequired,
  deleteItemFromDB: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const AddEditItemModalStyles = createUseStyles({
  evaluateScenarioInTimeline: {
    padding: "1rem",
  },
  table: {
    width: "100%",
  },
  row: {
    verticalAlign: "top",
  },
  kpi: {
    display: "grid",
    gridTemplateColumns: "repeat(5, max-content)",
    gridGap: "1rem",
  },
  evaluationNumber: {
    width: "3rem",
  },
  columnBtnsContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    gridGap: ".5rem",
    width: "100%",
    justifyContent: "end",
  },
  actionBtnPanel: {
    display: "grid",
    justifyItems: "end",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    alignContent: "top",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .9)",
    zIndex: 1,
  },
  modalContainer: {
    marginTop: "3rem",
    background: "white",
    minWidth: "60rem",
    borderRadius: ".5rem",
  },
  modal: {
    position: "absolute",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    background: "white",
    borderRadius: ".5rem",
    zIndex: 2,
    padding: "1rem",
    top: "5rem",
    width: "max-content",
  },
  headerContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
    fontFamily: variables.fontHeaderFamily,
  },
  header: {
    display: "grid",
    color: variables.colors.basicDark,
    gridTemplateColumns: "1fr max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
  },
  icon: {
    width: "1.5rem",
    cursor: "pointer",
  },
  actionBtnContainer: {
    width: "100%",
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "1fr auto",
  },
  form: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "1.5rem",
  },
  formFields: {
    display: "grid",
    gridTemplateColumns: "14rem repeat(3, 10rem)",
    gridGap: "1rem",
  },
});
export const AddEditItemModal = (props) => {
  const classes = AddEditItemModalStyles();
  const { t } = useTranslation();
  const { editItem, setEditItem, showModal, setShowModal, updateItem, saveItemToDB, noEditAccess, type } = props;
  const formFieldsSchema = (type === "Lesson Learned")
    ? createFormFieldsSchema({
      elementType: "LESSON_LEARNED"
    })
    : (type === "Comment")
    ? createFormFieldsSchema({
      elementType: "COMMENT"
    })
    : createFormFieldsSchema({
      elementType: ""
    });

  return (
    <div className={classes.modalOverlay}>
      <div className={classes.modalContainer}>
        <div className={classes.modal}>
          <div className={classes.headerContainer}>
            <div className={classes.header}>
              <h4>Add {type} Evaluation</h4>
              <img
                src={closeIconX}
                alt=""
                className={`${classes.icon} ${classes.iconClose}`}
                onClick={() => {
                  setEditItem({});
                  setShowModal(false);
                }}
              />
            </div>
          </div>
          <div className={classes.evaluateScenarioInTimeline}>
            <Formik
              initialValues={{
                title: editItem.title,
                text: editItem.text,
              }}
              validationSchema={createValidationSchema({
                formFieldsSchema,
              })}
              onSubmit={(values) => {
                const tempEditItem = _.cloneDeep(editItem);
                tempEditItem.title = values.title;
                tempEditItem.text = values.text;
                saveItemToDB(tempEditItem);
              }}
            >
              <Form className={classes.form}>
                  <span className={classes.formFields}>
                    {formFieldsSchema &&
                      formFieldsSchema.value[0].data.field.map((field, i) => (
                        <CreateFormField
                          key={i}
                          field={{ ...field }}
                          index={i}
                          hideRequiredText={true}
                        />
                      ))}
                  </span>
                  <span className={classes.actionBtnContainer}>
                    <button type="submit"
                      className={classes.actionBtn}
                      disabled={noEditAccess}
                    >
                      {t("save")}
                    </button>
                  </span>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};
AddEditItemModal.defaultProps = {};
AddEditItemModal.propTypes = {
  editItem: PropTypes.object.isRequired,
  setEditItem: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  saveItemToDB: PropTypes.func.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};
