const commandLevelsList = {
  list: [
    {
      value: "strategic",
      label: "Strategic",
    },
    {
      value: "tactical",
      label: "Tactical",
    },
    {
      value: "operational",
      label: "Operational",
    },
  ],
};

export default commandLevelsList;
