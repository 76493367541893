import axios from "axios";
import properties from "../utils/properties";
import { addToast } from "../utils/helpers/alerts";
import { getCountryCode } from "../utils/constants/data/countriesEUwithAlpha2code";

// ---------- GET LOCATION ADDRESS ----------
export function getLocationAddress(coords) {
  return axios
    .get(properties.googleMapsApi.url.getAddressFromCoordinates, {
      params: {
        latlng: `${coords[0]},${coords[1]}`,
        key: properties.googleMapsApi.key,
      },
    })
    .then((response) => {
      return response.data.results[0].formatted_address;
    })
    .catch(function (error) {
      if (error.message.includes("404")) {
        addToast("error", "Error 404", false);
      } else if (error.message.includes("Network Error")) {
        addToast(
          "error",
          "Network Error on retrieving Location Address",
          false
        );
      }
      return "Not available address";
    });
}

// ---------- CREATE FULL PARTICIPANT NAME ----------
export function createFullParticipantName(participant) {
  return `${getCountryCode(
    participant.organization.country
  )}_${participant.organization.acronym.toUpperCase()}_${participant.level_of_command
    .substring(0, 3)
    .toUpperCase()}_${participant.name}`;
}
