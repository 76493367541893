/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import GoogleMapReact from "google-map-react";
import properties from "./../../utils/properties";
import { createUseStyles } from "react-jss";
import { myButton } from "../../jssVariables";
import { getElementIcon, PoPup, LegendContainer } from "./helpers";
import markerDefault from "../../assets/icons/markerDefault.svg";

const useStyles = createUseStyles({
  mapContainer: () => ({
    height: "max-content",
  }),
  map: (props) => ({
    height: props.height,
    width: "auto",
    borderRadius: ".5rem ",
  }),
  mapControlpanel: (props) => ({
    position: "absolute",
    bottom: props.legendExpanded ? "4rem" : "1.5rem",
    left: ".3rem",
  }),
  expandedBtn: {
    ...myButton,
    width: "4.5rem",
    fontSize: "small",
  },
  addressInputContainer: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "1rem",
    marginBottom: "1rem",
    alignItems: "center",
  },
  addressInputField: {
    borderRadius: ".2rem",
    border: "1px solid #ced4da",
    padding: " 0.25rem 0.5rem",
    width: "100%",
  },
  addressInputBtn: {
    ...myButton,
    height: "max-content",
  },
  markerDefault: {
    height: "2rem",
  },
});
const Map = (props) => {
  const { location, height, script } = props;
  const [showPopUp, setShowPopUp] = useState(false);
  const [popUpInfo, setPopUpInfo] = useState({});
  const [legendExpanded, setLegendExpanded] = useState(false);
  const [elementsToShowType, setElementsToShowType] = useState([
    "EVENT",
    "MESSAGE",
    "SOCIAL_MEDIA",
    "DATA_SOURCE",
  ]);

  const classes = useStyles({ height, legendExpanded });

  const mapProps = {
    center: location,
    zoom: 13,
    draggable: true,
    lat: location[0],
    lng: location[1],
  };

  return (
    <div className={classes.mapContainer}>
      <div className={classes.map}>
        <GoogleMapReact
          key={properties.googleMapsApi.key}
          bootstrapURLKeys={{ key: properties.googleMapsApi.key }}
          yesIWantToUseGoogleMapApiInternals={true} // remove it, not safe
          center={{ lat: location[0], lng: location[1] }}
          zoom={mapProps.zoom}
          draggable={mapProps.draggable}
        >
          {elementsToShowType &&
            script.map((element, idx) => {
              if (elementsToShowType.includes(element.type)) {
                return (
                  <div
                    key={idx}
                    lat={element.location.coordinates[0]}
                    lng={element.location.coordinates[1]}
                    onClick={() => {
                      setPopUpInfo(element);
                      setShowPopUp(true);
                    }}
                  >
                    <img
                      src={getElementIcon(element)}
                      alt=""
                      className={classes.markerDefault}
                    />
                  </div>
                );
              }
              return null;
            })}
          {showPopUp && (
            <PoPup
              element={popUpInfo}
              setShowPopUp={setShowPopUp}
              lat={popUpInfo.location.coordinates[0]}
              lng={popUpInfo.location.coordinates[1]}
            />
          )}
        </GoogleMapReact>
        <div className={classes.mapControlpanel}>
          <button
            className={classes.expandedBtn}
            onClick={() => setLegendExpanded((e) => !e)}
          >
            {legendExpanded ? "Close" : "Expand"}
          </button>
        </div>
      </div>
      {legendExpanded && (
        <LegendContainer
          elementsToShowType={elementsToShowType}
          setElementsToShowType={setElementsToShowType}
        />
      )}
    </div>
  );
};

Map.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
  height: PropTypes.string.isRequired,
  script: PropTypes.array.isRequired,
};

Map.defaultProps = {
  location: {},
  height: "20rem",
  script: [],
  isScenarioTimelineMap: false,
};

export default Map;
