export const variables = {
  myShadow: "0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)",
  fontFamily: "Roboto sans-serif",
  fontHeaderFamily: "'Nunito', sans-serif",
  fontTextFamily: "'Open Sans', sans-serif",
  breadcrumb: {
    fontSize: "small",
  },
  colors: {
    basicLight: "#ffffff",
    basicDark: "#4e4d4d",
    basicBlue: "#0d6efd",
    lightGray: "rgb(235, 235, 235)",
  },
  form: {
    border: "1px solid #d4d4d4",
  },
  media: {
    smallscreen: "screen and (min-width: 1366px)",
    bigscreen: "screen and (min-width: 1441px)",
  },
};

export const myButton = {
  cursor: "pointer",
  fontSize: "small",
  borderRadius: ".5rem",
  padding: ".1rem .6rem",
  color: variables.colors.basicBlue,
  border: `1px solid ${variables.colors.basicBlue}`,
  background: variables.colors.basicLight,
  textTransform: "uppercase",
};
