import * as Yup from "yup";
import { DateTime } from "luxon";
import { dataSourceFormFieldsInitial } from "../../../utils/constants/forms/data-source-form-fields";

export function initialValidationSchema1(scriptLength) {
  return {
    selectDataSource: Yup.object().required("Required"),
    index: Yup.number()
      .max(scriptLength + 1, `Must be at most ${scriptLength + 1}`)
      .min(1, "Must be at least 1 ")
      .required("Required"),
    timeOccurred: Yup.date().required("Required"),
  };
}

function getEmptyElementValue(valueType) {
  switch (valueType) {
    case "Category":
      return [];
    case "Text":
      return "";
    case "Date":
    case "Time":
      return DateTime.now().toISO();
    case "Quantity":
      return 0;
    case "Numerical":
      return 0.0;

    default:
      return "";
  }
}

function createEventAndMessage(element, scriptLength, coords) {
  return {
    message: element.message,
    sender: (element.sender[0].value !== undefined && element.sender[0].label !== undefined) ? element.sender : [],
    receivers: element.receivers,
    eventCategory: element.eventCategory,
    location: {
      coordinates: element.location.coordinates
        ? element.location.coordinates
        : coords,
      type: element.location.type,
    },
    locationCoordinates:
      element.location.coordinates.length > 0
        ? element.location.coordinates
        : coords,
    coordinates:
      element.location.coordinates.length > 0
        ? element.location.coordinates
        : coords,
    locationAddress: element.locationAddress,
    file: element.file,
    levelOfCommand: element.levelOfCommand,
    socialMediaType: element.socialMediaType,
    timeOccurred: element.timeOccurred ? element.timeOccurred : DateTime.now(),
    timeEnded: element.timeEnded ? element.timeEnded : DateTime.now(),
    index: element.index ? element.index : scriptLength + 1,
  };
}

function createSocialMedia(element, scriptLength, coords) {
  return {
    eventCategory: element.eventCategory,
    sender: element.sender ? element.sender : "",
    message: element.message,
    location: {
      coordinates: element.location.coordinates
        ? element.location.coordinates
        : coords,
      type: element.location.type,
    },
    locationCoordinates:
      element.location.coordinates.length > 0
        ? element.location.coordinates
        : coords,
    coordinates:
      element.location.coordinates.length > 0
        ? element.location.coordinates
        : coords,
    locationAddress: element.locationAddress,
    socialMediaType: (element.socialMediaType[0].value !== "" && element.socialMediaType[0].label !== "") ? element.socialMediaType : [],
    file: element.file,
    timeOccurred: element.timeOccurred ? element.timeOccurred : DateTime.now(),
    index: element.index ? element.index : scriptLength + 1,
  };
}

function createSocialMediaBatch(element, scriptLength) {
  return {
    eventCategory: element.eventCategory,
    file: element.file,
    timeOccurred: element.timeOccurred ? element.timeOccurred : DateTime.now(),
    dataObjectSelection: (element.socialMediaType[0].value !== "" && element.socialMediaType[0].label !== "") ? element.socialMediaType : [],
    index: element.index ? element.index : scriptLength + 1,
  };
}
function createDataSource({ element, dataSourceIndex, index }) {
  let elementTemp = {};
  if (element.id) {
    elementTemp = { ...elementTemp, id: element.id };
  }

  elementTemp = {
    ...elementTemp,
    index,
    dataSource: dataSourceIndex > -1
      ? {
          label: dataSourceFormFieldsInitial.value[dataSourceIndex].label,
          value: dataSourceFormFieldsInitial.value[dataSourceIndex].name,
        }
      : {},
  };

  dataSourceFormFieldsInitial.value[dataSourceIndex].data.field.forEach(
    (field) => {
      elementTemp = {
        ...elementTemp,

        [field.name]: element[field.name]
          ? element[field.name]
          : getEmptyElementValue(field.type),
      };
      if (field.name === "file") {
        elementTemp = { ...elementTemp, file: element.data };
      }
    }
  );
  return elementTemp;
}

export function createInitialValues({
  element,
  scriptLength,
  coords,
  dataSourceIndex,
}) {
  switch (element.type) {
    case "EVENT":
    case "MESSAGE":
      return createEventAndMessage(element, scriptLength, coords);

    case "SOCIAL_MEDIA":
      return createSocialMedia(element, scriptLength, coords);

    case "SOCIAL_MEDIA_BATCH":
      return createSocialMediaBatch(element, scriptLength);

    case "DATA_SOURCE":
      return dataSourceIndex > -1
        ? createDataSource({ element, dataSourceIndex, index: scriptLength })
        : {};

    default:
      break;
  }
  return {};
}
