export const keyPerformanceIndicatorsEvalFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "evalKPI",
      description: "",
      name: "evalKPI",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Key Performance Indicator",
            name: "name",
            disabled: true,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Metric Type",
            name: "type",
            multiSelect: false,
            disabled: true,
            required: true,
            constraint: {
              type: "AllowedTokens",
              value: ["Percentage", "Numeric", "Boolean"],
              minValue: 1,
            },
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Start",
            name: "start",
            disabled: true,
            required: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Target",
            name: "target",
            disabled: true,
            required: true,
          },
          {
            type: "Quantity",
            valueType: "integer",
            description: "",
            label: "Achieved",
            name: "achieved",
            placeholder: "",
            required: true,
            constraint: {
              minValue: 0,
              maxValue: 0,
            },
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Comment",
            name: "comment",
            placeholder: "Details of this KPI evaluation",
            rows: 4,
            width: 450,
          },
        ],
      },
    },
  ],
};

const keyPerformanceIndicatorsEvalFormFields = (start, target) => {
  keyPerformanceIndicatorsEvalFormFieldsInitial.value[0].data.field[4].constraint.minValue =
    start;
  keyPerformanceIndicatorsEvalFormFieldsInitial.value[0].data.field[4].constraint.maxValue =
    target;
  return keyPerformanceIndicatorsEvalFormFieldsInitial;
};
export default keyPerformanceIndicatorsEvalFormFields;
