import React, { useEffect } from "react";
import { createUseStyles } from "react-jss";
import closeIconX from "../../assets/icons/closeIconX.svg";
import { variables, myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { createValidationSchema } from "../../utils/helpers/validation-helpers";
import CreateFormField from "../../utils/helpers/create-form-field";
import { useTranslation } from "react-i18next";
import commandLevelsList from "../../utils/constants/data/command-levels-list";
import { createFormFieldsSchema } from "../AddElement/helpers/createFormFieldsSchema";
import {
  saveOrganizationThunk,
  deleteOrganizationThunk,
} from "../../redux/organizationSlice";

const addEditOrganizationStyles = createUseStyles({
  editorganization: {},
  modalOverlay: {
    position: "absolute",
    display: "grid",
    gridTemplateColumns: "max-content",
    gridTemplateRows: "max-content",
    alignContent: "top",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    background: "rgba(0,0,0, .9)",
    zIndex: 1,
  },
  modal: {
    position: "absolute",
    display: "grid",
    gridTemplateRows: "max-content 1fr",
    background: "white",
    borderRadius: ".5rem",
    zIndex: 2,
    padding: "1rem",
    top: "5rem",
    width: "max-content",
  },
  header: (props) => ({
    display: "grid",
    color: props.onDelete ? "red" : variables.colors.basicDark,
    gridTemplateColumns: "1fr max-content",
    borderBottom: `1px solid ${variables.colors.lightGray}`,
  }),
  icon: {
    width: "1.5rem",
    cursor: "pointer",
  },
  form: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "1.5rem",
  },
  formFields: {
    display: "grid",
    gridTemplateColumns: "14rem repeat(3, 10rem)",
    gridGap: "1rem",
  },
  actionBtnContainer: {
    width: "100%",
    display: "grid",
    justifyItems: "end",
    gridTemplateColumns: "1fr auto",
  },
  actionBtn: {
    ...myButton,
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
});
export const AddEditOrganization = (props) => {
  const {
    organization,
    onDelete,
    showModal,
    setShowModal,
    setOrganization,
    setOnDelete,
  } = props;
  const classes = addEditOrganizationStyles({ onDelete });

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { id: scenarioId, involvedCountries } = useSelector(
    (state) => state.scenario
  );

  const formFieldsSchema = createFormFieldsSchema({
    elementType: "ORGANIZATION",
    commandLevelsList: commandLevelsList.list.map((level) => level.value),
    involvedCountries,
  });

  function saveToDB(values) {
    let organizationTemp = {
      name: values.name,
      country: values.country[0].value,
      levelOfCommand: values.levelOfCommand[0].value,
      acronym: values.acronym,
      scenario_id: scenarioId,
    };
    if (organization.id) {
      organizationTemp = {
        ...organizationTemp,
        id: organization.id,
      };
    }
    dispatch(saveOrganizationThunk(organizationTemp)).then(() => {
      setOrganization({});
      setShowModal(false);
    });
  }

  function deleteFunc() {
    dispatch(
      deleteOrganizationThunk({ ...organization, scenario_id: scenarioId })
    ).then(() => {
      setOrganization({});
      setOnDelete(false);
      setShowModal(false);
    });
  }

  useEffect(() => {
    console.log("organization: ", organization);
  }, [organization]);
  return (
    <div className={classes.editorganization}>
      {showModal > 0 && (
        <React.Fragment>
          <div className={classes.modal}>
            <div className={classes.header}>
              {onDelete ? "Delete" : [organization.id ? "Edit" : "Add"]}{" "}
              Organization
              <img
                src={closeIconX}
                alt=""
                className={classes.icon}
                onClick={() => {
                  setOrganization({});
                  setOnDelete(false);
                  setShowModal(false);
                }}
              />
            </div>

            <div className={classes.content}>
              <Formik
                initialValues={{
                  name: organization.name,
                  country: organization.country ? [organization.country] : [],
                  levelOfCommand: organization.levelOfCommand ? [organization.levelOfCommand] : [],
                  acronym: organization.acronym,
                }}
                validationSchema={createValidationSchema({
                  formFieldsSchema,
                })}
                onSubmit={(values) => {
                  saveToDB(values);

                  /*
                   * setTimeout(() => {
                   *   alert(JSON.stringify(values, null, 2));
                   *   setSubmitting(false);
                   * }, 400);
                   */
                }}
              >
                <Form className={classes.form}>
                  <span className={classes.formFields}>
                    {formFieldsSchema &&
                      formFieldsSchema.value[0].data.field.map((field, i) => (
                        <CreateFormField
                          key={i}
                          field={{ ...field, disabled: onDelete }}
                          index={i}
                          hideRequiredText={true}
                        />
                      ))}
                  </span>
                  <span className={classes.actionBtnContainer}>
                    {onDelete ? (
                      <button
                        type="button"
                        className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                        onClick={() => {
                          deleteFunc();
                        }}
                      >
                        {t("confirm")}
                      </button>
                    ) : (
                      <button type="submit" className={classes.actionBtn}>
                        {t("save")}
                      </button>
                    )}
                  </span>
                </Form>
              </Formik>
            </div>
          </div>
          <div
            className={classes.modalOverlay}
            onClick={() => {
              setOrganization({});
              setShowModal(false);
            }}
          >
            {" "}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
AddEditOrganization.defaultProps = {
  showModal: false,
  organization: {},
  onDelete: false,
};
AddEditOrganization.propTypes = {
  organization: PropTypes.object.isRequired,
  showModal: PropTypes.bool.isRequired,
  onDelete: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  setOrganization: PropTypes.func.isRequired,
  setOnDelete: PropTypes.func.isRequired,
};
