import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import RoutesOfSite from "./routing/RoutesOfSite";
import NavbarTop from "./components/navbar-top/index.js";
import Footer from "./components/footer";
import SidebarLeft from "./components/sidebar/sidebarLeft";
import { ToastContainer } from "react-toastify";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { variables } from "./jssVariables";

import "./App.css";

export const useStyles = createUseStyles({
  mainApp: {
    display: "grid",
    background: variables.colors.basicLight,
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    color: variables.colors.basicDark,
    fontFamily: variables.fontTextFamily,
  },
  toastify: {
    marginTop: "3rem",
  },
  mainContainer: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr",
    width: "100%",
    height: "100%",
    paddingTop: "2.5rem",
    position: "absolute",
  },
  sideBar: {
    display: "grid",
    width: "max-content",
    height: "100%",
  },
  mainScreen: {
    display: "grid",
    gridTemplateRows: "1fr max-content",
    alignContent: "space-between",
    background: variables.colors.lightGray,
  },
});
const App = () => {
  const classes = useStyles();
  const [isExpandedLeft, setIsExpandedLeft] = useState(false);
  const [isExpandedRight, setIsExpandedRight] = useState(false);

  // eslint-disable-next-line no-undef
  console.log("MODE: ", process.env.REACT_APP_MODE || "dev");

  const projName = process.env.REACT_APP_PROJECT_NAME || "default";
  console.log("PR Name: : ", projName);

  const [isAuthenticated, setIAuthenticated] = useState(
    useSelector((state) => state.authentication.isAuthenticated)
  );

  useEffect(() => {
    document.title =
      (projName === "nightingale" || projName === "silvanus" || projName === "co-protect") ? "Scenario Builder" : "PPT tool";
  }, []);

  return (
    <div className={classes.mainApp}>
      <ToastContainer className={classes.toastify} />
      <BrowserRouter>
        <NavbarTop
          setIsExpandedLeft={setIsExpandedLeft}
          setIsExpandedRight={setIsExpandedRight}
        />
        <div className={classes.mainContainer}>
          <div className={classes.sideBar}>
            <SidebarLeft
              isExpanded={isExpandedLeft}
              setIsExpanded={setIsExpandedLeft}
              isAuthenticated={isAuthenticated}
              setIAuthenticated={setIAuthenticated}
            />
          </div>
          <div className={classes.mainScreen}>
            <RoutesOfSite
              setIAuthenticated={setIAuthenticated}
              isExpandedRight={isExpandedRight}
              setIsExpandedRight={setIsExpandedRight}
            />
            <Footer />
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
