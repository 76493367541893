import properties from "./../properties";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { clearScenario } from "../../redux/scenarioSlice";
import { clearElement } from "../../redux/elementSlice";
import {
  clearOrganization,
  clearOrganizationList,
} from "../../redux/organizationSlice";
import {
  clearParticipant,
  clearParticipantList,
} from "../../redux/participantSlice";
import { clearEventCategory } from "../../redux/eventCategorySlice";
import { clearKPIs } from "../../redux/kpisSlice";

export function saveToLocalStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getFromLocalStorage(key, type, value) {
  if (["boolean", "number"].includes(type)) {
    if (type === "boolean") {
      return Boolean(localStorage.getItem(key));
    }
    return Number(localStorage.getItem(key));
  }

  if (localStorage.getItem(key) && localStorage.getItem(key) !== "undefined") {
    if (value) {
      return _.get(JSON.parse(localStorage.getItem(key)), `${value}`);
    }
    return JSON.parse(localStorage.getItem(key));
  }
  return getResultIfEmpty(type);
}

export function clearLocalStorage() {
  return localStorage.clear();
}

export function clearScenarioDataFromLocalStorage() {
  localStorage.removeItem("organizationList");
  localStorage.removeItem("participant");
  localStorage.removeItem("participantList");
  localStorage.removeItem("organization");
  localStorage.removeItem("eventCategoryList");
  localStorage.removeItem("scenario");
  localStorage.removeItem("element");
  localStorage.removeItem("eventCategory");
  localStorage.removeItem("kpis");
}

function getResultIfEmpty(type) {
  switch (type) {
    case "string":
      return "";
    case "object":
      return {};
    case "boolean":
      return false;
    case "array":
      return [];
    case "number":
      return 0;
    default:
      return null;
  }
}

export function clearScenarioDataFromGlobalState() {
  const dispatch = useDispatch();

  dispatch(clearScenario());
  dispatch(clearElement());
  dispatch(clearOrganization());
  dispatch(clearOrganizationList());
  dispatch(clearParticipant());
  dispatch(clearParticipantList());
  dispatch(clearEventCategory());
  dispatch(clearKPIs());
}
