const scenarioEmpty = {
  id: null,
  title: "",
  locationAddress: "",
  territorialCategory: null,
  scenarioGoal: "",
  startTime: null,
  comments: "",
  creationDate: null,
  versionComments: null,
  privateScenario: null,
  author: "",
  readAccess: [],
  fullAccess: [],
  involvedCountries: [],
  location: {
    type: "Point",
    coordinates: [48.20738541556119, 16.380481712037092], //[37.989227674581784, 23.740209782868273],
  },
  locationPolygon: null,
  currentStep: 1,
  trial: "",
  script: [],
};
export default scenarioEmpty;
