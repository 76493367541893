import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import BootstrapForm from "react-bootstrap/Form";
import emptyEventCategory from "../../utils/constants/data/empty-event-category";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import { myButton } from "../../jssVariables";
import PropTypes from "prop-types";
import {
  saveEventCategoryThunk,
  deleteEventCategoryThunk,
} from "../../redux/eventCategorySlice";

const useStyles = createUseStyles({
  eventCategory: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    alignItems: "start",
    gridGap: ".2rem",
  },
  mainPanel: (props) => ({
    display: "grid",
    gridGap: ".8rem",
    border: props.expanded ? "1px solid rgba(78, 77, 77, 0.4)" : "none",
    padding: ".5rem",
    borderRadius: ".5rem",
  }),
  tableColumnLabel: {
    fontSize: "small",
  },
  expandBtn: (props) => ({
    ...myButton,
    fontSize: props.evCatLength === 0 && !props.expanded ? "xx-large" : "small",
    paddingTop: 0,
    alignSelf: !props.expanded ? "center" : "start",
  }),
  addNewBtn: {
    ...myButton,
    fontSize: "small",
    width: "max-content",
    justifySelf: "end",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  controlButtons: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    paddingLeft: "1rem",
    gridGap: ".5rem",
  },
  controlBtn: {
    ...myButton,
    fontSize: "small",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  controlBtnDelete: {
    color: "red",
    borderColor: "red",
  },
  controlBtnCancel: {
    color: "gray",
    borderColor: "gray",
  },
  table: {
    marginBottom: 0,
  },
  evCategoriesPanel: (props) => ({
    display: "grid",
    gridTemplateColumns: props?.evCatLength
      ? `repeat(${props.evCatLength + 1}, max-content)`
      : "max-content",
    gridGap: ".5rem",
    fontSize: "small",
    lineHeight: "1rem",
  }),
  singleEvCategoriesPanel: {
    display: "grid",
    gridTemplateColumns: "1fr max-content",
    gridGap: "1rem",
  },
  colorDiv: {
    height: "1.2rem",
    width: "1.2rem",
    borderRadius: "50%",
  },
  colorInput: {
    height: "rem",
    fontSize: "xx-small",
    width: "2rem",
    padding: ".2rem",
  },
});
// eslint-disable-next-line max-statements
const EventCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { scenarioId, eventCategoryListFromState, accessType } = useSelector(
    (state) => {
      return {
        scenarioId: state.scenario.id,
        eventCategoryListFromState: state.eventCategory.eventCategoryList,
        accessType: state.scenario.accessType,
      };
    }
  );

  const noEditAccess = ["READ_ACCESS"].includes(accessType);

  const [eventCategoryList, setEventCategoryList] = useState([]);

  useEffect(() => {
    setEventCategoryList(
      eventCategoryListFromState ? eventCategoryListFromState : []
    );
  }, [eventCategoryListFromState]);

  const [expanded, setExpanded] = useState(false);
  const classes = useStyles({
    evCatLength: eventCategoryList ? eventCategoryList.length : 0,
    expanded,
  });

  const [, setIsEditing] = useState(false);

  let editFieldIdOpenRef = useRef(false);

  let eventCategoryListSizeOnDBRef = useRef(0);

  function addEmptyEventCategory() {
    if (
      eventCategoryListSizeOnDBRef.current === 0 ||
      (eventCategoryListSizeOnDBRef.current === eventCategoryList.length &&
        eventCategoryIsFilled(eventCategoryList[eventCategoryList.length - 1]))
    ) {
      setEventCategoryList([...eventCategoryList, emptyEventCategory]);
      setIsEditing(true);
    }
  }

  function removeEmptyEventCategory(index) {
    if (
      eventCategoryListSizeOnDBRef.current === 0 ||
      (eventCategoryListSizeOnDBRef.current < eventCategoryList.length &&
        index === eventCategoryList.length - 1)
    ) {
      eventCategoryList.pop();
      setEventCategoryList([...eventCategoryList]);
      setIsEditing(false);
    }
  }

  function eventCategoryIsFilled(input) {
    return input.name && input.color;
  }

  function checkIfExists(input, keyName) {
    if (typeof input === "object") {
      return eventCategoryList.find((o) => o[keyName].value === input);
    }
    return eventCategoryList.find((o) => o[keyName] === input);
  }

  function saveEventCategoryToDB(input) {
    if (!input.id) {
      delete input.id;
    }
    dispatch(saveEventCategoryThunk({ ...input, scenarioId }));
    setIsEditing(false);
  }

  return (
    <div className={classes.eventCategory}>
      <button
        className={classes.expandBtn}
        disabled={false}
        onClick={() => setExpanded(!expanded)}
      >
        {expanded
          ? "Compress"
          : [
              eventCategoryList?.length > 0
                ? "Expand"
                : "Start by clicking here!",
            ]}
      </button>
      <div className={classes.mainPanel}>
        {expanded ? (
          <React.Fragment>
            <div className={classes.tableDiv}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classes.tableColumnLabel}>
                      {t("global.eventCategoryLabel")}
                    </th>
                    <th className={classes.tableColumnLabel}>{"Color"}</th>
                    <th className={classes.tableColumnLabel}>{""}</th>
                  </tr>
                </thead>

                <tbody>
                  {typeof eventCategoryList !== "undefined" &&
                    eventCategoryList.map((eventCategory, i) => (
                      <tr key={i}>
                        <FormGroupControlEventCategory
                          i={i}
                          eventCategoryInput={eventCategory}
                          editFieldIdOpenRef={editFieldIdOpenRef}
                          eventCategoryIsFilled={eventCategoryIsFilled}
                          checkIfExists={checkIfExists}
                          saveEventCategoryToDB={saveEventCategoryToDB}
                          removeEmptyEventCategory={removeEmptyEventCategory}
                          setIsEditing={setIsEditing}
                          eventCategoryList={eventCategoryList}
                          expanded={expanded}
                          noEditAccess={noEditAccess}
                        />
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {eventCategoryList?.length < 3 && (
              <button
                className={classes.addNewBtn}
                disabled={noEditAccess}
                onClick={addEmptyEventCategory}
              >
                {t("global.eventCategoryAddNewLabel")}
              </button>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={classes.evCategoriesPanel}>
              {eventCategoryList &&
                eventCategoryList.map((eventCategory, i) => (
                  <div key={i} className={classes.singleEvCategoriesPanel}>
                    <span>{eventCategory.name}</span>

                    <div
                      className={classes.colorDiv}
                      style={{
                        background: eventCategory.color,
                      }}
                    ></div>
                  </div>
                ))}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

const FormGroupControlEventCategory = ({
  i,
  eventCategoryInput,
  editFieldIdOpenRef,
  eventCategoryIsFilled,
  checkIfExists,
  saveEventCategoryToDB,
  removeEmptyEventCategory,
  setIsEditing,
  eventCategoryList,
  expanded,
  noEditAccess,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({
    evCatLength:
      eventCategoryList && eventCategoryList.length > 0
        ? eventCategoryList.length
        : 0,
    expanded,
  });
  function openModalDelete() {
    setShowDeleteModal(true);
  }

  function closeModalDelete() {
    setShowDeleteModal(false);
  }
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [eventCategory, setEventCategory] = useState(eventCategoryInput);
  useEffect(() => {
    setEventCategory(eventCategoryInput);
  }, [eventCategoryInput]);

  const [disabledField, setDisabledField] = useState(
    eventCategoryIsFilled(eventCategoryInput)
  );

  function enableField() {
    if (!editFieldIdOpenRef.current) {
      setDisabledField(false);
      setIsEditing(true);
      editFieldIdOpenRef.current = true;
    }
  }

  function disableField() {
    editFieldIdOpenRef.current = false;
    setDisabledField(true);
    setIsEditing(false);
  }

  function save() {
    saveEventCategoryToDB(
      // eventCategoryList.find(({ name }) => name === eventCategoryInput.name)?.id
      //   ? eventCategoryList.find(({ name }) => name === eventCategoryInput.name)
      //   : eventCategory
      eventCategory
    );
    disableField();
  }

  function deleteEventCategoryFromDB() {
    if (eventCategory?.id) {
      dispatch(deleteEventCategoryThunk(eventCategory)).then(() =>
        setShowDeleteModal(false)
      );
    }
  }

  const [showWarningName, setShowWarningName] = useState(false);

  const formStyleInTable = {
    border: "none",
    backgroundColor: disabledField ? "transparent" : "#bacef5",
  };

  return (
    <React.Fragment>
      <td className={classes.tableCellText}>
        <BootstrapForm.Group controlId={`formBasicScenarioName${i}`}>
          <BootstrapForm.Control
            type="text"
            size="sm"
            placeholder=""
            disabled={disabledField}
            className={classes.formTextField}
            style={formStyleInTable}
            value={eventCategory.name}
            onChange={(e) => {
              setShowWarningName(checkIfExists(e.target.value, "name"));
              setEventCategory({ ...eventCategory, name: e.target.value });
            }}
          />
        </BootstrapForm.Group>
        {showWarningName && (
          <span className="add-edit-participant-form-field-warning">
            Already exists
          </span>
        )}
      </td>

      <td className={classes.tableCellColor}>
        <BootstrapForm.Group controlId={`formBasicColor${i}`}>
          <BootstrapForm.Control
            type="color"
            //defaultValue="#563d7c"
            disabled={disabledField}
            className={`${classes.formColorField} ${classes.colorInput}`}
            title="Choose Event Category color"
            value={eventCategory.color}
            onChange={(e) => {
              setEventCategory({ ...eventCategory, color: e.target.value });
            }}
          />
        </BootstrapForm.Group>
      </td>
      <td className={classes.controlButtons}>
        {disabledField ? (
          <button
            className={classes.controlBtn}
            onClick={enableField}
            disabled={noEditAccess}
          >
            {t("global.editLabel")}
          </button>
        ) : (
          <button
            disabled={!eventCategoryIsFilled(eventCategory) || showWarningName}
            className={classes.controlBtn}
            onClick={save}
            style={{ fontWeight: "bold" }}
          >
            {t("global.saveLabel")}
          </button>
        )}
        {disabledField ? (
          <button
            className={`${classes.controlBtn} ${classes.controlBtnDelete}`}
            onClick={openModalDelete}
            disabled={noEditAccess}
          >
            {t("global.deleteLabel")}
          </button>
        ) : (
          <button
            className={`${classes.controlBtn} ${classes.controlBtnCancel}`}
            disabled={noEditAccess}
            onClick={() => {
              disableField();
              if (!eventCategoryList[i].id) {
                removeEmptyEventCategory(i);
              } else {
                setShowWarningName(false);
                setEventCategory(eventCategoryList[i]);
              }
            }}
          >
            {"Cancel"}
          </button>
        )}
        <Modal
          show={showDeleteModal}
          onHide={closeModalDelete}
          animation={false}
          size="lg"
          className={classes.deleteModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>{t("global.eventCategoryDeleteLabel")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {`${t("global.eventCategoryDeleteLabel")}: ${eventCategory.name}?`}
          </Modal.Body>
          <Modal.Footer>
            <button
              className={`${classes.controlBtn} ${classes.controlBtnDelete}`}
              onClick={() => {
                deleteEventCategoryFromDB();
              }}
            >
              {t("global.confirmLabel")}
            </button>
          </Modal.Footer>
        </Modal>
      </td>
    </React.Fragment>
  );
};
FormGroupControlEventCategory.propTypes = {
  i: PropTypes.number.isRequired,
  eventCategoryInput: PropTypes.object.isRequired,
  editFieldIdOpenRef: PropTypes.object.isRequired,
  eventCategoryIsFilled: PropTypes.func.isRequired,
  checkIfExists: PropTypes.func.isRequired,
  saveEventCategoryToDB: PropTypes.func.isRequired,
  removeEmptyEventCategory: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  eventCategoryList: PropTypes.array.isRequired,
  expanded: PropTypes.bool.isRequired,
  noEditAccess: PropTypes.bool.isRequired,
};
FormGroupControlEventCategory.defaultProps = {
  i: 0,
  eventCategoryInput: {},
  editFieldIdOpenRef: {},
  eventCategoryIsFilled: false,
  eventCategoryList: [],
  expanded: false,
  noEditAccess: true,
};
export default EventCategory;
