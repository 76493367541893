import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteKPI, getKPIs, saveKPI } from "../services";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

export const loadKPIsThunk = createAsyncThunk(
  "KPIs/getKPIs",
  async (thunkAPI, { dispatch }) => {
    console.log("here2");
    const response = await getKPIs(thunkAPI);
    console.log(response);
    dispatch(setKPIs([...response]));
    return response;
  }
);

export const saveKPIThunk = createAsyncThunk(
  "KPI/saveKPI",
  async (thunkAPI, { dispatch }) => {
    const response = await saveKPI(thunkAPI);
    dispatch(loadKPIsThunk(thunkAPI.scenarioId));
    console.log("save ", response);
    return response;
  }
);

export const deleteKPIThunk = createAsyncThunk(
  "KPI/deleteKPI",
  async (thunkAPI, { dispatch }) => {
    await deleteKPI(thunkAPI);
    dispatch(loadKPIsThunk(thunkAPI.scenarioId));
  }
);

const initialState = getFromLocalStorage("kpis", "array")
  ? getFromLocalStorage("kpis", "array")
  : [];

export const kpisSlice = createSlice({
  name: "KPIs",
  initialState,
  reducers: {
    setKPIList: (state, action) => {
      state.KPIList = action.payload;
      saveToLocalStorage("KPIList", state.KPIList);
    },
    getKPIList: (state) => {
      return state.KPIList;
    },
    setKPIs: (state, action) => {
      saveToLocalStorage("kpis", action.payload);
      return action.payload;
    },
    clearKPIs: () => {
      saveToLocalStorage("kpis", []);
      return [];
    },
  },
});

// each case under reducers becomes an action
export const { setKPIList, getKPIList, setKPIs, clearKPIs } = kpisSlice.actions;
export default kpisSlice.reducer;

// const kpisListFromState = [
//   {
//     name: "Performance",
//     type: "Percentage",
//     start: 0,
//     target: 100,
//   },
//   {
//     name: "Endurance",
//     type: "Numeric",
//     start: 0,
//     target: 5,
//   },
// ];
