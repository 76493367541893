import React, { useState } from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";
import { useField } from "formik";
import Map from "../../map/";
import { createUseStyles } from "react-jss";
import { LabelRequiredAndErrorMessage } from "./helpers";
import properties from "../../../utils/properties";

const useStyles = createUseStyles({
  label: {
    fontSize: "x-small",
    marginBottom: "0",
  },
  formField: {
    fontSize: "x-small",
  },
  errorMessage: {
    display: "grid",
    justifyItems: "end",
    color: "red",
    fontSize: "x-small",
  },
  requiredLabel: {
    display: "grid",
    justifyItems: "end",
    fontSize: "x-small",
  },
});
const CoordinatesMapTypeFieldFormik = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { fieldLabel, mapHeight, required, disabled } = props;

  const classes = useStyles();
  const { setValue } = helpers;

  const updateState = (input) => {
    setValue(input);
  };

  return (
    <React.Fragment>
      <BootstrapForm.Group controlId={`formBasic${fieldLabel}`}>
        <BootstrapForm.Label className={classes.label}>
          {fieldLabel}
        </BootstrapForm.Label>
        <Map
          updateState={updateState}
          location={field.value}
          height={mapHeight}
          setValue={setValue}
          disabled={disabled}
        />

        <LabelRequiredAndErrorMessage meta={meta} required={required} />
      </BootstrapForm.Group>
    </React.Fragment>
  );
};
CoordinatesMapTypeFieldFormik.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  mapHeight: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
CoordinatesMapTypeFieldFormik.defaultProps = {
  fieldLabel: "",
  required: false,
  hideRequiredText: false,
  mapHeight: "20rem",
  disabled: false,
};
export default CoordinatesMapTypeFieldFormik;
