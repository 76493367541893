import dataSourceFormFieldsInitial from "../../../utils/constants/forms/data-source-form-fields";

const elementEmpty = {
  type: null,
  message: "",
  sender: "",
  receivers: "",
  eventCategory: "",
  location: {
    type: "Point",
    coordinates: [],
  },
  locationAddress: "",
  file: "",
  levelOfCommand: null,
  socialMediaType: "",
  timeOccurred: "",
  timeEnded: "",
  seconds: null,
  index: 0,
};

function getEmptyElementValue(type, index) {
  function getValueType(valueType) {
    switch (valueType) {
      case "Category":
        return [];
      case "Text":
        return "";
      case "Date":
      case "Time":
        return "";
      case "Quantity":
        return 0;
      case "Numerical":
        return 0.0;

      default:
        return "";
    }
  }

  function fillDataSource() {
    let elementEmptyTemp = {};
    dataSourceFormFieldsInitial.value[index].data.field.forEach((field) => {
      elementEmptyTemp = {
        ...elementEmptyTemp,
        [field.name]: getValueType(field.type),
      };
    });
  }

  switch (type) {
    case "EVENT":
    case "MESSAGE":
    case "SOCIAL_MEDIA":
      return elementEmpty;

    case "DATA_SOURCE":
      return fillDataSource();
    default:
      break;
  }
  return elementEmpty;
}

export default getEmptyElementValue;
