import React from "react";
import CategoryTypeFieldFormik from "../../components/forms/fields/category-type-field-formik";
import QuantityTypeFieldFormik from "../../components/forms/fields/quantity-type-field-formik";
import NumericalTypeFieldFormik from "../../components/forms/fields/numerical-type-field-formik";
import TimeTypeFieldFormik from "../../components/forms/fields/time-type-field-formik";
import TextTypeFieldFormik from "../../components/forms/fields/text-type-field-formik";
import FileTypeFieldFormik from "../../components/forms/fields/file-type-field-formik";
import CoordinatesMapTypeFieldFormik from "../../components/forms/fields/coordinates-map-type-field-formik";
import ColorTypeFieldFormik from "../../components/forms/fields/color-type-field-formik";
import DateTypeFieldFormik from "../../components/forms/fields/date-type-field-formik";
import PropTypes from "prop-types";

const CreateFormField = ({
  field,
  hideRequiredText,
  participantList,
  elementType,
}) => {
  let availableOptionsList = [];
  switch (field.type) {
    case "Category":
      if (field.name === "eventCategory") {
        availableOptionsList = field.constraint.value.map((e, i) => {
          return { ...e, label: e.name, i };
        });
      } else if (["sender", "receivers"].includes(field.name)) {
        availableOptionsList = participantList.map((e, i) => {
          return { ...e, label: e.fullName, i };
        });
      } else {
        availableOptionsList = field.constraint.value.map((e, i) => {
          return { name: e, label: e, i };
        });
      }
      return (
        <CategoryTypeFieldFormik
          availableOptionsList={availableOptionsList}
          fieldLabel={field.label}
          isMultiSelect={field.multiSelect}
          required={Boolean(field.required)}
          description={field.description}
          name={field.name}
          disabled={field.disabled}
          placeholder={field.placeholder}
        />
      );

    case "Quantity":
      return (
        <QuantityTypeFieldFormik
          minValue={field.constraint.minValue}
          maxValue={field.constraint.maxValue}
          fieldLabel={field.label}
          required={Boolean(field.required)}
          description={"Sample description"}
          uom={field.uom?.code}
          name={field.name}
          type="number"
          disabled={field.disabled}
          placeholder={field.placeholder}
        />
      );
    case "Numerical":
      return (
        <NumericalTypeFieldFormik
          minValue={field.constraint.minValue}
          fieldLabel={field.label}
          required={Boolean(field.required)}
          description={"Sample description"}
          uom={field.uom?.code}
          name={field.name}
          type="number"
          disabled={field.disabled}
          placeholder={field.placeholder}
          decimals={field.decimals}
        />
      );
    case "Time":
      return (
        <TimeTypeFieldFormik
          fieldLabel={field.label}
          required={Boolean(field.required)}
          description={"Sample description"}
          name={field.name}
          disabled={field.disabled}
          elementType={elementType}
        />
      );
    case "Date":
      return (
        <DateTypeFieldFormik
          fieldLabel={field.label}
          required={Boolean(field.required)}
          description={"Sample description"}
          name={field.name}
          disabled={field.disabled}
          elementType={elementType}
        />
      );
    case "Text":
    case "Password":
    case "Textarea":
      return (
        <TextTypeFieldFormik
          fieldLabel={field.label}
          required={Boolean(field.required)}
          description={"Sample description"}
          name={field.name}
          type={field.type.toLowerCase()}
          disabled={field.disabled}
          rows={field.rows}
          width={field.width}
          hideRequiredText={hideRequiredText}
          placeholder={field.placeholder}
        />
      );

    case "File":
      return (
        <FileTypeFieldFormik
          fieldLabel={field.label}
          required={field.required}
          description={"Sample description"}
          name={field.name}
          disabled={field.disabled}
          placeholder={field.placeholder}
        />
      );

    case "Coordinates":
      return (
        <CoordinatesMapTypeFieldFormik
          fieldLabel={field.label}
          required={field.required}
          description={"Sample description"}
          name={field.name}
          disabled={field.disabled}
          mapHeight={field.mapHeight}
        />
      );

    case "Color":
      return (
        <ColorTypeFieldFormik
          fieldLabel={field.label}
          required={field.required}
          description={"Sample description"}
          name={field.name}
          disabled={field.disabled}
        />
      );

    default:
      return null;
  }
};
CreateFormField.propTypes = {
  field: PropTypes.object.isRequired,
  hideRequiredText: PropTypes.bool.isRequired,
  participantList: PropTypes.array.isRequired,
  elementType: PropTypes.string.isRequired,
};

CreateFormField.defaultProps = {
  field: {},
  hideRequiredText: true,
  participantList: [],
  elementType: "",
};
export default CreateFormField;
