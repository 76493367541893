import React, { useState } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import ElementsSentBox from "../ElementsSentBox";
import { variables } from "../../jssVariables";
import eventIcon from "../../assets/elementTypes/event.svg";
import messageIcon from "../../assets/elementTypes/message.svg";
import eventAndMessageIcon from "../../assets/elementTypes/eventAndMessage.svg";
import socialMedia from "../../assets/elementTypes/socialMedia.svg";
import TabButton from "../TabButton";

const useStyles = createUseStyles({
  sideBar: () => ({
    display: "grid",
    background: variables.colors.basicLight,
    height: "100%",
    width: "max-content",
    paddingBottom: "3rem",
    alignContent: "space-between",
  }),
  tabs: () => ({
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    justifyContent: "space-between",
  }),
  tabBtn: () => ({
    background: "#ebe8e8",
    padding: ".3rem",
    border: "none",
  }),
  tabIcon: () => ({
    height: "1.5rem",
    width: "1.5rem",
  }),
  link: (props) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: props.isExpanded ? ".3rem" : 0,
    alignItems: "center",
    border: "none",
    background: "none",
    cursor: "pointer",
    height: "max-content",
    color: variables.colors.basicDark,
  }),
  elementSentBox: {
    overflowY: "auto",
    padding: ".5rem 1rem",
  },
  icon: (props) => ({
    width: props.isExpanded ? "1.1rem" : "1.5rem",
    alignSelf: "baseline",
    paddingTop: "0.3rem",
    fontSize: "large",
  }),
});
const SidebarRight = (props) => {
  const { elementsSent, isExpanded } = props;
  const [tabNum, setTabNum] = useState(0);

  const classes = useStyles({ isExpanded });

  return (
    <div className={classes.sideBar}>
      <div className={classes.tabs}>
        <TabButton
          tabId={0}
          label={"All"}
          tabNum={tabNum}
          setTabNum={setTabNum}
        />
        <TabButton
          tabId={1}
          label={eventAndMessageIcon}
          tabNum={tabNum}
          setTabNum={setTabNum}
          labelIsText={false}
        />
        <TabButton
          tabId={2}
          label={eventIcon}
          tabNum={tabNum}
          setTabNum={setTabNum}
          labelIsText={false}
        />

        <TabButton
          tabId={3}
          label={messageIcon}
          tabNum={tabNum}
          setTabNum={setTabNum}
          labelIsText={false}
        />
        <TabButton
          tabId={4}
          label={socialMedia}
          tabNum={tabNum}
          setTabNum={setTabNum}
          labelIsText={false}
        />
      </div>

      {tabNum === 0 && (
        <div className={classes.elementSentBox}>
          <ElementsSentBox elementsSent={elementsSent} selectedType="ALL" />
        </div>
      )}

      {tabNum === 1 && (
        <div className={classes.elementSentBox}>
          <ElementsSentBox
            elementsSent={elementsSent.filter(
              (el) => el.type === "EVENT" || el.type === "MESSAGE"
            )}
          />
        </div>
      )}
      {tabNum === 2 && (
        <div className={classes.elementSentBox}>
          <ElementsSentBox
            elementsSent={elementsSent.filter((el) => el.type === "EVENT")}
          />
        </div>
      )}
      {tabNum === 3 && (
        <div className={classes.elementSentBox}>
          <ElementsSentBox
            elementsSent={elementsSent.filter((el) => el.type === "MESSAGE")}
          />
        </div>
      )}
      {tabNum === 4 && (
        <div className={classes.elementSentBox}>
          <ElementsSentBox
            elementsSent={elementsSent.filter(
              (el) => el.type === "SOCIAL_MEDIA"
            )}
          />
        </div>
      )}
    </div>
  );
};
SidebarRight.defaultProps = {
  elementsSent: [],
  isExpanded: false,
};
SidebarRight.propTypes = {
  elementsSent: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool.isRequired,
};
export default SidebarRight;
