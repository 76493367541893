import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { getFromLocalStorage } from "../utils/helpers/storageHelpers";

const ProtectedRouter = ({ children }) => {
  const isAuthenticated = getFromLocalStorage(
    "authentication",
    "boolean",
    "isAuthenticated"
  );

  if (isAuthenticated && children.type.name === "SignIn") {
    return <Navigate to="/" />;
  } else if (isAuthenticated) {
    return children;
  }
  return <Navigate to="/sign-in" />;
};
export default ProtectedRouter;

ProtectedRouter.propTypes = {
  children: PropTypes.object.isRequired,
};

ProtectedRouter.defaultProps = {
  children: {},
};
