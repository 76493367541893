import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { DateTime } from "luxon";
import eventIcon from "../../assets/elementTypes/event.svg";
import messageIcon from "../../assets/elementTypes/message.svg";
import assetIcon from "../../assets/elementTypes/asset.png";
import dataSource from "../../assets/elementTypes/dataSource.svg";
import twitter from "../../assets/icons/twitter.svg";
import reddit from "../../assets/icons/reddit.svg";
import facebook from "../../assets/icons/facebook.svg";
import instagram from "../../assets/icons/instagram.svg";

const useStyles = createUseStyles({
  elementsSent: {
    width: "max-content",
    display: "grid",
    gridAutoFlow: "row",
    gridGap: "1rem",
  },
  element: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    [`-webkit-box-shadow`]: "4px 4px 5px -1px rgba(86,86,86,0.82)",
    boxShadow: "4px 4px 5px -1px rgba(86,86,86,0.82)",
    height: "max-content",
    width: "15rem",
    padding: ".5rem 1rem",
    paddingBottom: ".7rem",
    borderRadius: "1rem",
    border: `1px solid #0d6efd3b`,
  },
  content: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: ".3rem",
    paddingBottom: ".5rem",
  },
  basicInfo: {
    display: "grid",
    gridTemplateColumns: "max-content 1fr  1fr max-content",
    justifyContent: "space-between",
    gridGap: "1rem",
    alignItems: "center",
  },
  basicIcon: {
    height: "2rem",
    width: "2rem",
  },
  sender: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "small",
  },
  timestamp: {
    fontWeight: "normal",
    fontSize: "x-small",
  },
  state: {
    fontSize: "x-small",
    fontWeight: "bold",
  },
  message: {
    borderBottom: "1px solid lightgray",
    padding: ".3rem 0",
    fontSize: "small",
  },
  receivers: {
    display: "flex",
    height: "max-content",
    overflow: "hidden",
    flexWrap: "wrap",
  },
  receiver: {
    fontSize: "small",
    padding: ".1rem .4rem",
    background: "#e0e0f7",
    borderRadius: ".3rem",
    width: "max-content",
    marginRight: ".2rem",
    marginBottom: ".2rem",
  },
});
const ElementsSentBox = (props) => {
  const { elementsSent, selectedType } = props;
  const messageRef = useRef(null);
  const classes = useStyles();

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }, [elementsSent]);

  function formatTinestanp(timestamp) {
    return DateTime.fromISO(timestamp).toLocaleString({
      hour: "2-digit",
      minute: "2-digit",
      hourCycle: "h23",
      month: "short",
      day: "2-digit",
    });
  }

  function getIcon(element) {
    switch (element.type) {
      case "EVENT":
        return eventIcon;
      case "MESSAGE":
        return messageIcon;
      case "ASSET":
        return assetIcon;
      case "SOCIAL_MEDIA":
        return getSocialMediaIcon(element.socialMediaType);
      case "DATA_SOURCE":
        return dataSource;
      default:
        return null;
    }
  }

  function getSocialMediaIcon(socialMediaType) {
    switch (socialMediaType) {
      case "Twitter":
        return twitter;
      case "Reddit":
        return reddit;
      case "Facebook":
        return facebook;
      case "Instagram":
        return instagram;
      default:
        return null;
    }
  }
  return (
    <div className={classes.elementsSent} ref={messageRef}>
      {elementsSent.length ? (
        elementsSent.map((el, idx) => {
          return (
            <div className={classes.element} key={idx}>
              <div className={classes.content}>
                {el.type !== "SOCIAL_MEDIA_BATCH" ? (
                  <React.Fragment>
                    <div className={classes.basicInfo}>
                      <img
                        src={getIcon(el)}
                        alt="eventIcon"
                        className={classes.basicIcon}
                      />
                      <div className={classes.sender}>
                        {el.sender ? el.sender.name : ""}
                        {el.type === "DATA_SOURCE" && "DATA SOURCE: "}

                        <div className={classes.timestamp}>
                          {formatTinestanp(el.time)}
                        </div>
                      </div>
                      <span></span>
                      <span className={classes.state}>
                        {el.type === "EVENT" && el.active ? "START" : "ENDED"}
                      </span>
                    </div>

                    <div className={classes.message}>{el.message}</div>
                  </React.Fragment>
                ) : (
                  <div className={classes.sender}>
                    SOCIAL MEDIA BATCH
                    <div className={classes.timestamp}>
                      {formatTinestanp(el.time)}
                    </div>
                  </div>
                )}
              </div>
              {el.type === "DATA_SOURCE" && el.dataSource}
              {!["SOCIAL_MEDIA", "SOCIAL_MEDIA_BATCH", "DATA_SOURCE"].includes(
                el.type
              ) && (
                <div className={classes.receivers}>
                  {el.receivers.map((r, index) => {
                    return (
                      <React.Fragment key={index}>
                        <span key={index} className={classes.receiver}>
                          {r.name ? r.name : ""}
                        </span>
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className={classes.element}>
          {selectedType === "ALL"
            ? "Nothing has been sent yet."
            : " No elements of this kind."}
        </div>
      )}
    </div>
  );
};
ElementsSentBox.defaultProps = {
  elementsSent: [],
};
ElementsSentBox.propTypes = {
  elementsSent: PropTypes.array.isRequired,
  selectedType: PropTypes.string,
};

export default ElementsSentBox;
