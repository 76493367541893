import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import scenarioEmpty from "../../utils/constants/data/scenario-empty";
import {
  getScenarioList,
  deleteScenario,
  cloneScenario,
} from "../../services/index";
import { useSelector, useDispatch } from "react-redux";
import { setScenario } from "../../redux/scenarioSlice";
import { loadOrganizationListThunk } from "../../redux/organizationSlice";
import { loadParticipantListThunk } from "../../redux/participantSlice";
import { loadEventCategoryListThunk } from "../../redux/eventCategorySlice";
import { createUseStyles } from "react-jss";
import { variables, myButton } from "../../jssVariables";
import {
  clearScenarioDataFromLocalStorage,
  clearScenarioDataFromGlobalState,
} from "../../utils/helpers/storageHelpers";
import closeIconX from "../../assets/icons/closeIconX.svg";
import { loadKPIsThunk } from "../../redux/kpisSlice";
import { loadLessonsLearnedThunk } from "../../redux/lessonsLearnedSlice";
import { loadCommentsThunk } from "../../redux/commentSlice";
import _ from "lodash";

const useStyles = createUseStyles({
  scenarioLibrary: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: "1rem",
    paddingTop: ".5rem",
  },
  breadcrumb: {
    padding: "0",
    height: "1.5rem",
  },
  breadcrumbItem: {
    fontSize: variables.breadcrumb.fontSize,
    color: variables.colors.basicBlue,
  },
  cardContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: "1rem",
    justifyContent: "end",
    padding: "0",
  },
  card: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    justifyItems: "center",
    width: "max-content",
    background: variables.colors.basicLight,
    padding: ".5rem",
    borderRadius: ".5rem",
    fontSize: "small",
  },
  tablePanel: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    gridGap: "1rem",
    background: variables.colors.basicLight,
    borderRadius: ".5rem",
    padding: "1rem",
    marginTop: "3rem",
  },
  title: {
    fontSize: "large",
    fontFamily: variables.fontHeaderFamily,
  },
  addScenarioBtn: {
    ...myButton,
  },
  table: {
    fontSize: "small",
  },
  columnText: {
    fontSize: "small",
    padding: ".5rem 0",
  },
  columnBtns: {
    width: "10rem",
  },
  columnBtnsContainer: {
    display: "grid",
    gridAutoFlow: "column",
    gridGap: ".5rem",
  },
  actionBtn: {
    ...myButton,
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
  bottomPanel: {
    display: "grid",
    justifyItems: "end",
  },
  modalDelete: {
    marginTop: "3rem",
  },
  closeIconX: {
    width: "3rem",
    height: "2rem",
    cursor: "pointer",
  },
});
// eslint-disable-next-line max-statements
const ScenarioLibrary = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const authentication = useSelector((state) => state.authentication);

  const [scenarioList, setScenarioList] = useState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);

  const [cloneTitleExtraText, setCloneTitleExtraText] = useState("");
  const [scenarioToEditClone, setScenarioToEditClone] = useState({});

  clearScenarioDataFromGlobalState();

  function deleteScenarioFromDB(scenarioId, title) {
    deleteScenario(scenarioId, title).then(() => {
      getScenariosFromDB();
      setShowDeleteModal(false);
    });
  }

  function getAccessType({
    user,
    author,
    isPrivateScenario,
    scenarioReadAccess,
    scenarioFullAccesss,
  }) {
    if (_.get(user, "roles", []).includes("admin")) {
      return "ADMIN_ACCESS";
    }
    if (user.email === author) {
      return "FULL_ACCESS";
    }
    if (isPrivateScenario) {
      if (scenarioFullAccesss?.includes(user.email)) {
        return "EDIT_ACCESS";
      } else if (scenarioReadAccess?.includes(user.email)) {
        return "READ_ACCESS";
      }
    }
    return "EDIT_ACCESS";
  }

  function getScenariosFromDB() {
    getScenarioList().then((response) => {
      const scenarioListWithAccessType = response.map((scenario) => {
        return {
          ...scenario,
          accessType: getAccessType({
            user: {
              email: authentication.user.email,
              roles: authentication.roles,
            },
            author: scenario.author,
            isPrivateScenario: scenario.privateScenario,
            scenarioReadAccess: scenario.readAccess,
            scenarioFullAccesss: scenario.fullAccess,
          }),
        };
      });
      setScenarioList(scenarioListWithAccessType);
    });
  }

  useEffect(() => {
    clearScenarioDataFromLocalStorage();
    getScenariosFromDB();
  }, []);

  const addScenario = () => {
    dispatch(setScenario(scenarioEmpty));
    navigate("/add-edit-scenario-main-data");
  };

  const editScenario = (scenarioExisted) => {
    dispatch(setScenario(scenarioExisted));
    dispatch(loadOrganizationListThunk(scenarioExisted.id));
    dispatch(loadParticipantListThunk(scenarioExisted.id));
    dispatch(loadEventCategoryListThunk(scenarioExisted.id));
    dispatch(loadKPIsThunk(scenarioExisted.id));
    dispatch(loadLessonsLearnedThunk(scenarioExisted.id));
    dispatch(loadCommentsThunk(scenarioExisted.id));
    navigate("/add-edit-scenario-main-data");
  };

  const openScenario = (scenarioExisted) => {
    dispatch(setScenario(scenarioExisted));
    dispatch(loadOrganizationListThunk(scenarioExisted.id));
    dispatch(loadParticipantListThunk(scenarioExisted.id));
    dispatch(loadEventCategoryListThunk(scenarioExisted.id));
    dispatch(loadKPIsThunk(scenarioExisted.id));
    dispatch(loadLessonsLearnedThunk(scenarioExisted.id));
    dispatch(loadCommentsThunk(scenarioExisted.id));
    navigate("/scenario");
  };

  function cloneScenarioFunction() {
    cloneScenario({
      id: scenarioToEditClone.id,
      title: `${scenarioToEditClone.title}_${cloneTitleExtraText}`,
    }).then((result) => {
      setScenarioToEditClone({});
      setShowCloneModal(false);
      setCloneTitleExtraText("");
      getScenariosFromDB();
    });
  }

  return (
    <div className={classes.scenarioLibrary}>
      <Breadcrumb className={classes.breadcrumb}>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/")}
        >
          {t("breadcrumb.homeScreen")}
        </Breadcrumb.Item>
        <Breadcrumb.Item active className={classes.breadcrumbItem}>
          {t("breadcrumb.scenarioLibrary")}
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className={classes.cardContainer}>
        <Card
          number={scenarioList ? scenarioList.length : 0}
          text={t("scenarioLibrary.totalScenariosLabel")}
        />
        <Card
          number={scenarioList ? scenarioList.length : 0}
          text={t("scenarioLibrary.publicScenariosLabel")}
        />
        <Card number={2} text={t("scenarioLibrary.userRegisteredLabel")} />
        <Card
          number={scenarioList ? scenarioList.length : 0}
          text={t("scenarioLibrary.loggedInUserScenarios")}
        />
      </div>
      <div className={classes.tablePanel}>
        <span className={classes.title}>
          {t("global.scenarioLibraryLabel")}
        </span>
        <table className={classes.table}>
          <thead>
            <tr>
              <th className={classes.columnTitle}>#</th>

              <th className={classes.columnTitle}>{t("global.titleLabel")}</th>
              <th className={classes.columnTitle}>
                {t("global.locationAddresslabel")}
              </th>
              <th className={classes.columnTitle}>{t("global.authorLabel")}</th>
              <th className={classes.columnTitle}>
                {t("global.commentsLabel")}
              </th>
              <th className={classes.columnBtns}></th>
            </tr>
          </thead>

          <tbody>
            {scenarioList &&
              scenarioList.map((scenario, i) => (
                <tr key={i}>
                  <td className={classes.columnText}>{i + 1}</td>

                  <td className={classes.columnText}>{scenario.title}</td>
                  <td className={classes.columnText}>
                    {scenario.locationAddress}
                  </td>
                  <td className={classes.columnText}>{scenario.author}</td>
                  <td className={classes.columnText}>{scenario.comments}</td>
                  <td className={classes.columnBtns}>
                    <span className={classes.columnBtnsContainer}>
                      <button
                        className={classes.actionBtn}
                        onClick={() => {
                          setScenarioToEditClone(scenario);
                          setShowCloneModal(true);
                        }}
                      >
                        Clone
                      </button>{" "}
                      <button
                        className={classes.actionBtn}
                        onClick={() => editScenario(scenario)}
                      >
                        {[
                          "ADMIN_ACCESS",
                          "FULL_ACCESS",
                          "EDIT_ACCESS",
                        ].includes(scenario.accessType)
                          ? t("global.editLabel")
                          : t("global.viewLabel")}
                      </button>{" "}
                      <button
                        className={classes.actionBtn}
                        onClick={() => openScenario(scenario)}
                      >
                        {t("global.openLabel")}
                      </button>{" "}
                      <button
                        className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                        onClick={() => {
                          setScenarioToEditClone(scenario);
                          setShowDeleteModal(true);
                        }}
                        disabled={
                          ![
                            "ADMIN_ACCESS",
                            "FULL_ACCESS",
                            "EDIT_ACCESS",
                          ].includes(scenario.accessType)
                        }
                      >
                        {t("global.deleteLabel")}
                      </button>
                    </span>
                    <Modal
                      show={showCloneModal}
                      onHide={() => setShowCloneModal(false)}
                      animation={false}
                      size="lg"
                      className={classes.modalDelete}
                    >
                      <Modal.Header>
                        <Modal.Title>{"Clone scenario"}</Modal.Title>
                        <img
                          src={closeIconX}
                          alt=""
                          onClick={() => setShowCloneModal(false)}
                          className={classes.closeIconX}
                        />
                      </Modal.Header>
                      <Modal.Body>
                        {`Clone the scenario: ${scenarioToEditClone.title} to: ${scenarioToEditClone.title}_`}
                        <input
                          type="text"
                          value={cloneTitleExtraText}
                          onChange={(e) =>
                            setCloneTitleExtraText(e.target.value)
                          }
                        ></input>
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                          onClick={() =>
                            cloneScenarioFunction(scenarioToEditClone)
                          }
                        >
                          Clone
                        </button>
                      </Modal.Footer>
                    </Modal>
                    <Modal
                      show={showDeleteModal}
                      onHide={() => setShowDeleteModal(false)}
                      animation={false}
                      size="lg"
                      className={classes.modalDelete}
                    >
                      <Modal.Header>
                        <Modal.Title>{"Delete scenario"}</Modal.Title>
                        <img
                          src={closeIconX}
                          alt=""
                          onClick={() => setShowDeleteModal(false)}
                          className={classes.closeIconX}
                        />
                      </Modal.Header>
                      <Modal.Body>
                        {`Delete the scenario: ${scenarioToEditClone.title}?`}
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className={`${classes.actionBtn} ${classes.actionBtnDelete}`}
                          onClick={() =>
                            deleteScenarioFromDB(
                              scenarioToEditClone.id,
                              scenarioToEditClone.title
                            )
                          }
                        >
                          {t("global.confirmLabel")}
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className={classes.bottomPanel}>
          <button className={classes.addScenarioBtn} onClick={addScenario}>
            {t("global.addScenario")}
          </button>
        </div>{" "}
      </div>
    </div>
  );
};
export default ScenarioLibrary;

const Card = ({ number, text }) => {
  const classes = useStyles();

  return (
    <div className={classes.card}>
      <span className={classes.cardTitle}>{number}</span>
      <span className={classes.cardText}>{text}</span>
    </div>
  );
};

Card.propTypes = {
  number: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
};

Card.defaultProps = {
  number: 0,
  text: "",
};
