import React from "react";
import staminaLogoSmall from "./../../assets/images/STAMINA_logo_small_72x87.png";
import { useTranslation } from "react-i18next";
import burgerMenu from "../../assets/icons/burgerMenu.svg";
import chatMessage from "../../assets/icons/chatMessage.svg";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { variables } from "../../jssVariables";
import NIGHTINGALE_mark from "../../resources/NIGHTINGALE_Mark.svg";
import SILVANUS_mark from "../../resources/SILVANUS_logo_without_text.svg";
import EXUS_mark from "../../assets/images/EXUS_logo_white20x31_small.png";

const proj_name = process.env.REACT_APP_PROJECT_NAME || "default";

const useStyles = createUseStyles({
  navbar: {
    display: "grid",
    gridTemplateColumns: "repeat(3, max-content)",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 .2rem",
    position: "fixed",
    zIndex: 9999,
    top: 0,
    width: "100%",
    height: "2.5rem",
    background: variables.colors.basicLight,
    boxShadow: variables.myShadow,
  },
  icon: {
    height: "1.5rem",
    cursor: "pointer",
  },
  titleContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: ".5rem",
  },
  title: {
    color: "black",
    fontSize: "large",
    fontWeight: "bold",
    fontFamily: variables.fontHeaderFamily,
  },
  projectLogo: {
    width: "1.5rem",
  },
  projectLogoNightingale: {
    width: "2.5rem",
  },
  projectLogoSilvanus: {
    width: "1.2rem"
  },
  projectLogoCoprotect: {
    height: "1.8rem",
  },
});
const NavbarTop = (props) => {
  const { t } = useTranslation();
  const { setIsExpandedLeft, setIsExpandedRight } = props;
  const location = useLocation();

  const classes = useStyles();
  const locationUrl = location.pathname;

  return (
    <div className={classes.navbar}>
      <img
        src={burgerMenu}
        alt="menu"
        className={classes.icon}
        onClick={() => setIsExpandedLeft((e) => !e)}
      />
      <span className={classes.titleContainer}>
        {proj_name === "nightingale" ? (
          <React.Fragment>
            <img
              src={NIGHTINGALE_mark}
              alt="project logo icon"
              className={classes.projectLogoNightingale}
            />
            <span href="/" className={classes.title}>
              Scenario Builder
            </span>
          </React.Fragment>
        ) : proj_name === "silvanus"
          ? (
            <React.Fragment>
              <img
                src={SILVANUS_mark}
                alt="project logo icon"
                className={classes.projectLogoSilvanus}
              />
              <span href="/" className={classes.title}>
                Scenario Builder
              </span>
            </React.Fragment>
          ) : proj_name === "co-protect"
            ? (
              <React.Fragment>
                <img
                  src={EXUS_mark}
                  alt="project logo icon"
                  className={classes.projectLogoCoprotect}
                />
                <span href="/" className={classes.title}>
                Scenario Builder
              </span>
              </React.Fragment>
            )
          : (
          <React.Fragment>
            <img
              src={staminaLogoSmall}
              alt="project logo icon"
              className={classes.projectLogo}
            />
            <span href="/" className={classes.title}>
              {t("headerTitle")}
            </span>
          </React.Fragment>
        )}
      </span>
      {locationUrl === "/scenario" && (
        <span>
          <img
            src={chatMessage}
            alt="menu"
            className={classes.icon}
            onClick={() => setIsExpandedRight((e) => !e)}
          />
        </span>
      )}

      {/* <div className={classes.dropdownItems}>
        <NavDropdown title={t("global.languageLabel")} id="basic-nav-dropdown">
          <NavDropdown.Item
            className={classes.dropdownItem}
            value="en"
            onClick={() => changeLanguage("en")}
            href="#action/3.1"
          >
            English
          </NavDropdown.Item>
          <NavDropdown.Item
            className={classes.dropdownItem}
            value="gr"
            onClick={() => changeLanguage("gr")}
            href="#action/3.2"
          >
            Greek
          </NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item
            className={classes.dropdownItem}
            value="en"
            onClick={() => changeLanguage("en")}
            href="#action/3.4"
          >
            Default
          </NavDropdown.Item>
        </NavDropdown>

        {isAuthenticated && (
          <NavDropdown title={user.username} id="basic-nav-dropdown">
            <NavDropdown.Item
              className="navbar-dropdown-item"
              href="#action/3.1"
            >
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item
              className="navbar-dropdown-item"
              href="#action/3.2"
            >
              Account
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              className="navbar-dropdown-item"
              href="/"
              onClick={logOut}
            >
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        )}
      </div> */}
    </div>
  );
};
NavbarTop.propTypes = {
  setIsExpandedLeft: PropTypes.func.isRequired,
  setIsExpandedRight: PropTypes.func.isRequired,
};
export default NavbarTop;
