import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  loadParticipantList,
  saveParticipant,
  deleteParticipant,
} from "../services/";
import emptyParticipant from "../utils/constants/data/empty-participant";
import {
  saveToLocalStorage,
  getFromLocalStorage,
} from "../utils/helpers/storageHelpers";

export const loadParticipantListThunk = createAsyncThunk(
  "participant/loadParticipantList",
  async (thunkAPI, { dispatch }) => {
    const response = await loadParticipantList(thunkAPI);
    dispatch(setParticipantList(response));
    return response;
  }
);

export const saveParticipantThunk = createAsyncThunk(
  "saveParticipantThunk/saveParticipant",
  async (thunkAPI, { dispatch }) => {
    const response = await saveParticipant(thunkAPI);
    dispatch(loadParticipantListThunk(thunkAPI.scenario));
    return response;
  }
);

export const deleteParticipantThunk = createAsyncThunk(
  "participant/deleteParticipant",
  async (thunkAPI, { dispatch }) => {
    await deleteParticipant(thunkAPI);
    dispatch(loadParticipantListThunk(thunkAPI.scenario));
  }
);

export const participantSlice = createSlice({
  name: "participant",
  initialState: {
    participant: getFromLocalStorage("participant", "object")
      ? getFromLocalStorage("participant", "object")
      : emptyParticipant,
    participantList: getFromLocalStorage("participantList", "object")
      ? getFromLocalStorage("participantList", "array")
      : [],
  },
  reducers: {
    setParticipantList: (state, action) => {
      state.participantList = action.payload;
      saveToLocalStorage("participantList", state.participantList);
    },
    getParticipantList: (state) => {
      return state.participant.participantList;
    },
    setParticipant: (state, action) => {
      state.participant = action.payload;
      saveToLocalStorage("participant", state);
    },

    clearParticipant: () => {
      saveToLocalStorage("participant", emptyParticipant);
      return { participant: { emptyParticipant } };
    },

    clearParticipantList: () => {
      saveToLocalStorage("participantList", []);
      return { participantList: [] };
    },
  },
});

export const {
  setParticipantList,
  getParticipantList,
  setParticipant,
  clearParticipant,
  clearParticipantList,
} = participantSlice.actions;
export default participantSlice.reducer;
