import React from "react";
import { Routes, Route } from "react-router-dom";
import Scenario from "../pages/scenario";
import ScenarioLibrary from "../pages/scenarioLibrary";
import AddEditScenarioMainDataContainer from "../pages/scenario/AddEditScenarioMainData";
import AddEditOrganizationsContainer from "../components/organization/index";
import AddEditParticipantsContainer from "../components/participant/index";
import ScenarioSettings from "../pages/scenario-settings/scenario-settings";
import ProtectedRouter from "./ProtectedRouter";
import SignIn from "../pages//signIn";
import HomeScreen from "../pages/homeScreen";
import PropTypes from "prop-types";
import KeyPerformanceIndicatorsContainer from "../components/key-performance-indicators";

const RoutesOfSite = (props) => {
  const { setIAuthenticated, isExpandedRight, setIsExpandedRight } = props;
  return (
    <Routes>
      <Route
        exact
        path="/sign-in"
        element={<SignIn setIAuthenticated={setIAuthenticated} />}
      />
      <Route
        exact
        path="/"
        element={
          <ProtectedRouter>
            <HomeScreen />
          </ProtectedRouter>
        }
      />
      <Route
        path="/scenario-library"
        element={
          <ProtectedRouter>
            <ScenarioLibrary />
          </ProtectedRouter>
        }
      />
      <Route
        path="/add-edit-scenario-main-data"
        element={
          <ProtectedRouter>
            <AddEditScenarioMainDataContainer />
          </ProtectedRouter>
        }
      />
      <Route
        path="/add-edit-organizations"
        element={
          <ProtectedRouter>
            <AddEditOrganizationsContainer />
          </ProtectedRouter>
        }
      />
      <Route
        path="/add-edit-participants"
        element={
          <ProtectedRouter>
            <AddEditParticipantsContainer />
          </ProtectedRouter>
        }
      />
      <Route
        path="/scenario-KPIs"
        element={
          <ProtectedRouter>
            <KeyPerformanceIndicatorsContainer />
          </ProtectedRouter>
        }
      />
      <Route
        path="/scenario-settings"
        element={
          <ProtectedRouter>
            <ScenarioSettings />
          </ProtectedRouter>
        }
      />

      <Route
        path="/scenario"
        element={
          <ProtectedRouter>
            <Scenario
              isExpandedRight={isExpandedRight}
              setIsExpandedRight={setIsExpandedRight}
            />
          </ProtectedRouter>
        }
      />
    </Routes>
  );
};
RoutesOfSite.defaultProps = {
  isExpandedRight: false,
};
RoutesOfSite.propTypes = {
  setIAuthenticated: PropTypes.func.isRequired,
  isExpandedRight: PropTypes.bool.isRequired,
  setIsExpandedRight: PropTypes.func.isRequired,
};
export default RoutesOfSite;
