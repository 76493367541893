import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  clearScenarioDataFromLocalStorage,
  clearScenarioDataFromGlobalState,
} from "../../utils/helpers/storageHelpers";
import { createUseStyles } from "react-jss";
import { variables, myButton } from "../../jssVariables";

const useStyles = createUseStyles({
  homeScreen: {
    display: "grid",
  },
  homeScreenCardPanel: {
    display: "grid",
    height: "10rem",
    marginTop: "5rem",
    padding: "0 5rem",
  },
  card: {
    display: "grid",
    gridTemplateRows: "auto auto",
    width: "15rem",
    boxShadow: variables.myShadow,
    background: variables.colors.basicLight,
    padding: "1rem",
    borderRadius: "1rem",
  },
  btnContainer: {
    display: "grid",
    justifyItems: "center",
    height: "max-content",
  },
  btn: {
    ...myButton,
  },
  cardTitle: {
    padding: 0,
    fontFamily: variables.fontHeaderFamily,
  },
  cardText: {},
  [`@media ${variables.media.smallscreen}`]: {
    btn: {
      fontSize: "medium",
    },
  },
  [`@media ${variables.media.bigscreen}`]: {
    btn: {},
  },
});
const HomeScreen = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  clearScenarioDataFromLocalStorage();
  clearScenarioDataFromGlobalState();

  return (
    <div className={classes.homeScreen}>
      <div className={classes.homeScreenCardPanel}>
        <CardPanel
          title={t("global.scenarioLibraryLabel")}
          text={t("global.scenarioLibraryDescription")}
          link={"scenario-library"}
        />
      </div>
    </div>
  );
};

const CardPanel = ({ ...props }) => {
  const { title, text, link } = props;
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.card}>
      <div className={classes.btnContainer}>
        <span className={classes.btn} onClick={() => navigate(`/${link}`)}>
          <span className={classes.cardTitle}>{title}</span>
        </span>
      </div>
      <div className={classes.cardText}>{text}</div>
    </div>
  );
};

CardPanel.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

CardPanel.defaultProps = {
  title: "",
  text: "",
  link: "",
};

export default HomeScreen;
