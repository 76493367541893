export const scenarioSettingsFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "Scenario",
      description: "",
      name: "scenario",
      data: {
        field: [
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Scenario Author",
            name: "authorEmail",
            disabled: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Scenario is private ",
            name: "privateScenario",
            multiSelect: false,
            required: true,
            constraint: {
              type: "AllowedTokens",
              value: ["YES", "NO"],
              minValue: 1,
            },
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Read only access",
            name: "readAccess",
            multiSelect: true,
            required: false,
            constraint: {
              type: "AllowedTokens",
              value: [],
            },
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Full access",
            name: "fullAccess",
            multiSelect: true,
            required: false,
            constraint: {
              type: "AllowedTokens",
              value: [],
            },
          },
        ],
      },
    },
  ],
};

export const scenarioSettingsFormFields = (usersAndOrgs) => {
  const { users, organizations } = usersAndOrgs;

  scenarioSettingsFormFieldsInitial.value[0].data.field[2].constraint.value = [
    ...users.map((user) => {
      return user.email;
    }),
    ...organizations.map((organization) => {
      return organization.name;
    }),
  ];

  scenarioSettingsFormFieldsInitial.value[0].data.field[3].constraint.value = [
    ...users.map((user) => {
      return user.email;
    }),
    ...organizations.map((organization) => {
      return organization.name;
    }),
  ];

  return scenarioSettingsFormFieldsInitial;
};
