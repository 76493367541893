import { useEffect, useState } from "react";
import { saveToLocalStorage, getFromLocalStorage } from "../storageHelpers";

export const useSystemClock = () => {
  const [systemTime, setSystemTime] = useState(new Date());
  const [givenTime, setGivenTime] = useState("");
  const [isPaused, setIsPaused] = useState(false);

  const [offsetMillis, setOffsetMillis] = useState(0);

  function setManualDatetime(input) {
    setGivenTime(input);
    if (input) {
      const timeNowTemp = new Date();
      setOffsetMillis(
        timeNowTemp.valueOf() > input.valueOf()
          ? timeNowTemp.valueOf() - input.valueOf()
          : input.valueOf() - timeNowTemp.valueOf()
      );
    } else {
      setSystemTime(new Date());
      setOffsetMillis(0);
    }
  }

  useEffect(() => {
    let fixedTime = 0;
    if (givenTime) {
      fixedTime =
        new Date().valueOf() < givenTime.valueOf()
          ? new Date().valueOf() + offsetMillis
          : new Date().valueOf() - offsetMillis;
    }

    const timer = setInterval(() => {
      if (!givenTime) {
        setSystemTime(new Date());
      } else {
        if (!isPaused) {
          setSystemTime(new Date(fixedTime));
        }
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  return [systemTime, givenTime, isPaused, setManualDatetime, setIsPaused];
};
