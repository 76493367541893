const eventFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "EVENT",
      description: "",
      name: "event",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident Category",
            name: "eventCategory",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Sender",
            name: "sender",
            placeholder: "Sender of this event",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
            required: true,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Receiver(s)",
            name: "receivers",
            placeholder: "Receiver(s) of this event",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: true,
            required: true,
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Message",
            name: "message",
            placeholder: "Message of this event",
            required: true,
            rows: 3,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Scenario location coordinates",
            name: "locationCoordinates",
            placeholder: "Will appear after point selection on map",
            disabled: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location Address",
            name: "locationAddress",
            placeholder: "If exists will appear after point selection on map",
            required: false,
            disabled: true,
          },
          {
            type: "Coordinates",
            valueType: "string",
            description: "",
            label: "Point selection on map",
            name: "coordinates",
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Start time",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
            isRange: false,
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "End time",
            name: "timeEnded",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
            isRange: false,
          },
          // {
          //   type: "Quantity",
          //   valueType: "integer",
          //   description: "",
          //   label: "Index",
          //   name: "index",
          //   placeholder: "",
          //   constraint: {
          //     minValue: 1,
          //     maxValue: 1,
          //   },
          //   required: true,
          // },
        ],
      },
    },
  ],
};

const eventFormFields = (eventCategoryList, participantsList, scriptLength) => {
  eventFormFieldsInitial.value[0].data.field[0].constraint.value =
    eventCategoryList;
  eventFormFieldsInitial.value[0].data.field[1].constraint.value =
    participantsList;
  const fieldsLength = eventFormFieldsInitial.value[0].data.field.length;
  eventFormFieldsInitial.value[0].data.field[
    fieldsLength - 1
  ].constraint.maxValue = scriptLength + 1;
  return eventFormFieldsInitial;
};
export default eventFormFields;
