import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import elementSlice from "./elementSlice";
import organizationSlice from "./organizationSlice";
import participantSlice from "./participantSlice";
import scenarioSlice from "./scenarioSlice";
import eventCategorySlice from "./eventCategorySlice";
import usersAndOrgsSlice from "./usersAndOrgsSlice";
import kpisSlice from "./kpisSlice";
import lessonsLearnedSlice from "./lessonsLearnedSlice";
import commentSlice from "./commentSlice";

export default configureStore({
  reducer: {
    authentication: authSlice,
    scenario: scenarioSlice,
    participant: participantSlice,
    organization: organizationSlice,
    element: elementSlice,
    eventCategory: eventCategorySlice,
    usersAndOrgs: usersAndOrgsSlice,
    kpis: kpisSlice,
    lessonsLearned: lessonsLearnedSlice,
    comments: commentSlice,
  },
});
