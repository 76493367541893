import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import { createValidationSchema } from "./../../utils/helpers/validation-helpers";
import CreateFormField from "./../../utils/helpers/create-form-field";
import {
  scenarioSettingsFormFieldsInitial,
  scenarioSettingsFormFields,
} from "./../../utils/constants/forms/scenario-settings-form-fields";
import { saveScenarioThunk } from "../../redux/scenarioSlice";
import { useSelector, useDispatch } from "react-redux";
import { variables, myButton } from "../../jssVariables";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const scenarioSettingsUseStyles = createUseStyles({
  title: {
    fontSize: "medium",
    fontFamily: variables.fontHeaderFamily,
  },
  formContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content max-content",
    padding: ".5rem 1rem",
    gridGap: ".5rem",
    background: variables.colors.basicLight,
    borderRadius: "0.5rem",
    width: "max-content",
  },
  form: {
    display: "grid",
    gridAutoFlow: "row",
    gridGap: ".5rem",
    width: "30rem",
  },
  actionBtns: {
    display: "grid",
    gridTemplateColumns: "max-content max-content",
    justifyContent: "space-between",
  },
  actionBtn: {
    ...myButton,
    width: "max-content",
    "&:disabled": {
      background: "lightgray",
      cursor: "no-drop",
    },
  },
  actionBtnSubmit: {
    marginTop: "2rem",
    justifySelf: "end",
  },
});
export const ScenarioSettings = (props) => {
  const { isInTimeline } = props;

  const { t } = useTranslation();
  const classes = scenarioSettingsUseStyles();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { scenario, usersAndOrgs } = useSelector((state) => state);

  const user = useSelector((state) => state.authentication.user);
  const noEditAccess = ["READ_ACCESS"].includes(scenario.accessType);

  function onSubmit(values) {
    if (noEditAccess) {
      if (!isInTimeline) {
        navigate("/scenario");
      }
    } else {
      dispatch(
        saveScenarioThunk({
          ...scenario,
          privateScenario: values.privateScenario[0].value === "YES",
          readAccess: values.readAccess.map((userName) => userName.value),
          fullAccess: values.fullAccess.map((userName) => userName.value),
        })
      ).then(() => {
        navigate("/scenario");
      });
    }
  }

  let scenarioSettingsFormFieldsFull = {};

  function previousStep() {
    navigate("/scenario-KPIs");
  }

  useEffect(() => {
    scenarioSettingsFormFieldsFull = scenarioSettingsFormFields(usersAndOrgs);
  }, []);

  return (
    <div className={classes.scenarioSettings}>
      <div className={classes.formContainer}>
        {!isInTimeline && (
          <span className={classes.title}>{"Scenario Settings"} </span>
        )}
        {scenarioSettingsFormFieldsFull && (
          <Formik
            initialValues={{
              authorEmail: user.email,
              privateScenario: scenario.privateScenario
                ? [{ value: "YES", label: "YES" }]
                : [{ value: "NO", label: "NO" }],
              readAccess: scenario.readAccess
                ? scenario.readAccess.map((userName) => {
                    return { label: userName, value: userName };
                  })
                : [],
              fullAccess: scenario.fullAccess
                ? scenario.fullAccess.map((userName) => {
                    return { label: userName, value: userName };
                  })
                : [],
            }}
            validationSchema={createValidationSchema({
              formFieldsSchema: scenarioSettingsFormFieldsInitial,
            })}
            onSubmit={(values) => {
              onSubmit(values);
            }}
          >
            <Form className={classes.form}>
              {scenarioSettingsFormFields(usersAndOrgs).value[0].data.field.map(
                (field, i) => (
                  <CreateFormField
                    key={i}
                    field={{
                      ...field,
                      disabled:
                        field.name === "authorEmail" ? true : noEditAccess,
                    }}
                    index={i}
                  />
                )
              )}
              <div className={classes.actionBtns}>
                {!isInTimeline &&
                  <button
                    className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
                    disabled={false}
                    onClick={previousStep}
                  >
                    {t("global.previousStepLabel")}
                  </button>
                }
                <button
                  className={`${classes.actionBtn} ${classes.actionBtnSubmit}`}
                  disabled={isInTimeline && noEditAccess}
                  type="submit"
                >
                  {isInTimeline ? t("save") : (noEditAccess ? t("global.openScenario") : t("global.saveAndOpenScenario"))}
                </button>
              </div>
            </Form>
          </Formik>
        )}
      </div>
    </div>
  );
};
ScenarioSettings.defaultProps = {
  isInTimeline: false,
};
ScenarioSettings.propTypes = {
  isInTimeline: PropTypes.bool,
};

const useStyles = createUseStyles({
  scenarioSettingsContainer: {
    display: "grid",
    gridTemplateRows: "max-content max-content",
    padding: "1rem",
    paddingTop: ".5rem",
    gridGap: ".5rem",
    width: "max-content",
  },
  breadcrumb: {
    padding: "0",
  },
  breadcrumbItem: {
    fontSize: variables.breadcrumb.fontSize,
    color: variables.colors.basicBlue,
  },
});
const ScenarioSettingsContainer = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <div className={classes.scenarioSettingsContainer}>
      <Breadcrumb className={classes.breadcrumb}>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/")}
        >
          Main Screen
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/scenario-library")}
        >
          Scenario Library
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-scenario-main-data")}
        >
          Add/Edit Scenario - Basic data
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-organizations")}
        >
          Add/Edit Organizations
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/add-edit-participants")}
        >
          Add/Edit Participants
        </Breadcrumb.Item>
        <Breadcrumb.Item
          className={classes.breadcrumbItem}
          onClick={() => navigate("/scenario-KPIs")}
        >
          Add/Edit Key Performance Indicators (KPIs)
        </Breadcrumb.Item>
        <Breadcrumb.Item className={classes.breadcrumbItem} active>
          Scenario Settings
        </Breadcrumb.Item>
      </Breadcrumb>
      <ScenarioSettings />
    </div>
  );
};

export default ScenarioSettingsContainer;
