import { addToast } from "../../../utils/helpers/alerts";
import { saveScenarioThunk } from "../../../redux/scenarioSlice";
import _ from "lodash";

export function deleteElement({ elementId, scenario, dispatch, handleClose }) {
  if (elementId) {
    let scenarioTemp = _.cloneDeep(scenario);
    const scriptIndex = scenarioTemp.script.findIndex(
      (el) => el.id === elementId
    );
    let temp = scenarioTemp.script.splice(scriptIndex, 1);

    dispatch(saveScenarioThunk(scenarioTemp)).then(() => {
      addToast(
        "success",
        `The ${temp[0].type} has been deleted successfully!`,
        3000
      );
      handleClose();
    });
  }
}
