import React from "react";
import PropTypes from "prop-types";
import { createUseStyles } from "react-jss";
import { variables } from "../jssVariables";

const tabButtonStyles = createUseStyles({
  tabBtn: (props) => ({
    background: "#ebebeb",
    padding: ".2rem",
    border: "none",
    borderTop: props.isActive ? `3px solid ${variables.colors.basicBlue}` : 0,
    borderLeft: props.isActive ? `1px solid ${variables.colors.basicBlue}` : 0,
    borderRight: props.isActive ? `1px solid ${variables.colors.basicBlue}` : 0,
    borderBottom: props.isActive
      ? "none"
      : `1px solid ${variables.colors.basicBlue}`,
    fontSize: "larger",
  }),
  tabLabel: (props) => ({
    color: props.isActive ? variables.colors.basicBlue : "gray",
  }),
  tabIcon: () => ({
    height: "1.5rem",
    width: "1.5rem",
  }),
});
const TabButton = (props) => {
  const { tabId, label, labelIsText, tabNum, setTabNum } = props;
  const classes = tabButtonStyles({ isActive: tabId === tabNum });

  return (
    <button
      className={classes.tabBtn}
      style={{ background: "white" }}
      onClick={() => setTabNum(tabId)}
    >
      {labelIsText ? (
        <span className={classes.tabLabel}>{label}</span>
      ) : (
        <img src={label} alt="" className={classes.tabIcon} />
      )}
    </button>
  );
};
TabButton.defaultProps = {
  tabNum: false,
  label: "All",
  tabId: 0,
  labelIsText: true,
};
TabButton.propTypes = {
  tabId: PropTypes.number.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  tabNum: PropTypes.number.isRequired,
  setTabNum: PropTypes.func.isRequired,
  labelIsText: PropTypes.bool.isRequired,
};

export default TabButton;
