import React from "react";
import { createUseStyles } from "react-jss";
import { useSelector } from "react-redux";
import { Chart } from "react-google-charts";
import PropTypes from "prop-types";

const useStyles = createUseStyles({
  organizationStructure: (props) => ({
    height: props.height,
    overflowY: "scroll",
  }),
  label: {
    textAlign: "center",
  },
});
const OrganizationStructure = (props) => {
  const { height } = props;
  const classes = useStyles({ height });
  const { organizationList, participantList } = useSelector((state) => {
    return {
      organizationList: state.organization.organizationList,
      participantList: state.participant.participantList,
    };
  });

  const data = [["Initial", "Initial", ""]];
  organizationList.forEach((org) => {
    data.push([
      {
        v: org.name,
        f: `${org.name}<div style="color:red;">${org.levelOfCommand.value}</div>`,
      },
      org.name,
      org.name,
    ]);
  });
  participantList.forEach((p) => {
    data.push([
      {
        v: p.fullName,
        f: `${p.fullName}<div style="color:red; ">${p.levelOfCommand.value}</div>`,
      },
      p.organization.name,
      p.levelOfCommand.value,
    ]);
  });

  const options = {
    allowHtml: true,
  };

  const style = {
    overflowY: "hidden",

    td: {
      background: "red",
    },
  };

  return (
    <div className={classes.organizationStructure}>
      <h4 className={classes.label}>Organization Structure</h4>
      <br />
      <Chart
        chartType="OrgChart"
        data={data}
        options={options}
        width="100%"
        height={PropTypes}
        style={style}
      />
    </div>
  );
};
OrganizationStructure.defaultProps = {};
OrganizationStructure.propTypes = {
  height: PropTypes.string.isRequired,
};

export default OrganizationStructure;
