import { DateTime } from "luxon";
import { socialMediaBatch } from "../../../utils/constants/data/social-media-batch/social-media-batch";
import spanish_trial_reddit_step_01_130522 from "../../../utils/constants/data/social-media-batch/spanish_trial_reddit_step_01_130522.json";
import spanish_trial_tweets_step_01_fake_injected_120522 from "../../../utils/constants/data/social-media-batch/spanish_trial_tweets_step_01_fake_injected_120522.json";
import spanish_trial_reddit_step_07_130522 from "../../../utils/constants/data/social-media-batch/spanish_trial_reddit_step_07_130522.json";
import spanish_trial_tweets_step_07_fake_injected_120522 from "../../../utils/constants/data/social-media-batch/spanish_trial_tweets_step_07_fake_injected_120522.json";
import romanian_trial_reddit_batch from "../../../utils/constants/data/social-media-batch/romanian_trial_reddit_batch_small_0.json";
import romanian_trial_tweets_batch from "../../../utils/constants/data/social-media-batch/romanian_trial_tweets_batch_small_0.json";

// eslint-disable-next-line max-statements
export function cleanElementForSave({
  scenario,
  values,
  elementType,
  element,
}) {
  let scriptTemp = [...scenario.script];
  let elem = {};

  const simulatedSocialMediaData = {
    spanish_trial_reddit_step_01_130522,
    spanish_trial_tweets_step_01_fake_injected_120522,
    spanish_trial_reddit_step_07_130522,
    spanish_trial_tweets_step_07_fake_injected_120522,
    romanian_trial_reddit_batch,
    romanian_trial_tweets_batch,
  };

  const timeOffset = 5 * 60 * 1000;

  if (
    ["EVENT", "MESSAGE", "SOCIAL_MEDIA", "SOCIAL_MEDIA_BATCH"].includes(
      elementType
    )
  ) {
    let receiversFull = [];
    if (["EVENT", "MESSAGE"].includes(elementType)) {
      receiversFull = values.receivers.map((r) => {
        return { id: r.id, name: r.value };
      });
    }

    let tempDate = new Date();

    elem = {
      ...element,
      eventCategory: values.eventCategory[0].id,
      message: values.message,
      sender: ["EVENT", "MESSAGE"].includes(elementType)
        ? {
            name: values.sender[0].value,
            id: values.sender[0].id,
          }
        : { name: values.sender },
      receivers: receiversFull,
      location: {
        type: "Point",
        coordinates:
          typeof values.coordinates === "string"
            ? values.coordinates.split(",")
            : values.coordinates,
      },
      locationAddress: values.locationAddress,
      file: values.file,
      levelOfCommand: values.levelOfCommand,
      socialMediaType: values.socialMediaType
        ? values.socialMediaType[0].value
        : "",
      timeOccurred: new Date(values.timeOccurred).toISOString(),
      timeEnded:
        elementType === "EVENT"
          ? new Date(values.timeEnded).toISOString()
          : new Date(
              tempDate.setTime(
                new Date(values.timeOccurred).getTime() + timeOffset
              )
            ).toISOString(),
      index: values.index,
    };

    if (["SOCIAL_MEDIA_BATCH"].includes(elementType)) {
      elem = {
        ...elem,
        timeOccurred: new Date(values.timeOccurred).toISOString(),
        timeEnded: new Date(
          tempDate.setTime(new Date(values.timeOccurred).getTime() + timeOffset)
        ).toISOString(),
        location: {
          coordinates: [0, 0],
          type: "Point",
        },
        socialMediaType: values.dataObjectSelection[0].value,
        socialMediaData:
          simulatedSocialMediaData[`${values.dataObjectSelection[0].value}`],
      };
    }
  } else if (elementType === "DATA_SOURCE") {
    let tempDate = new Date();

    if (element.id) {
      elem.id = element.id;
    }
    elem = {
      ...elem,
      eventCategory: values.eventCategory[0].id,
      timeOccurred: new Date(values.timeOccurred).toISOString(),
      timeEnded: new Date(
        tempDate.setTime(new Date(values.timeOccurred).getTime() + timeOffset)
      ).toISOString(),
      data: Array.isArray(values.file) ? values.file : [values.file],
      elementName: values.elementName,
    };
    elem = {
      ...element,
      ...elem,
      sender: null,
      receivers: null,
      location: null,
    };
  }

  if (scenario.script.length === 0) {
    scriptTemp = [elem];
  } else {
    const index = scenario.script.findIndex((el) => el.id === elem.id);
    if (index >= 0) {
      scriptTemp[index] = { ...elem, index: scenario.script.length + 1 };
    } else {
      scriptTemp[scriptTemp.length] = {
        ...elem,
        index: scriptTemp.length + 1,
      };
    }
  }

  return { ...scenario, script: [...scriptTemp] };
}
