import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ExusBrand from "../ExusBrand";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import home from "../../assets/icons/home.svg";
import profile from "../../assets/icons/profile.svg";
import scenarioLibrary from "../../assets/icons/scenarioLibrary.svg";
import history from "../../assets/icons/history.svg";
import contactEmail from "../../assets/icons/contactEmail.svg";
import settings from "../../assets/icons/settings.svg";
import faqs from "../../assets/icons/faqs.svg";
import privacyPolicy from "../../assets/icons/privacyPolicy.svg";
import sbTool from "../../assets/icons/sbTool.svg";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { clearLocalStorage } from "../../utils/helpers/storageHelpers";
import { createUseStyles } from "react-jss";
import { variables } from "../../jssVariables";

const proj_name = process.env.REACT_APP_PROJECT_NAME || "default";

const useStyles = createUseStyles({
  sideBar: {
    display: "grid",
    gridTemplateRows: "repeat(2, max-content)",
    alignContent: "space-between",
  },
  linkList: (props) => ({
    display: "grid",
    gridTemplateRows: "repeat(8, max-content)",
    paddingRight: props.isExpanded ? "1.5rem" : 0,
    paddingTop: ".4rem",
    gridGap: "1rem",
  }),
  link: (props) => ({
    display: "grid",
    gridTemplateColumns: "repeat(2, max-content)",
    gridGap: props.isExpanded ? ".3rem" : 0,
    alignItems: "center",
    border: "none",
    background: "none",
    cursor: "pointer",
    height: "max-content",
    padding: "0 .3rem",
    fontSize: "small",
  }),
  icon: () => ({
    width: "1.2rem",
    alignSelf: "baseline",
  }),
  profileMenu: {
    display: "grid",
    gridTemplateRows: "repeat(1, max-content)",
  },
  profileMenuItem: {
    fontSize: "small",
  },
  profileMenuItemLogout: {
    fontSize: "small",
    color: variables.colors.basicBlue,
  },
  divider: (props) => ({
    background: "#0d6efd3b",
    width: props.isExpanded ? "95%" : "100%",
    height: "1px",
    marginLeft: props.isExpanded ? "1rem" : 0,
  }),
});
const SidebarLeft = (props) => {
  const { t } = useTranslation();
  const { isExpanded, setIsExpanded, isAuthenticated, setIAuthenticated } =
    props;
  const classes = useStyles({ isExpanded });
  const { user } = useSelector((state) => state.authentication);

  function logOut() {
    clearLocalStorage();
    setIAuthenticated(false);
    setIsExpanded(false);
  }
  const navigate = useNavigate();

  return (
    <div className={classes.sideBar}>
      <ButtonGroup vertical className={classes.linkList}>
        {isAuthenticated && (
          <span className={classes.link}>
            <img
              src={profile}
              alt="profile"
              className={classes.icon}
              onClick={() => setIsExpanded((s) => !s)}
            />
            {isExpanded && (
              <span className={classes.profileMenu}>
                <span className={classes.profileMenuItem}>
                  {" "}
                  {user.username}
                </span>

                <span
                  className={`${classes.profileMenuItem} ${classes.profileMenuItemLogout}`}
                  onClick={logOut}
                >
                  {t("signIn.signOutLabel")}
                </span>
              </span>
            )}
          </span>
        )}
        <div className={classes.divider}></div>
        <span
          className={classes.link}
          onClick={() => {
            navigate("/");
            setIsExpanded(false);
          }}
        >
          <img src={home} alt="home" className={classes.icon} />

          {isExpanded && t("sidebarLeft.homeLabel")}
        </span>
        {/*<span className={classes.link}>*/}
        {/*  <img src={sbTool} alt="sbTool" className={classes.icon} />*/}
        {/*  {isExpanded && (proj_name === "nightingale" || proj_name === "silvanus" || proj_name === "co-protect") && "Scenario Builder"}*/}
        {/*  {console.log("proj_name: ", proj_name)}*/}
        {/*  {isExpanded &&*/}
        {/*    (proj_name === "default" || proj_name === "stamina") &&*/}
        {/*    t("sidebarLeft.toolDescriptionLabel")}*/}
        {/*</span>*/}
        <span
          className={classes.link}
          onClick={() => {
            navigate("/scenario-library");
            setIsExpanded(false);
          }}
        >
          <img
            src={scenarioLibrary}
            alt="scenarioLibrary"
            className={classes.icon}
          />
          {isExpanded && t("global.scenarioLibraryLabel")}
        </span>
        {/*<span className={classes.link}>*/}
        {/*  <img src={history} alt="history" className={classes.icon} />*/}
        {/*  {isExpanded && t("sidebarLeft.historyLabel")}*/}
        {/*</span>*/}
        {/*<span className={classes.link}>*/}
        {/*  <img src={faqs} alt="faqs" className={classes.icon} />*/}

        {/*  {isExpanded && t("sidebarLeft.faqsLabel")}*/}
        {/*</span>*/}
        {/*<span className={classes.link}>*/}
        {/*  <img src={settings} alt="settings" className={classes.icon} />*/}
        {/*  {isExpanded && t("sidebarLeft.settingsLabel")}*/}
        {/*</span>*/}
        {/*<span className={classes.link}>*/}
        {/*  <img*/}
        {/*    src={privacyPolicy}*/}
        {/*    alt="privacyPolicy"*/}
        {/*    className={classes.icon}*/}
        {/*  />*/}

        {/*  {isExpanded && t("sidebarLeft.privacyPolicyLabel")}*/}
        {/*</span>*/}
        {/*<span className={classes.link}>*/}
        {/*  <img src={contactEmail} alt="contactEmail" className={classes.icon} />*/}

        {/*  {isExpanded && t("sidebarLeft.contactUsLabel")}*/}
        {/*</span>*/}
      </ButtonGroup>

      <ExusBrand isExpanded={isExpanded} />
    </div>
  );
};
SidebarLeft.defaultProps = {
  isExpanded: false,
  isAuthenticated: false,
};
SidebarLeft.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  setIsExpanded: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setIAuthenticated: PropTypes.func.isRequired,
};
export default SidebarLeft;
