import React from "react";
import PropTypes from "prop-types";
import BootstrapForm from "react-bootstrap/Form";
import { useField, useFormikContext } from "formik";
import DateTimePicker from "react-datetime-picker";
import { DateTime } from "luxon";
import { LabelRequiredAndErrorMessage } from "./helpers";

const TimeTypeFieldFormik = ({ ...props }) => {
  const [field, meta, helpers] = useField(props);

  const { fieldLabel, required, elementType } = props;
  const { setValue, setError } = helpers;

  const { values } = useFormikContext();
  const { timeOccurred, timeEnded } = values;

  const updateState = (input) => {
    // if (elementType === "EVENT") {
    //   const t1 = DateTime.fromMillis(
    //     new Date(
    //       field.name === "timeOccurred" ? timeEnded : timeOccurred
    //     ).valueOf()
    //   );
    //   const t2 = DateTime.fromMillis(new Date(input).valueOf());
    //   if (field.name === "timeOccurred" && t1.diff(t2) > 0) {
    //     setValue(input);
    //   } else if (field.name === "timeEnded" && t1.diff(t2) < 0) {
    //     setValue(input);
    //   } else {
    //     setError("End Time must be later than Start Time");
    //   }
    // } else {
    //   setValue(input);
    // }
    setValue(input);
  };
  return (
    <React.Fragment>
      <BootstrapForm.Group controlId={`formBasicInvolved${fieldLabel}`}>
        <BootstrapForm.Label>{`Select ${fieldLabel}`}</BootstrapForm.Label>
        <br />
        <DateTimePicker
          disableClock={true}
          value={field.value ? new Date(field.value) : new Date()}
          onChange={(e) => updateState(e)}
        />
        <LabelRequiredAndErrorMessage meta={meta} required={required} />
      </BootstrapForm.Group>
    </React.Fragment>
  );
};

TimeTypeFieldFormik.propTypes = {
  name: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  hideRequiredText: PropTypes.bool.isRequired,
  elementType: PropTypes.string.isRequired,
};

TimeTypeFieldFormik.defaultProps = {
  name: "objectName",
  fieldLabel: "",
  required: false,
  hideRequiredText: false,
  elementType: "",
};

export default TimeTypeFieldFormik;
