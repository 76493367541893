import { myButton } from "../../../jssVariables";

export const elementStyles = {
  modalAddElement: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  deletePrompt: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    color: "red",
    width: "100%",
    padding: "0 2rem",
    fontWeight: "bold",
  },
  actionBtns: {
    display: "grid",
    gridTemplateColumns: "1fr auto",
    width: "100%",
  },
  actionBtnsRightColumn: (props) => ({
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridGap: props.elementHasId ? "1rem" : 0,
  }),
  actionBtn: {
    ...myButton,
    width: "max-content",
  },
  actionBtnSubmit: {
    justifySelf: "end",
  },
  actionBtnDelete: {
    color: "red",
    borderColor: "red",
  },
  actionBtnCancel: {
    color: "gray",
    borderColor: "gray",
  },
};
