const socialMediaFormFieldsInitial = {
  dataCount: 1,
  value: [
    {
      dataId: "1",
      type: "DataRecord",
      label: "SOCIAL_MEDIA",
      description: "",
      name: "socialMedia",
      data: {
        field: [
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Incident Category",
            name: "eventCategory",
            required: "true",
            constraint: {
              type: "AllowedTokens",
              value: [],
              minValue: 1,
            },
            multiSelect: false,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "User (username)",
            name: "sender",
            placeholder: "The username of the user that posted it",
            required: true,
          },
          {
            type: "Textarea",
            valueType: "string",
            description: "",
            label: "Post text",
            name: "message",
            placeholder: "The text of this Social Media post",
            required: true,
            rows: 3,
          },
          {
            type: "Category",
            valueType: "string",
            description: "",
            label: "Select the Social media of this post",
            name: "socialMediaType",
            required: true,
            constraint: {
              type: "AllowedTokens",
              value: ["Twitter", "Reddit", "LinkedIn", "Instagram", "Facebook"],
              minValue: 1,
            },
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location coordinates",
            name: "locationCoordinates",
            placeholder: "Will appear after point selection on map",
            disabled: true,
          },
          {
            type: "Text",
            valueType: "string",
            description: "",
            label: "Location Address",
            name: "locationAddress",
            placeholder: "If exists will appear after point selection on map",
            required: false,
            disabled: true,
          },
          {
            type: "Coordinates",
            valueType: "string",
            description: "",
            label: "Point selection on map",
            name: "coordinates",
          },
          {
            type: "Time",
            valueType: "string",
            description: "e.g.: 2021-09-14T09:40:56.260Z",
            label: "Time of the post",
            name: "timeOccurred",
            constraint: {
              format: "TimeIso8601",
            },
            required: true,
          },
          // {
          //   type: "Quantity",
          //   valueType: "integer",
          //   description: "",
          //   label: "Index",
          //   name: "index",
          //   placeholder: "",
          //   constraint: {
          //     minValue: 1,
          //   },
          //   required: true,
          // },
        ],
      },
    },
  ],
};

const socialMediaFormFields = (eventCategoryList) => {
  socialMediaFormFieldsInitial.value[0].data.field[0].constraint.value =
    eventCategoryList;
  return socialMediaFormFieldsInitial;
};

export default socialMediaFormFields;
